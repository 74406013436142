import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";
import { ICalendarOutputFormat } from "../../../interfaces";

interface IProps {
  summary: string | undefined;
  location: string | undefined;
  description: string | undefined;
  onSummaryChange: (pk: string) => Promise<void>;
  onLocationChange: (pk: string) => Promise<void>;
  onDescriptionChange: (pk: string) => Promise<void>;
  outputFormats: Array<ICalendarOutputFormat> | undefined;
}

const ReportOutput = (props: IProps): JSX.Element => {
  const {
    summary,
    location,
    description,
    onSummaryChange,
    onLocationChange,
    onDescriptionChange,
    outputFormats
  } = props;

  const { t } = useTranslation();

  const handleOnSummaryChange = async (event: any): Promise<void> => {
    const pk: string | undefined = getFormatPK(event.target.value);

    if (pk) {
      await onSummaryChange(pk);
    }
  };

  const handleOnLocationChange = async (event: any): Promise<void> => {
    const pk: string | undefined = getFormatPK(event.target.value);

    if (pk) {
      await onLocationChange(pk);
    }
  };

  const handleOnDescriptionChange = async (event: any): Promise<void> => {
    const pk: string | undefined = getFormatPK(event.target.value);

    if (pk) {
      await onDescriptionChange(pk);
    }
  };

  /**
   * Formats sorted alphabetically
   */
  const sortedFormats: Array<ICalendarOutputFormat> | undefined = outputFormats?.sort(
    (formatA: ICalendarOutputFormat, formatB: ICalendarOutputFormat): number => {
      return formatA.public_example < formatB.public_example ? -1 : 1;
    }
  );

  /**
   * Finds the pk of the chosen format.
   *
   * @param {string} publicExample The public_example from chosen the format.
   * @returns {string | undefined} The pk of the chosen format.
   */
  const getFormatPK = (publicExample: string): string | undefined => {
    return sortedFormats?.find((format: ICalendarOutputFormat) => format.public_example === publicExample)?.pk;
  };

  return (
    <SettingsRow
      label={t("preferences.calendar.output.report.label")}
      description={t("preferences.calendar.output.report.description")}>
      <Form.Group>
        <Form.Label>{t("preferences.calendar.output.summary")}</Form.Label>
        <Form.Control
          as="select"
          value={
            sortedFormats?.find((format: ICalendarOutputFormat) => Number(format.pk) === Number(summary))
              ?.public_example
          }
          onChange={handleOnSummaryChange}>
          {sortedFormats &&
            sortedFormats.map((format: ICalendarOutputFormat) => {
              return <option key={format.pk}>{format.public_example}</option>;
            })}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>{t("preferences.calendar.output.location")}</Form.Label>
        <Form.Control
          as="select"
          value={
            sortedFormats?.find((format: ICalendarOutputFormat) => Number(format.pk) === Number(location))
              ?.public_example
          }
          onChange={handleOnLocationChange}>
          {sortedFormats &&
            sortedFormats.map((format: ICalendarOutputFormat) => {
              return <option key={format.pk}>{format.public_example}</option>;
            })}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>{t("preferences.calendar.output.description")}</Form.Label>
        <Form.Control
          as="select"
          value={
            sortedFormats?.find((format: ICalendarOutputFormat) => Number(format.pk) === Number(description))
              ?.public_example
          }
          onChange={handleOnDescriptionChange}>
          {sortedFormats &&
            sortedFormats.map((format: ICalendarOutputFormat) => {
              return <option key={format.pk}>{format.public_example}</option>;
            })}
        </Form.Control>
      </Form.Group>
    </SettingsRow>
  );
};

export default ReportOutput;
