import React, { useEffect, useState } from "react";
import { AllTypeaheadOwnAndInjectedProps, Typeahead } from "react-bootstrap-typeahead";
import { IAirport } from "../../interfaces";
import { airportService } from "../../services/airport/AirportService";
import { showErrorMessage } from "../../stores/error/SnackbarStore";

interface IProps {
  name: string;
  placeholder: string;
  defaultAirport?: IAirport;
  onChange: Function;
  fieldTouched: Function;
  errors: string | undefined;
  touched: boolean | undefined;
}

const maxResults: number = 10;

const getDefault = (defaultValue: IAirport): Array<IAirport> => {
  return new Array<IAirport>(defaultValue);
};

const filter = (airport: IAirport, props: AllTypeaheadOwnAndInjectedProps<IAirport>): boolean => {
  const query: string = props.text.toLowerCase();
  if (
    airport.Name?.toLowerCase()?.includes(query) ||
    airport.IATA?.toLowerCase()?.includes(query) ||
    airport.ICAO?.toLowerCase()?.includes(query)
  ) {
    return true;
  }
  return false;
};

const AirportSelector = (props: IProps): JSX.Element => {
  const { name, placeholder, defaultAirport, onChange, fieldTouched, errors, touched } = props;

  const [airports, setAirports] = useState<Array<IAirport>>([]);

  useEffect(() => {
    fetchAirports();
  }, []);

  const fetchAirports = async (): Promise<void> => {
    try {
      const data: Array<IAirport> = await airportService().fetchAirports();

      setAirports(data);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleOnBlur = (): void => fieldTouched(name, true);

  const handleOnChange = (airport: IAirport): void => (airport ? onChange(name, airport.IATA) : onChange(name, ""));

  return (
    <>
      <Typeahead
        id="airport-selection"
        defaultSelected={defaultAirport && defaultAirport?.Name ? getDefault(defaultAirport) : undefined}
        maxResults={maxResults}
        paginate={true}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        options={airports}
        filterBy={filter}
        labelKey={(airport: IAirport) => `${airport.Name} - (${airport.IATA})`}
        onChange={(selected: Array<IAirport>) => handleOnChange(selected[0])}
        inputProps={{ style: { border: "none", backgroundColor: "#f4f6fa" } }}
      />
      {errors && touched && <p className="input-feedback">{errors}</p>}
    </>
  );
};

export default AirportSelector;
