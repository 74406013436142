import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { Preference } from "../../../../enums/settings/Preference";
import { IPreferences, IUserData } from "../../../../interfaces";
import { settingsService } from "../../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../../stores/user/UserStore";
import PageHead from "../../../PageHead";
import CrewNames from "./CrewNames";
import ExportFlights from "./ExportFlights";

const RBLogbookSettings: () => JSX.Element = observer(() => {
  const userData: IUserData | null = createUserStore().userData;

  const preference: IPreferences | null = userData ? userData.preferences : null;

  const updateUserPref = async (pref: Preference, value: string): Promise<void> => {
    try {
      await settingsService().updatePreference(pref, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleFlightExport = async (value: string): Promise<void> => {
    preference!.rb_logbook_export = value;
    await updateUserPref(Preference.RB_LOGOOK_EXPORT, value);
  };

  const handleCrewNames = async (value: string): Promise<void> => {
    preference!.rb_logbook_crew_names = value;
    await updateUserPref(Preference.RB_LOGOOK__CREW_NAMES, value);
  };

  return (
    <ComponentBoundary componentName="RB Logbook settings">
      <PageHead title={PageTitle.SETTINGS_RB_LOGBOOK} description={PageDescription.SETTINGS_RB_LOGBOOK} />

      <ExportFlights defaultValue={preference?.rb_logbook_export} onChange={handleFlightExport} />

      <CrewNames defaultValue={preference?.rb_logbook_crew_names} onChange={handleCrewNames} />
    </ComponentBoundary>
  );
});

export default RBLogbookSettings;
