import React from "react";
import { useTranslation } from "react-i18next";
import CustomButtonGroup from "../../../../components/inputs/CustomButtonGroup";
import SettingsRow from "../../../../components/settings/SettingsRow";
import { IToggleButtonOption } from "../../../../interfaces";

enum Options {
  ALL = "all",
  FLIGHT_CREW = "flight_crew",
  NONE = "none"
}

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const CrewNames = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const options: Array<IToggleButtonOption> = [
    { label: t("preferences.rb_logbook.crew_names.all"), value: Options.ALL },
    { label: t("preferences.rb_logbook.crew_names.flight_crew"), value: Options.FLIGHT_CREW },
    { label: t("preferences.rb_logbook.crew_names.none"), value: Options.NONE }
  ];

  return (
    <SettingsRow
      label={t("preferences.rb_logbook.crew_names.label")}
      description={t("preferences.rb_logbook.crew_names.description")}>
      <CustomButtonGroup defaultValue={defaultValue ?? Options.ALL} onChange={onChange} options={options} />
    </SettingsRow>
  );
};

export default CrewNames;
