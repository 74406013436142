import { computed, observable } from "mobx";
import { IUser, IUserData } from "../../interfaces";

export class UserStore {
  @observable private _userData: IUserData | null = null;
  @observable private _logbookUser: IUser | null = null;
  @observable private _isLoading: boolean = false;

  public set userData(userData: IUserData | null) {
    this._userData = userData;
  }

  public get userData(): IUserData | null {
    return this._userData;
  }

  public set logbookUser(user: IUser | null) {
    this._logbookUser = user;
  }

  public get logbookUser(): IUser | null {
    return this._logbookUser;
  }

  public set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  public get userFetched(): boolean {
    return this._userData !== null || this._logbookUser !== null;
  }

  public get userProfileIncomplete(): boolean {
    const user: IUser | null = this._userData ? this._userData.user : null;

    if (user === null) {
      return true;
    }

    return user.jobtype == null || user.homeairline == null || user.base == null;
  }

  public clear(): void {
    store = null;
  }
}

let store: UserStore | null = null;

export const createUserStore = (): UserStore => store ?? (store = new UserStore());

export const getUserData = (): IUserData | null => createUserStore().userData;
