export enum EventType {
  UNKNOWN = 0,
  DAY_OFF = 1,
  FLIGHT = 2,
  POSITIONING = 3,
  TRANSPORT = 4,
  REPORT = 5,
  LAYOVER = 6,
  SIM = 7,
  STANDBY = 8,
  OFFICE = 10,
  INFO = 11,
  COURSE = 12,
  PERSONAL = 13,
  WORKING = 14,
  NO_DUTY = 15,
  DEBRIEF = 16,
  PICKUP = 17,
  CHECK_OUT = 22,
  CHECK_IN = 23,
  HOTEL = 24
}
