import { createTheming, ThemingType } from "@callstack/react-theme-provider";

export type Theme = {
  primaryColor: string;
};

export const themes: { [key: string]: Theme } = {
  rb: {
    primaryColor: "#049fd9"
  },
  lb: {
    primaryColor: "#FF7300"
  }
};

const { ThemeProvider, withTheme, useTheme }: ThemingType<Theme> = createTheming(themes.rb);

export { ThemeProvider, withTheme, useTheme };
