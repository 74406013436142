//tslint:disable no-magic-numbers
import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../../components/buttons/SubmitButton";
import TextField from "../../../../components/inputs/TextField";
import Tooltip from "../../../../components/tooltip/Tooltip";
import { IAirline, ICrewPortalCredentials, IUploadType } from "../../../../interfaces";
import { uploadRosterService } from "../../../../services/roster/UploadRosterService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { getUserData } from "../../../../stores/user/UserStore";
import { rosterPortalSchema } from "../../../../utils/Validation";
import PortalInstructions from "./PortalInstructions";

enum FormValue {
  USERNAME = "username",
  PASSWORD = "password",
  USERNAME2 = "username2",
  PASSWORD2 = "password2",
  MEMORABLE = "memorable",
  DATE = "start_date",
  DAYS = "num_days"
}

enum SpecialCase {
  // Airlines
  CPZ = "CPZ",
  LOF = "LOF",
  GJS = "GJS",
  // Fetchers
  FLICA = "FLICA",
  CCNX_FETCH = "CCNX_FETCH"
}

interface IMonthObject {
  name: string;
  date: string;
  days: number;
}

const PortalForm = (): JSX.Element => {
  const { t } = useTranslation();

  const airline: IAirline | undefined = getUserData()?.homeairline;

  const uploadType: IUploadType | undefined = airline?.uploadType[0];

  // Date format for output.
  const dateFormat: string = "YYYY-MM-DD";

  // These airlines require a special field.
  const specialAirlines: Array<string> = [SpecialCase.CPZ, SpecialCase.LOF, SpecialCase.GJS];

  // Checks if airline is a FLICA airline.
  const isFLICA: boolean = uploadType?.pk.toUpperCase() === SpecialCase.FLICA;

  // Checks if airline is one of the special airlines that require a different input field.
  const isSpecialAirline: boolean = airline?.pk != null && specialAirlines.includes(airline.pk);

  // Checks if fetcher is CCNX.
  const isCCNX_FETCHER: boolean = uploadType?.pk.toUpperCase() === SpecialCase.CCNX_FETCH;

  const currentMonth: moment.Moment = moment().startOf("month");

  const nextMonth: moment.Moment = moment()
    .add(1, "months")
    .startOf("month");

  let months: Array<IMonthObject> | null = null;

  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const handleSubmit = async (values: ICrewPortalCredentials): Promise<void> => {
    try {
      await uploadRosterService().fetchRosterFromPortal(values);
    } catch (error) {
      throw error;
    }
  };

  const getInitialValues = (): ICrewPortalCredentials => {
    const values: ICrewPortalCredentials = {
      username: "",
      password: ""
    };

    if (uploadType?.fetchboxdouble === 1) {
      values.username2 = "";
      values.password2 = "";
    }

    if (uploadType?.fetchboxmemorable === 1) {
      values.memorable = "";
    }

    if (uploadType?.fetchboxextra === 1) {
      if (!isCCNX_FETCHER && !isSpecialAirline) {
        values.start_date = moment().format(dateFormat);
      } else if (isSpecialAirline) {
        months = getMonthsForSpecificAirlines();
        values.start_date = months[0].date;
        values.num_days = months[0].days;
      }

      if (uploadType?.fetchboxdays === 1) {
        if (isCCNX_FETCHER) {
          months = getMonthsForSpecificAirlines();
          values.start_date = months[0].date;
          values.num_days = months[0].days;
        } else {
          const month: number = 31;
          values.num_days = month;
        }
      }
    }

    if (isFLICA) {
      months = getMonthsForFLICA();
      values.start_date = months[0].date;
      values.num_days = months[0].days;
    }

    return values;
  };

  /**
   * Creates a list of months for specific airlines.
   */
  const getMonthsForSpecificAirlines = (): Array<IMonthObject> => {
    const values: Array<IMonthObject> = [
      {
        name: t("roster_upload.months.current"),
        date: currentMonth.format(dateFormat),
        days: currentMonth.daysInMonth()
      },
      { name: t("roster_upload.months.next"), date: nextMonth.format(dateFormat), days: nextMonth.daysInMonth() }
    ];

    return values;
  };

  /**
   * Creates a list of months for FLICA airlines.
   */
  const getMonthsForFLICA = (): Array<IMonthObject> => {
    const days: number = currentMonth.daysInMonth() + nextMonth.daysInMonth();

    const values: Array<IMonthObject> = [
      { name: t("roster_upload.months.current_next"), date: currentMonth.format(dateFormat), days: days }
    ];

    for (let i: number = 1; i < 5; i++) {
      const month: moment.Moment = moment()
        .subtract(i, "months")
        .startOf("month");

      values.push({ name: month.format("MMMM"), date: month.format(dateFormat), days: month.daysInMonth() });
    }

    return values;
  };

  /**
   * Renders an extra username and password field.
   */
  const renderFetchBoxDouble = (): JSX.Element | null => {
    if (uploadType?.fetchboxdouble !== 1) {
      return null;
    }
    return (
      <>
        <Form.Group>
          <TextField
            id="inputDoubleUsername"
            placeholder={uploadType?.wiz_username2_title ?? t("portal_username")}
            type="text"
            name={FormValue.USERNAME2}
          />
        </Form.Group>
        <Form.Group>
          <TextField
            id="inputDoublePassword"
            placeholder={uploadType?.wiz_password2_title ?? t("password")}
            type="password"
            name={FormValue.PASSWORD2}
          />
        </Form.Group>
      </>
    );
  };

  /**
   * Renders a field to input the memorable.
   */
  const renderFetchBoxMemorable = (): JSX.Element | null => {
    if (uploadType?.fetchboxmemorable !== 1) {
      return null;
    }

    return (
      <Form.Group>
        <TextField
          id="inputMemorable"
          placeholder={uploadType?.title_memorable ?? t("portal_memorable")}
          type="password"
          name={FormValue.MEMORABLE}
        />
      </Form.Group>
    );
  };

  /**
   * Renders a datepicker to select the start date of the roster.
   */
  const renderFetchBoxExtra = (): JSX.Element | null => {
    if (uploadType?.fetchboxextra !== 1 || isCCNX_FETCHER || isSpecialAirline) {
      return null;
    }

    return (
      <div>
        <div style={{ textAlign: "right" }}>
          <Tooltip id={FormValue.DATE} placement="left" message={t("portal_extra_tooltip")} />
        </div>
        <Form.Group>
          <TextField id="inputStartDate" type="date" name={FormValue.DATE} />
        </Form.Group>
      </div>
    );
  };

  /**
   * Renders a field to input the number of days to fetch.
   */
  const renderFetchBoxDays = (): JSX.Element | null => {
    if (uploadType?.fetchboxdays !== 1 || isCCNX_FETCHER) {
      return null;
    }

    return (
      <div>
        <div style={{ textAlign: "right" }}>
          <Tooltip id={FormValue.DAYS} placement="left" message={t("portal_days_tooltip")} />
        </div>
        <Form.Group>
          <TextField id="inputNumDays" name={FormValue.DAYS} placeholder={t("portal_days")} type="number" />
        </Form.Group>
      </div>
    );
  };

  /**
   * Renders an alternative way for choosing a start date and num days.
   * This is only used for specific airlines/fetchers.
   *
   * @param props FormikProps
   */
  const renderMonthSelector = (props: FormikProps<ICrewPortalCredentials>): JSX.Element | null => {
    if (!months) {
      return null;
    }

    const { setFieldValue } = props;

    return (
      <Form.Group>
        <Form.Label className="text-muted" style={{ fontSize: ".875rem" }}>
          {isFLICA ? t("roster_upload.months.months") : t("roster_upload.months.period")}
        </Form.Label>
        <Typeahead
          id="month-selection"
          defaultSelected={[months[0]]}
          options={months}
          labelKey={(month: IMonthObject): string => month.name}
          filterBy={(): boolean => true}
          onChange={(selected: Array<IMonthObject>): void => {
            setFieldValue(FormValue.DATE, selected[0].date);
            setFieldValue(FormValue.DAYS, selected[0].days);
          }}
          inputProps={{
            readOnly: true,
            style: { border: "none", backgroundColor: "#f4f6fa" }
          }}
        />
      </Form.Group>
    );
  };

  const handleShowInstructions = (): void => {
    document.getElementById("upload-roster-modal")!.style.display = "none";
    setShowInstructions(true);
  };

  const handleHideInstructions = (): void => {
    document.getElementById("upload-roster-modal")!.style.display = "flex";
    setShowInstructions(false);
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={rosterPortalSchema}
        onSubmit={async (values: ICrewPortalCredentials, actions: FormikHelpers<any>) => {
          try {
            actions.setSubmitting(true);

            await handleSubmit(values);
          } catch (error) {
            showErrorMessage(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}>
        {(props: FormikProps<ICrewPortalCredentials>) => (
          <FormikForm>
            <div className="py-3">
              <Form.Group>
                <TextField
                  id="inputEmail1"
                  placeholder={uploadType?.wiz_username1_title ?? t("portal_username")}
                  type="text"
                  name={FormValue.USERNAME}
                />
              </Form.Group>
              <Form.Group>
                <TextField
                  id="inputPassword1"
                  placeholder={uploadType?.wiz_password1_title ?? t("password")}
                  type="password"
                  name={FormValue.PASSWORD}
                />
              </Form.Group>

              {renderFetchBoxDouble()}
              {renderFetchBoxMemorable()}
              {renderFetchBoxExtra()}
              {renderFetchBoxDays()}
              {renderMonthSelector(props)}

              {uploadType?.upload_instructions && (
                <div style={{ textAlign: "center" }}>
                  <Button variant="link" onClick={handleShowInstructions}>
                    {t("portal_instructions_btn")}
                  </Button>
                </div>
              )}
            </div>

            <Form.Group>
              <SubmitButton
                label={t("portal_btn_label")}
                loadingLabel={t("portal_btn_loading_label")}
                disabled={props.isSubmitting}
              />
            </Form.Group>
          </FormikForm>
        )}
      </Formik>
      {showInstructions && (
        <PortalInstructions instructions={uploadType?.upload_instructions} onHide={handleHideInstructions} />
      )}
    </>
  );
};

export default PortalForm;
