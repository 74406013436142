import { AxiosInstance, AxiosResponse } from "axios";
import { action } from "mobx";
import { IData, IToken } from "../../interfaces";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { signOut } from "../auth/SignOutService";
import { AbstractHttpService } from "./AbstractHttpService";
import { refreshRBToken } from "./AuthApiService";

export class HttpService extends AbstractHttpService {
  /**
   * Returns an authorized Auth-API client.
   */
  public async authClient(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_AUTH_API_RB!);
  }

  /**
   * Returns a client for authentication.
   */
  public guestAuthClient(): AxiosInstance {
    return this.createGuestClient(process.env.REACT_APP_AUTH_API_RB!);
  }

  /**
   * Returns an authorized Auth-API client pointing to the base platform-independent auth api.
   */
  public async baseAuthClient(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_AUTH_API!);
  }

  /**
   * Returns a client for authentication pointing to the base platform-independent auth api.
   */
  public baseGuestAuthClient(): AxiosInstance {
    return this.createGuestClient(process.env.REACT_APP_AUTH_API!);
  }

  /**
   * Returns a Payment-API client.
   */
  public async paymentClient(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_PAYMENT_API_RB!);
  }

  /**
   * Returns an authorized RB-API client.
   */
  public async client(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_API_URL!);
  }

  /**
   * Returns an RB-API client without authorization.
   */
  public guestClient(): AxiosInstance {
    return this.createGuestClient(process.env.REACT_APP_API_URL!);
  }

  /**
   * Refreshes an expired token.
   *
   * @param {IToken} token The expired token
   */
  @action.bound
  public async refreshToken(token: IToken): Promise<void> {
    try {
      const response: AxiosResponse<IData<IToken>> = await refreshRBToken(token);

      const newToken: IToken = response.data.data;

      createAuthStore().setTokenForRB(newToken);
    } catch (error) {
      signOut();
    }
  }
}

let service: HttpService | null = null;

export const httpService = (): HttpService => service ?? (service = new HttpService());
