import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const EventGenerateReport = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  return (
    <SettingsRow
      label={t("settings_events_generate_report_label")}
      description={t("settings_events_generate_report_desc")}>
      <Form.Check
        type="switch"
        id="generate-report"
        checked={defaultValue ? Boolean(Number(defaultValue)) : false}
        label=""
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(String(+event.target.checked))}
      />
    </SettingsRow>
  );
};

export default EventGenerateReport;
