import React from "react";
import { useTranslation } from "react-i18next";
import CustomButtonGroup from "../../../components/inputs/CustomButtonGroup";
import SettingsRow from "../../../components/settings/SettingsRow";
import { IToggleButtonOption } from "../../../interfaces";

enum Format {
  MDY = "mdy",
  DMY = "dmy"
}

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const EventDateFormat = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const options: Array<IToggleButtonOption> = [
    { label: t("mm/dd/yyyy"), value: Format.MDY },
    { label: t("dd/mm/yyyy"), value: Format.DMY }
  ];

  return (
    <SettingsRow label={t("settings_events_date_format_label")} description={t("settings_events_date_format_desc")}>
      <CustomButtonGroup defaultValue={defaultValue ?? Format.MDY} onChange={onChange} options={options} />
    </SettingsRow>
  );
};

export default EventDateFormat;
