import { computed, observable } from "mobx";
import moment from "moment";
import { IEvent, IStatisticsResponseData, IStatisticsRoute, IStats } from "../../interfaces";

export class TimelineStore {
  @observable private _initialStartDate: moment.Moment | undefined = undefined;
  @observable private _initialEndDate: moment.Moment | undefined = undefined;
  @observable private _allEventsStartDate: moment.Moment | undefined = undefined;
  @observable private _showAllEvents: boolean = false;
  @observable private _statistics: IStatisticsResponseData | null = null;
  @observable private _events: Array<IEvent> = [];

  public set initialStartDate(date: moment.Moment | undefined) {
    this._initialStartDate = date;
  }

  public get initialStartDate(): moment.Moment | undefined {
    return this._initialStartDate;
  }

  public set allEventsStartDate(date: moment.Moment | undefined) {
    this._allEventsStartDate = date;
  }

  public get allEventsStartDate(): moment.Moment | undefined {
    return this._allEventsStartDate;
  }

  public set initialEndDate(date: moment.Moment | undefined) {
    this._initialEndDate = date;
  }

  public get initialEndDate(): moment.Moment | undefined {
    return this._initialEndDate;
  }

  public set showAllEvents(flag: boolean) {
    this._showAllEvents = flag;
  }

  public get showAllEvents(): boolean {
    return this._showAllEvents;
  }

  public set statistics(stats: IStatisticsResponseData | null) {
    this._statistics = stats;
  }

  public get stats(): IStats | null {
    return this._statistics ? this._statistics.numbers : null;
  }

  public get blockHours(): string {
    try {
      const block: string | undefined = this._statistics?.blockhours;

      const arr: Array<string> | undefined = block?.split(":");

      return arr ? `${arr[0]}h ${arr[1]}m` : "0h 0m";
    } catch (error) {
      return "0h 0m";
    }
  }

  public get dutyHours(): string {
    try {
      const duty: string | undefined = this._statistics?.dutyhours;

      const arr: Array<string> | undefined = duty?.split(":");

      return arr ? `${arr[0]}h ${arr[1]}m` : "0h 0m";
    } catch (error) {
      return "0h 0m";
    }
  }

  public get routes(): Array<IStatisticsRoute> | null {
    return this._statistics ? this._statistics.routes : null;
  }

  public set events(events: Array<IEvent>) {
    this._events = events || [];
  }

  public get events(): Array<IEvent> {
    return this._events || [];
  }

  @computed
  public get hasEvents(): boolean {
    return this.events.length !== 0;
  }

  public clear(): void {
    store = null;
  }
}

let store: TimelineStore | null = null;

export const createTimelineStore = (): TimelineStore => store ?? (store = new TimelineStore());
