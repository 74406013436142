import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ReCaptcha from "../../../components/inputs/ReCaptcha";
import SettingsRow from "../../../components/settings/SettingsRow";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { passwordService } from "../../../services/auth/PasswordService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import { passwordResetSchema } from "../../../utils/Validation";
import PageHead from "../../PageHead";

const PasswordReset = (): JSX.Element => {
  const { t } = useTranslation();

  const recaptchaRef: React.MutableRefObject<any> = useRef<React.MutableRefObject<any>>();

  const handleResetPassword = async (recaptcha: string): Promise<void> => {
    try {
      const email: string = createUserStore().userData!.user.email;

      await passwordService().resetPassword(email, recaptcha);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <PageHead title={PageTitle.SETTINGS_RESET_PASSWORD} description={PageDescription.SETTINGS_RESET_PASSWORD} />

      <SettingsRow
        label={t("preferences.reset_password.title")}
        description={t("preferences.reset_password.description")}>
        <Formik
          initialValues={{ recaptchaToken: "" }}
          validationSchema={passwordResetSchema}
          onSubmit={async (values: { recaptchaToken: string }, actions: FormikHelpers<any>) => {
            try {
              actions.setSubmitting(true);
              await handleResetPassword(values.recaptchaToken);
            } catch (error) {
              showErrorMessage(error);
            } finally {
              recaptchaRef.current.reset();
              actions.setSubmitting(false);
            }
          }}>
          {(props: FormikProps<any>) => {
            const { errors, touched, setFieldValue, isSubmitting } = props;

            return (
              <FormikForm>
                <Form.Group>
                  <ReCaptcha
                    name="recaptchaToken"
                    ref={recaptchaRef}
                    onChange={setFieldValue}
                    errors={errors.recaptchaToken}
                    touched={touched.recaptchaToken}
                  />
                </Form.Group>
                <Form.Group>
                  <PrimaryButton
                    label={t("reset_password_title")}
                    loadingLabel={t("reset_password_btn_loading_label")}
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Form.Group>
              </FormikForm>
            );
          }}
        </Formik>
      </SettingsRow>
    </>
  );
};

export default PasswordReset;
