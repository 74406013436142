import React from "react";
import Button from "react-bootstrap/Button";

interface IProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CancelButton = (props: IProps): JSX.Element => {
  const { label, onClick } = props;

  return (
    <Button className="cancel-btn" variant="dark" onClick={onClick}>
      {label}
    </Button>
  );
};

export default CancelButton;
