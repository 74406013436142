import React from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import i18n from "../../../../localization/i18n";

interface IProps {
  uploadBox: number;
  onClick: () => void;
}

const style: React.CSSProperties = {
  display: "block",
  width: "100%",
  margin: "3rem 0",
  fontSize: "1rem",
  borderRadius: "50px",
  backgroundColor: "#6CC04A"
};

const UploadFileButton = (props: IProps): JSX.Element | null => {
  if (props.uploadBox !== 1) {
    return null;
  }

  return (
    <PrimaryButton
      className="btn-hero-lg"
      icon="file-alt"
      label={i18n.t("upload_file_title")}
      onClick={props.onClick}
      style={style}
    />
  );
};

export default UploadFileButton;
