import moment from "moment";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useTranslation } from "react-i18next";
import CancelButton from "../buttons/CancelButton";
import PrimaryButton from "../buttons/PrimaryButton";

interface IProps {
  show: boolean;
  handleClose: () => void;
  numberOfMonths: number;
  onApply: (start: moment.Moment, end: moment.Moment) => Promise<void>;
}

const RangePicker = (props: IProps): JSX.Element => {
  const { show, handleClose, numberOfMonths, onApply } = props;

  const { t } = useTranslation();

  const [from, setFrom] = useState<Date | undefined>(undefined);
  const [to, setTo] = useState<Date | undefined>(undefined);
  const [enteredTo, setEnteredTo] = useState<Date | null>(null);

  const resetState = (): void => {
    setFrom(undefined);
    setTo(undefined);
    setEnteredTo(null);
  };

  const handleApply = async (): Promise<void> => {
    await onApply(moment(from), moment(to));
    handleClose();
  };

  const handleDayClick = (day: Date): void => {
    if (from && to && day >= from! && day <= to!) {
      resetState();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setFrom(day);
      setTo(undefined);
      setEnteredTo(null);
    } else {
      setTo(day);
      setEnteredTo(day);
    }
  };

  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date): boolean => {
    const isBeforeFirstDay: boolean = from !== undefined && DateUtils.isDayBefore(day, from);
    const isRangeSelected: boolean = from !== undefined && to !== undefined;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayMouseEnter = (day: Date): void => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const modifiers: any = { start: from, end: enteredTo };
  const selectedDays: Array<any> = [from, { from, to: enteredTo }];

  return (
    <Modal show={show} onHide={() => handleClose} centered>
      <Modal.Header>
        <Modal.Title className="date-picker-title">{t("date_picker_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <DayPicker
          className="Range"
          numberOfMonths={numberOfMonths}
          selectedDays={selectedDays}
          modifiers={modifiers}
          onDayClick={(day: Date) => handleDayClick(day)}
          onDayMouseEnter={(day: Date) => handleDayMouseEnter(day)}
        />
      </Modal.Body>
      <Modal.Footer>
        <CancelButton label={t("cancel")} onClick={handleClose} />
        <PrimaryButton label={t("apply")} onClick={handleApply} />
      </Modal.Footer>
    </Modal>
  );
};

export default RangePicker;
