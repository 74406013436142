import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import PageLoader from "../../components/loaders/PageLoader";
import { RouteURL } from "../../enums/routing/Route";
import LogbookPublicRoute from "../../logbook/LogbookPublicRoute";
import LogbookEmailVerify from "../../logbook/pages/auth/LogbookEmailVerify";
import LogbookUnsubscribe from "../../logbook/pages/campaign/LogbookUnsubscribe";
import { logbookUserService } from "../../logbook/services/LogbookUserService";
import { signOut } from "../../services/auth/SignOutService";
import { createUserStore } from "../../stores/user/UserStore";

const LogbookApp: () => JSX.Element = observer(
  (): JSX.Element => {
    useEffect(() => {
      logbookUserService()
        .fetchUser()
        .catch(() => signOut());
    }, []);

    if (!createUserStore().userFetched) {
      return <PageLoader />;
    }

    return (
      <Switch>
        <LogbookPublicRoute exact path={RouteURL.LOGBOOK_CAMPAIGN_UNSUBSCRIBE} component={LogbookUnsubscribe} />
        <LogbookPublicRoute exact path={RouteURL.LOGBOOK_EMAIL_VERIFY} component={LogbookEmailVerify} />

        <Redirect to={RouteURL.LOGBOOK_SIGN_IN} />
      </Switch>
    );
  }
);

export default LogbookApp;
