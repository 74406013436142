import { RouteComponentProps } from "react-router-dom";
import { redirectToLogbookSignIn, redirectToSignIn } from "../../utils/RedirectToSignIn";

const CustomRedirect = (props: RouteComponentProps): null => {
  const path: string = props.location.pathname;

  if (path.includes("/logbook")) {
    redirectToLogbookSignIn(props.location);
  } else {
    redirectToSignIn(props.location);
  }

  return null;
};

export default CustomRedirect;
