import React from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import rbLogoWhite from "../../../assets/images/logo/rb-logo-white.png";
import { RouteLabel, RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";

const Brand = (): JSX.Element => {
  const { t } = useTranslation();

  // showing titleLabel as 'RosterBuster' if page is Home/Download-App
  // showing 'Home' for all other pages
  const titleLabel = () => {
    switch (window.location.pathname) {
      case RouteURL.HOME:
      case RouteURL.DOWNLOAD_APP:
        return t("app_name");
      default:
        return RouteLabel.HOME;
    }
  };

  return (
    <Button variant="link" onClick={() => History.push(RouteURL.HOME)}>
      <Image className="mr-2" src={rbLogoWhite} style={{ height: "50px", width: "auto" }} />
      <span className="d-none d-sm-inline font-size-lg" style={{ color: "white" }}>
        {titleLabel()}
      </span>
    </Button>
  );
};

export default Brand;
