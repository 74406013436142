import React from "react";
import { Route, Switch } from "react-router-dom";
import { RouteURL } from "../enums/routing/Route";
import LogbookPublicRoute from "../logbook/LogbookPublicRoute";
import LogbookResetPasswordPage from "../logbook/pages/auth/ResetPasswordPage";
import LogbookSignInPage from "../logbook/pages/LogbookSignInPage";
import ResetPasswordPage from "../pages/auth/reset-password/ResetPasswordPage";
import SignInPage from "../pages/auth/sign-in/SignInPage";
import SignUpPage from "../pages/auth/sign-up/SignUpPage";
import ThirdPartyAuthorizePage from "../pages/auth/thirdparty/ThirdpartyAuthorizePage";
import CustomRedirect from "./custom-routes/CustomRedirect";
import PublicRouteRB from "./custom-routes/PublicRouteRB";
import { SharedPublicRoutes } from "./SharedPublicRoutes";

const PublicApp = (): JSX.Element => {
  return (
    <Switch>
      <PublicRouteRB exact path={RouteURL.SIGN_IN} component={SignInPage} />
      <PublicRouteRB exact path={RouteURL.SIGN_UP} component={SignUpPage} />
      <PublicRouteRB exact path={RouteURL.FORGOT_PASSWORD} component={ResetPasswordPage} />
      <PublicRouteRB exact path={RouteURL.THIRD_PARTY_AUTHORIZE} component={ThirdPartyAuthorizePage} />

      <LogbookPublicRoute exact path={RouteURL.LOGBOOK_SIGN_IN} component={LogbookSignInPage} />
      <LogbookPublicRoute exact path={RouteURL.LOGBOOK_FORGOT_PASSWORD} component={LogbookResetPasswordPage} />

      {SharedPublicRoutes()}

      <Route component={CustomRedirect} />
    </Switch>
  );
};

export default PublicApp;
