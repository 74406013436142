//tslint:disable:no-magic-numbers
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RangePicker from "../../components/datepickers/RangePicker";
import { eventService } from "../../services/event/EventsService";
import { showErrorMessage } from "../../stores/error/SnackbarStore";
import { createTimelineStore } from "../../stores/timeline/TimelineStore";
import { getUserTimezone } from "../../utils/DateTimeUtils";
import ExportEvents from "./ExportEvents";

interface IProps {
  onChange: (start: moment.Moment, end: moment.Moment) => Promise<void>;
  initialStartDate: moment.Moment;
  initialEndDate: moment.Moment;
}

const week: number = 6;
const month: number = 30;
const numberOfMonths: number = 1;
const dateFormat: string = "D MMM YYYY";

const EventRangePicker = (props: IProps): JSX.Element => {
  const { initialStartDate, initialEndDate, onChange } = props;

  const { t } = useTranslation();

  const [title, setTitle] = useState(t("all"));
  const [show, setShow] = useState(false);
  const [start, setStart] = useState<moment.Moment>(initialStartDate);
  const [end, setEnd] = useState<moment.Moment>(initialEndDate);

  useEffect(() => {
    setStart(initialStartDate);
    setEnd(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  // Shows all events
  const handleAllEvents = async (): Promise<void> => {
    try {
      await eventService().fetchAllEvents();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  // Sets the range from current day to 'x' days in the future
  const handlePresetChange = async (days: number): Promise<void> => {
    const currentDate: moment.Moment = moment().tz(getUserTimezone());

    const endDate: moment.Moment = moment()
      .tz(getUserTimezone())
      .add(days, "days");

    await handleChange(currentDate, endDate);
  };

  // Sets custom range
  const handleChange = async (start: moment.Moment, end: moment.Moment): Promise<void> => {
    createTimelineStore().showAllEvents = false;

    start = start.tz(getUserTimezone()).startOf("day");
    end = end.tz(getUserTimezone()).endOf("day");

    setStart(start);
    setEnd(end);

    await onChange(start, end);
  };

  const openDatePicker = (): void => setShow(true);

  const closeDatePicker = (): void => setShow(false);

  return (
    <Card className="block event-date-picker-card">
      <div className="block-content block-content-full">
        <Row noGutters>
          <Col xs={8} style={{ paddingRight: "0.25rem" }}>
            <Dropdown onSelect={(eventKey: any) => setTitle(eventKey)}>
              <Dropdown.Toggle id="event-date-dropdown" style={{ borderBottomLeftRadius: 0 }}>
                {title}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={t("all")} onClick={handleAllEvents}>
                  {t("all")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey={t("next_seven_days")} onClick={() => handlePresetChange(week)}>
                  {t("next_seven_days")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey={t("next_thirty_days")} onClick={() => handlePresetChange(month)}>
                  {t("next_thirty_days")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey={t("custom")} onClick={openDatePicker}>
                  {t("custom")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col xs={4}>
            <ExportEvents startDate={start} endDate={end} />
          </Col>
        </Row>

        <Row noGutters id="event-range-picker-dates">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="event-range-picker-from" style={{ borderTop: "none", borderTopLeftRadius: 0 }}>
                  {t("from")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled
                placeholder={t("from")}
                value={start?.format(dateFormat) ?? t("from")}
                style={{
                  textAlign: "center",
                  backgroundColor: "#FFFFFF",
                  borderTop: "none",
                  borderRadius: 0
                }}
              />
            </InputGroup>
          </Col>

          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="event-range-picker-to"
                  style={{ borderLeft: "none", borderTop: "none", borderRadius: 0 }}>
                  {t("to")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled
                placeholder={t("to")}
                value={end?.format(dateFormat) ?? t("to")}
                style={{
                  textAlign: "center",
                  backgroundColor: "#FFFFFF",
                  borderTop: "none",
                  borderTopRightRadius: 0
                }}
              />
            </InputGroup>
          </Col>
        </Row>

        <RangePicker show={show} handleClose={closeDatePicker} numberOfMonths={numberOfMonths} onApply={handleChange} />
      </div>
    </Card>
  );
};

export default EventRangePicker;
