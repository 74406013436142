//tslint:disable:no-magic-numbers
import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

interface IProps {
  to: string;
  icon: string;
  label: string;
}

const SettingsCategory = (props: IProps): JSX.Element => {
  const { to, icon, label } = props;

  return (
    <NavLink
      exact
      to={to}
      className="nav-link rb-nav-link"
      activeClassName="rb-active-nav-link"
      style={{ width: "100%" }}>
      <Row noGutters>
        <Col xs={2}>
          <i className={`fa fa-${icon}`} />
        </Col>
        <Col xs={10}>{label}</Col>
      </Row>
    </NavLink>
  );
};

export default SettingsCategory;
