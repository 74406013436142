import { AxiosInstance, AxiosResponse } from "axios";
import { logbookHttpService } from "./LogbookHttpService";

enum LBApi {
  USER = "/v1/user",
  UNSUBSCRIBE_EMAIL = "v1/user/email/unsubscribe",
  RESET_PASSWORD = "/v2/auth/resetpassword"
}

const _client = async (): Promise<AxiosInstance> => {
  return await logbookHttpService().client();
};

const _guestClient = (): AxiosInstance => {
  return logbookHttpService().guestClient();
};

/**
 * Fetches the logbook user
 */
export const fetchUser = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(LBApi.USER);
};

/**
 * Update the signed-in user's account
 * @param {object} userData Contains the data to update
 */
export const updateUser = async (userData: {}): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(LBApi.USER, userData);
};

/**
 * Unsubscribe from Logbook emails.
 */
export const unsubscribeFromEmail = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(LBApi.UNSUBSCRIBE_EMAIL);
};

/**
 * Request a password reset
 * @param {string} email The user's email
 * @param {string} recaptchaResponse Google recaptcha response token
 */
export const resetPassword = async (email: string, recaptchaResponse: string): Promise<AxiosResponse<any>> => {
  return await _guestClient().post(LBApi.RESET_PASSWORD, {
    email: email,
    "g-recaptcha-response": recaptchaResponse
  });
};
