import { observer } from "mobx-react";
import React from "react";
import Toast from "react-bootstrap/Toast";
import { createSnackbarStore, MessageType, SnackbarStore } from "../../stores/error/SnackbarStore";

const Snackbar: () => JSX.Element | null = observer(() => {
  const autoHideDelay: number = 4000;

  const messageStore: SnackbarStore = createSnackbarStore();

  const { hasMessage, messageType, message } = messageStore;

  const handleOnClose = (): void => messageStore.clear();

  if (!hasMessage) {
    return null;
  }

  return (
    <Toast
      show={hasMessage}
      delay={autoHideDelay}
      autohide
      onClose={handleOnClose}
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        minWidth: 250,
        fontWeight: "bold",
        border: "none",
        backgroundColor: "#FFFFFF"
      }}>
      <Toast.Header>
        <div
          style={{
            marginRight: "auto",
            fontSize: "1rem",
            color: messageType === MessageType.SUCCESS ? "#6CC04A" : "#DC3545"
          }}>
          {message?.toString()}
        </div>
      </Toast.Header>
    </Toast>
  );
});

export default Snackbar;
