import { RouteURL } from "../enums/routing/Route";
import History from "../History";
import { createAuthStore } from "../stores/auth/AuthStore";
import { createUserStore } from "../stores/user/UserStore";

/**
 * @param {string} from The path from where the user is redirected
 */
export const redirectToSignIn = (from?: any): void => {
  clearUserData();

  const location: any = {
    pathname: RouteURL.SIGN_IN,
    state: { from: from }
  };
  History.push(location);
};

/**
 * @param {string} from The path from where the user is redirected
 */
export const redirectToLogbookSignIn = (from?: any): void => {
  clearUserData();

  const location: any = {
    pathname: RouteURL.LOGBOOK_SIGN_IN,
    state: { from: from }
  };
  History.push(location);
};

const clearUserData = (): void => {
  createUserStore().clear();

  createAuthStore().clear();
};
