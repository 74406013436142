// tslint:disable: file-name-casing
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import MainApp from "./application/App";
import "./assets/css/dependencies";
import i18n from "./localization/i18n";
import ErrorPage from "./pages/error/ErrorPage";
import * as serviceWorker from "./serviceWorker";

// Init Google Analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!);

// Init Bugsnag
const bugsnagClient: any = bugsnag("36b7ff89616e4221eeb1b2f40133e12c");
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary: any = bugsnagClient.getPlugin("react");
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <MainApp />
    </ErrorBoundary>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
