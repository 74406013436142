import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { ISignInCredentials } from "../../../interfaces";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { signInSchema } from "../../../utils/Validation";
import SubmitButton from "../../buttons/SubmitButton";
import TextField from "../../inputs/TextField";

interface IProps {
  onSubmit: (values: ISignInCredentials) => Promise<void>;
  children?: React.ReactNode | React.ReactNodeArray;
}

const GenericSignInForm = (props: IProps): JSX.Element => {
  const { onSubmit, children } = props;

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        signInPassword: ""
      }}
      validationSchema={signInSchema}
      onSubmit={async (values: ISignInCredentials, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);

          await onSubmit(values);
        } catch (error) {
          showErrorMessage(error);

          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<any>) => (
        <FormikForm>
          <div className="py-3">
            <Form.Group>
              <TextField placeholder={t("email")} type="email" name="email" />
            </Form.Group>
            <Form.Group>
              <TextField placeholder={t("password")} type="password" name="signInPassword" />
            </Form.Group>
          </div>

          <Form.Group>
            <Form.Group>
              <SubmitButton
                label={t("sign_in_title")}
                loadingLabel={t("sign_in_btn_loading_label")}
                icon="sign-in-alt"
                disabled={props.isSubmitting}></SubmitButton>
            </Form.Group>
            {children}
          </Form.Group>
        </FormikForm>
      )}
    </Formik>
  );
};

export default GenericSignInForm;
