export enum RouteLabel {
  HOME = "Home",
  UPGRADE = "Upgrade",
  PREMIUM = "Premium",
  LIFETIME = "Lifetime"
}

export enum RouteURL {
  HOME = "/home",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  FORGOT_PASSWORD = "/forgot-password",
  UPLOAD = "/home/upload",
  UPLOAD_PORTAL = "/home/upload/portal",
  UPLOAD_FILE = "/home/upload/file",
  UPLOAD_SRC = "/home/upload/src",
  UPLOAD_SUCCESS = "/home/upload/success",
  CAMPAIGN_UNSUBSCRIBE = "/unsubscribe",
  CAMPAIGN_COMPLETE_PROFILE = "/campaign/complete-profile",
  AMBASSADOR_OPT_OUT = "/user/ambassador-opt-out",
  DOWNLOAD_APP = "/download-app",
  CHANGE_PASSWORD = "/reset-password",
  SETTINGS = "/settings",
  SETTINGS_PROFILE = "/settings/profile",
  SETTINGS_DISPLAY = "/settings/display",
  SETTINGS_ROSTER = "/settings/roster",
  SETTINGS_EVENT = "/settings/event",
  SETTINGS_CALENDAR = "/settings/calendar",
  SETTINGS_CALENDAR_FILTER = "/settings/calendar-filter",
  SETTINGS_RB_LOGBOOK = "/settings/rb-logbook",
  SETTINGS_SAFELOG = "/settings/safelog",
  SETTINGS_PRIVACY = "/settings/privacy",
  SETTINGS_RESET_PASSWORD = "/settings/reset-password",
  SETTINGS_THIRD_PARTY = "/settings/third-party",
  LOGBOOK_SIGN_IN = "/logbook/sign-in",
  LOGBOOK_CAMPAIGN_UNSUBSCRIBE = "/logbook/unsubscribe",
  LOGBOOK_EMAIL_VERIFY = "/logbook/verify-email",
  LOGBOOK_FORGOT_PASSWORD = "/logbook/forgot-password",
  LOGBOOK_CHANGE_PASSWORD = "/logbook/reset-password",
  THIRD_PARTY_AUTHORIZE = "/thirdparty/login",
  EMAIL_VERIFY = "/verify-email",

  UNAUTHORIZED = "/unauthorized"
}

export enum RouteIcon {
  HOME = "home",
  UPGRADE = "rocket",
  PREMIUM = "medal",
  LIFETIME = "medal",
  SETTINGS_PROFILE = "user",
  SETTINGS_DISPLAY = "ruler-combined",
  SETTINGS_ROSTER = "clipboard-list",
  SETTINGS_EVENT = "list",
  SETTINGS_CALENDAR = "calendar-alt",
  SETTINGS_CALENDAR_FILTER = "filter",
  SETTINGS_RB_LOGBOOK = "clipboard",
  SETTINGS_SAFELOG = "clipboard",
  SETTINGS_PRIVACY = "lock",
  SETTINGS_RESET_PASSWORD = "key",
  SETTINGS_THIRD_PARTY = "boxes"
}

export enum RouteSource {
  RB_LOGBOOK = "rblogbook"
}
