//tslint:disable: no-magic-numbers
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import backGroundImage from "../../../assets/images/background/sign-up-bg.jpg";
import rbLogoBlue from "../../../assets/images/logo/rb-logo-blue.png";
import CompleteProfileForm from "./CompleteProfileForm";

const CompleteProfilePage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="bg-image" style={{ backgroundImage: `url(${backGroundImage})` }}>
      <Row className="no-gutters justify-content-center bg-black-75">
        <Col md={8} className="hero-static d-flex align-items-center bg-white">
          <div className="p-3 w-100">
            <div className="mb-5 text-center">
              <Image src={rbLogoBlue} style={{ height: "150px", width: "auto" }} />
              <p className="text-uppercase font-w700 font-size-sm text-muted">{t("complete_profile_title")}</p>
            </div>
            <Row className="no-gutters justify-content-center">
              <Col sm={8} xl={6}>
                <CompleteProfileForm />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompleteProfilePage;
