import React from "react";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import googlePlayBadge from "../../../assets/images/store-badge/google-play-badge.png";
import { Url } from "../../../enums/Url";

const GooglePlayButton = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="d-inline-block m-2">
      <a href={Url.RB_GOOGLE_PLAY} target="_blank" rel="noopener noreferrer">
        <Image src={googlePlayBadge} alt={t("google_play_btn")} style={{ height: "71px", width: "240px" }} />
      </a>
    </div>
  );
};

export default GooglePlayButton;
