//tslint:disable no-magic-numbers
import _ from "lodash";
import * as Yup from "yup";
import i18n from "../localization/i18n";

const nameRegex: RegExp = /^[a-zA-ZÀ-ž\s]+$/;
const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d-!$%^&*()_+|~=`{}[:;<>?,.@#\]]{6,}$/;
const minPasswordLength: number = 8;
const minNameLength: number = 2;

const mb: number = 1000000;
const MAX_AVATAR_SIZE: number = 1 * mb;
const MAX_ROSTER_SIZE: number = 10 * mb;

export const IMAGE_FORMATS: Array<string> = ["image/jpeg", "image/png"];

export const ROSTER_FORMATS: Array<string> = [
  "text/plain",
  "application/pdf",
  "image/jpeg",
  "image/png",
  "text/calendar",
  "text/csv",
  "text/html",
  "text/xml"
];
export const ROSTER_FILE_EXTENSION: Array<string> = ["ino"];

const firstname: Yup.StringSchema<string> = Yup.string()
  .trim()
  .matches(nameRegex, i18n.t("name_regex"))
  .min(minNameLength, i18n.t("firstname_length"))
  .required(i18n.t("firstname_required"));

const lastname: Yup.StringSchema<string> = Yup.string()
  .trim()
  .matches(nameRegex, i18n.t("name_regex"))
  .min(minNameLength, i18n.t("lastname_length"))
  .required(i18n.t("lastname_required"));

const email: Yup.StringSchema<string> = Yup.string()
  .required(i18n.t("email_required"))
  .email(i18n.t("email_invalid"));

const signUpPassword: Yup.StringSchema<string> = Yup.string()
  .matches(passwordRegex, i18n.t("password_regex"))
  .min(minPasswordLength, i18n.t("password_length"))
  .required(i18n.t("password_required"));

const signInPassword: Yup.StringSchema<string> = Yup.string()
  .required(i18n.t("password_required"))
  .trim();

const password_confirmation: Yup.StringSchema<string> = Yup.string()
  .required(i18n.t("confirm_password_required"))
  .oneOf([Yup.ref("password")], i18n.t("confirm_password_match"));

const phonenumber: Yup.StringSchema<string> = Yup.string();

const phonenumber_prefix: Yup.StringSchema<string> = Yup.string();

const recaptchaToken: Yup.StringSchema<string> = Yup.string().required(i18n.t("recaptcha_required"));

const jobtype: Yup.StringSchema<string> = Yup.string().required(i18n.t("position_required"));

const homeairline: Yup.StringSchema<string> = Yup.string().required(i18n.t("airline_required"));

const base: Yup.StringSchema<string> = Yup.string().required(i18n.t("homebase_required"));

const avatar: Yup.MixedSchema<any> = Yup.mixed()
  .test("fileSize", i18n.t("avatar_too_large"), (value: File) => {
    return value == null || value.size <= MAX_AVATAR_SIZE;
  })
  .test("fileFormat", i18n.t("unsupported_image_format"), (value: File) => {
    return value == null || IMAGE_FORMATS.includes(value.type);
  });

export const signUpSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  firstname,
  lastname,
  email,
  password: signUpPassword,
  password_confirmation
});

export const signInSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  email,
  signInPassword
});

export const resetPasswordSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  email,
  recaptchaToken
});

export const onboardingProfileSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  avatar,
  jobtype,
  homeairline,
  base
});

export const uploadRosterHtmlSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  source: Yup.string().required(i18n.t("roster_source_required"))
});

export const uploadRosterFileSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  file: Yup.mixed()
    .required(i18n.t("file_required"))
    .test("fileSize", i18n.t("file_too_large"), (value: File) => value && value.size <= MAX_ROSTER_SIZE)
    .test(
      "fileFormat",
      i18n.t("unsupported_file_format"),
      (value: File) =>
        value &&
        (ROSTER_FORMATS.includes(value.type) || ROSTER_FILE_EXTENSION.includes(_.last(_.split(value.name, ".")) || ""))
    )
});

export const rosterPortalSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  username: Yup.string().required(i18n.t("field_required")),
  password: Yup.string().required(i18n.t("field_required"))
});

export const settingsProfileSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  avatar,
  profile: Yup.object().shape({
    firstname,
    lastname,
    email,
    phonenumber,
    phonenumber_prefix,
    homeairline,
    base,
    jobtype
  })
});

export const passwordResetSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  recaptchaToken
});

export const changePasswordSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  new_password: signUpPassword,
  new_password_confirmation: Yup.string()
    .required(i18n.t("confirm_password_required"))
    .oneOf([Yup.ref("new_password")], i18n.t("confirm_password_match"))
});
