//tslint:disable: no-magic-numbers
import moment from "moment-timezone";
import { createUserStore } from "../stores/user/UserStore";

const millis: number = 1000;

/**
 * @returns The user's prefered timezone (or UTC default).
 */
export const getUserTimezone = (): string => {
  return createUserStore().userData?.preferences.profile_fridge_timezone ?? "UTC";
};

/**
 * Converts a Date to a String
 * @param date Date to convert
 * @returns Date string as e.g. '10 January 2020'
 */
export const toFullDateString = (date: string | number): string => {
  const newDate: moment.Moment = moment(date);

  return newDate.format("D MMMM YYYY");
};

/**
 * Returns the event time in the user's prefered timezone.
 *
 * @param date Date to get the time from
 * @returns Time in hours and seconds e.g. 20:30
 */
export const getEventTime = (date: number): string => {
  const newDate: moment.Moment = moment(date * millis).tz(getUserTimezone());

  // Adding a '0' to hours and minutes when it's lower than 10. Else you may get a time like 10:3 or 2:30.
  const hours: string = newDate.hour() < 10 ? "0" + newDate.hour().toString() : newDate.hour().toString();

  const minutes: string = newDate.minute() < 10 ? "0" + newDate.minute().toString() : newDate.minute().toString();

  switch (getUserTimezone()) {
    case "UTC":
    case "Zulu":
      return hours + ":" + minutes + "Z";

    default:
      return hours + ":" + minutes;
  }
};
