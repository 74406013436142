import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
  ref: React.MutableRefObject<any>;
  name: string;
  onChange: (name: string, value: any) => void;
  errors: any;
  touched: any;
}

//tslint:disable-next-line
const ReCaptcha = React.forwardRef((props: IProps, ref: React.Ref<any>) => {
  const { name, onChange, errors, touched } = props;

  return (
    <>
      <ReCAPTCHA
        ref={ref}
        onChange={(value: string | null) => {
          value ? onChange(name, value) : onChange(name, "");
        }}
        sitekey={process.env.REACT_APP_CAPTCHA_KEY!}
      />
      {errors && touched && <p className="input-feedback">{errors}</p>}
    </>
  );
});

export default ReCaptcha;
