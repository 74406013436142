import { ErrorMessage } from "formik";
import { get, toString } from "lodash";
import React, { useState } from "react";
import { Form, Image } from "react-bootstrap";
import { createUserStore } from "../../stores/user/UserStore";
import { IMAGE_FORMATS } from "../../utils/Validation";

interface IProps {
  label: string;
  name: string;
  onChange: Function;
}

const AvatarSelector = (props: IProps): JSX.Element => {
  const [avatar, setAvatar] = useState<any>(get(createUserStore().userData, "user.avatar_url", ""));

  const { label, name, onChange } = props;

  const handleChange = (file: File): void => {
    if (file != null) {
      try {
        const reader: FileReader = new FileReader();
        reader.onloadend = () => {
          setAvatar(reader.result);
        };

        reader.readAsDataURL(file);
      } catch (e) {
        console.log("Failed to read image file.");
      }

      onChange(file);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Image className="upload-avatar" src={avatar} />

      <label
        className="btn btn-primary"
        htmlFor="upload-avatar"
        style={{
          color: "#FFFFFF",
          borderRadius: "50px"
        }}>
        {label}
      </label>

      <Form.Control
        id="upload-avatar"
        name={name}
        type="file"
        hidden
        accept={toString(IMAGE_FORMATS)}
        onChange={(event: any) => handleChange(event.target.files[0])}
      />
      <ErrorMessage className="input-feedback" name={name} component="div" />
    </div>
  );
};

export default AvatarSelector;
