import { observer } from "mobx-react";
import React, { useEffect } from "react";
import PageLoader from "../../components/loaders/PageLoader";
import { AccountType } from "../../enums/user/AccountType";
import { signOut } from "../../services/auth/SignOutService";
import { createFirebaseDatabaseService } from "../../services/firebase/FirebaseDatabaseService";
import { userService } from "../../services/user/UserService";
import { createUserStore } from "../../stores/user/UserStore";
import LiteApp from "./LiteApp";
import PremiumApp from "./PremiumApp";

const RosterBusterApp: () => JSX.Element = observer(
  (): JSX.Element => {
    const { userData, userFetched, isLoading } = createUserStore();

    const accountType: string | undefined = userData?.user?.account_type;

    useEffect(() => {
      userService()
        .getUser()
        .catch(() => signOut());
    }, []);

    useEffect(() => {
      if (userFetched && !isLoading && userData?.user) {
        // Set online status to true
        createFirebaseDatabaseService()
          .setStatus(true, userData.user.pk)
          .catch();
      }
    }, [userFetched, isLoading, userData]);

    // Wait for user's account to be fetched.
    if (!userFetched) {
      return <PageLoader primary />;
    }

    // Load Premium features if the user has a subscription.
    if (accountType === AccountType.FULL) {
      return <PremiumApp />;
    }

    // Load lite features if the user has no subscription.
    return <LiteApp />;
  }
);

export default RosterBusterApp;
