//tslint:disable:no-magic-numbers
import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import SettingsCategory from "../../components/settings/SettingsCategory";
import { RouteIcon, RouteURL } from "../../enums/routing/Route";
import CalendarFilterSettings from "./calendar-filter/CalendarFilterSettings";
import CalendarSettings from "./calendar/CalendarSettings";
import DisplaySettings from "./display/DisplaySettings";
import EventSettings from "./event/EventSettings";
import RBLogbookSettings from "./export/rb-logbook/RBLogbookSettings";
import SafelogSettings from "./export/safelog/SafelogSettings";
import PasswordReset from "./password/PasswordReset";
import PrivacySettings from "./privacy/PrivacySettings";
import ProfileSettings from "./profile/ProfileSettings";
import RosterSettings from "./roster/RosterSettings";
import ThirdpartySettings from "./thirdparty/ThirdpartySettings";

export const SettingsPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Row className="no-gutters justify-content-center">
      <Col md={12} lg={10} xl={8}>
        <div className="content content-full">
          <Row>
            <Col md={4} style={{ marginBottom: "0.875rem" }}>
              <Card className="block-content block-content-full" style={{ display: "flex" }}>
                <div className="p-2" style={{ width: "100%" }}>
                  <h3 className="text-muted" style={{ marginBottom: "1rem" }}>
                    {t("preferences.account")}
                  </h3>
                  <Nav variant="pills" className="flex-column" style={{ margin: 0 }}>
                    <SettingsCategory
                      to={RouteURL.SETTINGS_PROFILE}
                      label={t("preferences.profile.title")}
                      icon={RouteIcon.SETTINGS_PROFILE}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_DISPLAY}
                      label={t("preferences.display.title")}
                      icon={RouteIcon.SETTINGS_DISPLAY}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_ROSTER}
                      label={t("preferences.roster.title")}
                      icon={RouteIcon.SETTINGS_ROSTER}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_EVENT}
                      label={t("preferences.events.title")}
                      icon={RouteIcon.SETTINGS_EVENT}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_PRIVACY}
                      label={t("preferences.privacy.title")}
                      icon={RouteIcon.SETTINGS_PRIVACY}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_RESET_PASSWORD}
                      label={t("preferences.reset_password.title")}
                      icon={RouteIcon.SETTINGS_RESET_PASSWORD}
                    />
                  </Nav>

                  <h3 className="text-muted" style={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
                    {t("preferences.export")}
                  </h3>
                  <Nav variant="pills" className="flex-column" style={{ margin: 0 }}>
                    <SettingsCategory
                      to={RouteURL.SETTINGS_RB_LOGBOOK}
                      label={t("preferences.rb_logbook.title")}
                      icon={RouteIcon.SETTINGS_RB_LOGBOOK}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_SAFELOG}
                      label={t("preferences.safelog.title")}
                      icon={RouteIcon.SETTINGS_SAFELOG}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_CALENDAR}
                      label={t("preferences.calendar.title")}
                      icon={RouteIcon.SETTINGS_CALENDAR}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_CALENDAR_FILTER}
                      label={t("preferences.calendar_filter.title")}
                      icon={RouteIcon.SETTINGS_CALENDAR_FILTER}
                    />
                    <SettingsCategory
                      to={RouteURL.SETTINGS_THIRD_PARTY}
                      label={t("third_party_settings.title")}
                      icon={RouteIcon.SETTINGS_THIRD_PARTY}
                    />
                  </Nav>
                </div>
              </Card>
            </Col>

            <Col md={8} style={{ margin: "0 auto" }}>
              <Card className="block-content">
                <div style={{ padding: "1.5rem 0.5rem" }}>
                  <Switch>
                    <Route exact path={RouteURL.SETTINGS_PROFILE} component={ProfileSettings} />
                    <Route exact path={RouteURL.SETTINGS_DISPLAY} component={DisplaySettings} />
                    <Route exact path={RouteURL.SETTINGS_ROSTER} component={RosterSettings} />
                    <Route exact path={RouteURL.SETTINGS_EVENT} component={EventSettings} />
                    <Route exact path={RouteURL.SETTINGS_CALENDAR} component={CalendarSettings} />
                    <Route exact path={RouteURL.SETTINGS_CALENDAR_FILTER} component={CalendarFilterSettings} />
                    <Route exact path={RouteURL.SETTINGS_RB_LOGBOOK} component={RBLogbookSettings} />
                    <Route exact path={RouteURL.SETTINGS_SAFELOG} component={SafelogSettings} />
                    <Route exact path={RouteURL.SETTINGS_PRIVACY} component={PrivacySettings} />
                    <Route exact path={RouteURL.SETTINGS_RESET_PASSWORD} component={PasswordReset} />
                    <Route exact path={RouteURL.SETTINGS_THIRD_PARTY} component={ThirdpartySettings} />
                    <Redirect to={RouteURL.SETTINGS_PROFILE} />
                  </Switch>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SettingsPage;
