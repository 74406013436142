import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";

interface IProps {
  size?: "lg";
  showBackBtn?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
}

const handleOnHide = (): void => History.push(RouteURL.HOME);

const backToMenu = (): void => History.push(RouteURL.UPLOAD);

const UploadModal = (props: IProps): JSX.Element => {
  const { size, showBackBtn, children } = props;

  const { t } = useTranslation();

  return (
    <Modal id="upload-roster-modal" show size={size} centered onHide={handleOnHide}>
      <Modal.Body>
        <div style={{ textAlign: "right" }}>
          <Button variant="link" onClick={handleOnHide}>
            <i className="fa fa-times" style={{ fontSize: "1.5rem" }} />
          </Button>
        </div>

        <div className="p-3">
          <ComponentBoundary componentName="Upload Roster">{children}</ComponentBoundary>

          {showBackBtn && (
            <p className="mt-3 mb-0 d-lg-flex justify-content-between">
              <SecondaryButton label={t("back_btn")} icon="arrow-left" onClick={backToMenu} />
            </p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
