//tslint:disable no-magic-numbers
import queryString from "query-string";
import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import backGroundImage from "../../../assets/images/background/sign-in-bg.jpg";
import rbLogoBlue from "../../../assets/images/logo/rb-logo-blue.png";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { RouteSource, RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";
import PageHead from "../../PageHead";
import ResetPasswordForm from "./ResetPasswordForm";

export const ResetPasswordPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  /**
   * Temporary reroute to RB Logbook forgot password flow.
   * Todo: This can be removed when https://rosterbuster.atlassian.net/browse/WA-2768 is done.
   */
  useEffect(() => {
    const queryParams: any = queryString.parse(props.location.search);
    if (queryParams.source === RouteSource.RB_LOGBOOK) {
      History.replace(RouteURL.LOGBOOK_FORGOT_PASSWORD);
    }
  }, []);

  return (
    <>
      <PageHead title={PageTitle.FORGOT_PASSWORD} description={PageDescription.FORGOT_PASSWORD} />

      <div className="bg-image" style={{ backgroundImage: `url(${backGroundImage})` }}>
        <Row className="no-gutters">
          <Col md={6} className="hero-static d-flex align-items-center bg-white">
            <div className="p-3 w-100">
              <div className="mb-3 text-center">
                <Image src={rbLogoBlue} style={{ height: "150px", width: "auto" }} />
                <p className="text-uppercase font-w700 font-size-sm text-muted">{t("reset_password_title")}</p>
              </div>
              <Row className="no-gutters justify-content-center">
                <Col sm={8} xl={6}>
                  <ResetPasswordForm {...props} />
                </Col>
              </Row>
            </div>
          </Col>

          <Col
            md={6}
            className="hero-static d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
            <div className="p-3">
              <p className="display-4 font-w700 text-white mb-3">{t("app_name")}</p>
              <p className="font-size-lg font-w600 text-white-75 mb-0">{t("app_subtitle")}</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPasswordPage;
