import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { JobTypes } from "../../constants/JobType";
import { IJobType } from "../../interfaces";

interface IProps {
  name: string;
  placeholder: string;
  defaultJob?: IJobType;
  onChange: Function;
  fieldTouched: Function;
  errors: string | undefined;
  touched: boolean | undefined;
}

const getDefault = (defaultValue: IJobType): Array<IJobType> | undefined => {
  return new Array<IJobType>(defaultValue);
};

const filter = (job: IJobType, props: any): boolean => {
  if (props.selected.length) {
    // Display all the options if there's a selection.
    return true;
  }
  // Otherwise filter on criteria.
  return job.name.toLowerCase().includes(props.text.toLowerCase());
};

const JobSelector = (props: IProps): JSX.Element => {
  const { name, placeholder, defaultJob, errors, touched, onChange, fieldTouched } = props;

  const handleOnBlur = (): void => fieldTouched(name, true);

  const handleOnChange = (job: IJobType): void => (job ? onChange(name, job.value) : onChange(name, ""));

  return (
    <>
      <Typeahead
        id="position-selection"
        defaultSelected={defaultJob && defaultJob.name ? getDefault(defaultJob) : undefined}
        placeholder={placeholder}
        options={JobTypes}
        filterBy={filter}
        labelKey={(job: IJobType) => job.name}
        onBlur={handleOnBlur}
        onChange={(selected: Array<IJobType>) => handleOnChange(selected[0])}
        inputProps={{ style: { border: "none", backgroundColor: "#f4f6fa" } }}
      />
      {errors && touched && <p className="input-feedback">{errors}</p>}
    </>
  );
};

export default JobSelector;
