//tslint:disable
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
//import { Dutch } from "./localization/languages/Dutch";
import { English } from "./languages/English";

i18n.use(detector).init({
  resources: {
    en: English
    //  nl: Dutch
  } as any,
  lng: navigator.language,
  fallbackLng: "en",

  ns: ["translations"],
  defaultNS: "translations",

  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  }
});

export default i18n;
