import { AxiosResponse } from "axios";
import { IData, ISignInCredentials, IToken } from "../../interfaces";
import { logbookSignIn } from "../../services/api/AuthApiService";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { logbookUserService } from "./LogbookUserService";

export class LogbookSignInService {
  /**
   * Signs the user in
   *
   * @param credentials User's credentials
   */
  public async signIn(credentials: ISignInCredentials): Promise<void> {
    try {
      const response: AxiosResponse<IData<IToken>> = await logbookSignIn(credentials);

      createAuthStore().setTokenForLB(response.data.data);

      await logbookUserService().fetchUser();
    } catch (error) {
      throw error;
    }
  }
}

let service: LogbookSignInService | null = null;

export const logbookSignInService = (): LogbookSignInService => service ?? (service = new LogbookSignInService());
