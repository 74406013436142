import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import SettingsRow from "../../../components/settings/SettingsRow";
import { PageDescription, PageTitle } from "../../../enums/Page";
import PageHead from "../../PageHead";
import { thirdpartyService } from "../../../services/thirdparty/ThirdpartyService";
import { IThirdpartyDataAllowedByUser } from "../../../interfaces";
import { createUserStore } from "../../../stores/user/UserStore";
import { Button } from "react-bootstrap";

const ThirdpartySettings = (): JSX.Element => {
  const { t } = useTranslation();
  const user_id = createUserStore().userData?.user?.pk;

  const handleThirdpartyDataAllowedByUser = async (): Promise<IThirdpartyDataAllowedByUser> => {
    try{
      return await thirdpartyService().getAllUserAllowedThirdpartyData(user_id);
    } catch (error) {
      throw error;
    }
  }

  const handleRemoveConsumerScopes = async (consumer: any) : Promise<void> => {
    try {
      await thirdpartyService().removeUserAllowedThirdpartyData(consumer, user_id);
      handleThirdpartyDataAllowedByUser().then(response => setThirdpartyData(response));
    } catch (error) {
      throw error
    }
  }

  const defaultData: IThirdpartyDataAllowedByUser = {
    consumer: {
      consumer_name: "",
      scopes: [""]
    }
  }

  const [thirdpartyData, setThirdpartyData] = useState<IThirdpartyDataAllowedByUser>(defaultData);

  useEffect(() => {
    handleThirdpartyDataAllowedByUser().then(response => setThirdpartyData(response));
  }, []);
  
  let consumers: any[] = [];
  Object.entries(thirdpartyData).forEach((consumer, index) => {
    if (!consumer[1].consumer_name)
    {
      return consumers = [<p key={index} className= "text-center">{t("third_party_cannot_find_third_party")}</p>]
    }
    consumers.push(
    <SettingsRow
      key={index}
      label={consumer[1].consumer_name}
      description={consumer[1].scopes.join(", ")}>
        <div className= "d-flex">
          <div className= "w-100"> 
            <p>{t("third_party_settings.third_party.description")}</p>
          </div>
          <Button className="text-right btn-danger h-100" onClick={() => handleRemoveConsumerScopes(consumer[0])}>{t("third_party_remove_btn")}</Button>
        </div>
    </SettingsRow>
    )});

    if (!consumers.length)
    {
      return <p className= "text-center">{t("third_party_cannot_find_third_party")}</p>
    }

    return (
      <ComponentBoundary componentName="Third party settings">
        <PageHead title={PageTitle.SETTINGS_THIRD_PARTY} description={PageDescription.SETTINGS_THIRD_PARTY} />
        {consumers}
      </ComponentBoundary>
    );
  }

export default ThirdpartySettings;
