import React from "react";
import { useTranslation } from "react-i18next";
import CustomButtonGroup from "../../../components/inputs/CustomButtonGroup";
import SettingsRow from "../../../components/settings/SettingsRow";
import { IToggleButtonOption } from "../../../interfaces";

enum Metrics {
  CELSIUS = "C",
  FAHRENHEIT = "F"
}

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const DisplayTemperature = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const options: Array<IToggleButtonOption> = [
    { label: t("celsius"), value: Metrics.CELSIUS },
    { label: t("fahrenheit"), value: Metrics.FAHRENHEIT }
  ];

  return (
    <SettingsRow label={t("settings_display_temperature_label")} description={t("settings_display_temperature_desc")}>
      <CustomButtonGroup defaultValue={defaultValue ?? Metrics.CELSIUS} onChange={onChange} options={options} />
    </SettingsRow>
  );
};

export default DisplayTemperature;
