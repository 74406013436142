import { AxiosResponse } from "axios";
import { action } from "mobx";
import { IAirport } from "../../interfaces";
import { fetchAirports } from "../api/ApiService";

export class AirportService {
  /**
   * Fetch all airports or by query
   *
   * @param {string} -> query to fetch specific airports
   */
  @action.bound
  public async fetchAirports(query?: string): Promise<Array<IAirport>> {
    try {
      const inputQuery: string = query ?? "";

      const response: AxiosResponse = await fetchAirports(inputQuery);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

let service: AirportService | null = null;

export const airportService = (): AirportService => service ?? (service = new AirportService());
