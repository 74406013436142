//tslint:disable: no-magic-numbers
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  appName: string;
  appSubtitle?: string;
  logo: string;
  backgroundImage: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

const GenericSignInPage = (props: IProps): JSX.Element => {
  const { appName, appSubtitle, logo, backgroundImage, children } = props;

  const { t } = useTranslation();

  return (
    <div className="bg-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className="no-gutters">
        <Col md={6} className="hero-static d-flex align-items-center bg-white">
          <div className="p-3 w-100">
            <div className="mb-3 text-center">
              <Image src={logo} style={{ height: "150px", width: "auto" }} />
              <p className="text-uppercase font-w700 font-size-sm text-muted">{t("sign_in_title")}</p>
            </div>
            <Row className="no-gutters justify-content-center">
              <Col sm={8} xl={6}>
                {children}
              </Col>
            </Row>
          </div>
        </Col>

        <Col
          md={6}
          className="hero-static d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
          <div className="p-3">
            <p className="display-4 font-w700 text-white mb-3">{appName}</p>
            <p className="font-size-lg font-w600 text-white-75 mb-0">{appSubtitle ?? ""}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenericSignInPage;
