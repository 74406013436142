import { AxiosInstance, AxiosResponse } from "axios";
import { action } from "mobx";
import { IData, IToken } from "../../interfaces";
import { AbstractHttpService } from "../../services/api/AbstractHttpService";
import { refreshLBToken } from "../../services/api/AuthApiService";
import { signOut } from "../../services/auth/SignOutService";
import { createAuthStore } from "../../stores/auth/AuthStore";

export class LogbookHttpService extends AbstractHttpService {
  /**
   * Returns a client for authentication.
   */
  public guestAuthClient(): AxiosInstance {
    return this.createGuestClient(process.env.REACT_APP_AUTH_API_LB!);
  }

  public async paymentClient(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_PAYMENT_API_LB!);
  }

  public async client(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_API_LB!);
  }

  public guestClient(): AxiosInstance {
    return this.createGuestClient(process.env.REACT_APP_API_LB!);
  }

  /**
   * Returns an authorized Auth-API client.
   */
  public async authClient(): Promise<AxiosInstance> {
    return await this.createClient(process.env.REACT_APP_AUTH_API_LB!);
  }

  /**
   * Refreshes an expired token
   *
   * @param {IToken} -> Expired token
   */
  @action.bound
  public async refreshToken(token: IToken): Promise<void> {
    try {
      const response: AxiosResponse<IData<IToken>> = await refreshLBToken(token);

      const newToken: IToken = response.data.data;

      createAuthStore().setTokenForLB(newToken);
    } catch (error) {
      signOut();
    }
  }
}

let service: LogbookHttpService | null = null;

export const logbookHttpService = (): LogbookHttpService => service ?? (service = new LogbookHttpService());
