import React from "react";

interface IProps {
  children?: React.ReactNode;
}

const PageContainer = (props: IProps): JSX.Element => {
  return (
    <div id="page-container" className="main-content-narrow">
      {props.children}
    </div>
  );
};

export default PageContainer;
