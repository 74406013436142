import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import queryString from "query-string";
import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import SubmitButton from "../../../components/buttons/SubmitButton";
import ReCaptcha from "../../../components/inputs/ReCaptcha";
import TextField from "../../../components/inputs/TextField";
import { blockedEmailDomains } from "../../../constants/BlockedEmailDomains";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { resetPasswordSchema } from "../../../utils/Validation";
import { logbookPasswordService } from "../../services/auth/LogbookPasswordService";

type IFormValues = {
  email: string;
  recaptchaToken: string;
};

const ResetPasswordForm = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const getEmail = (): string | undefined => {
    const { email }: any = queryString.parse(props.location.search);
    return email;
  };

  const recaptchaRef: React.MutableRefObject<any> = useRef<React.MutableRefObject<any>>();

  const handleResetPassword = async (values: IFormValues): Promise<void> => {
    try {
      const domain: string | undefined = values.email.split("@").pop();
      if (domain && blockedEmailDomains.includes(domain)) {
        throw `It is not possible to reset the password for ${values.email}, since we have an enterprise agreement with your airline`;
      }
      await logbookPasswordService().resetPassword(values.email, values.recaptchaToken);
    } catch (error) {
      throw error;
    }
  };

  const handleSignIn = (): void => History.push(RouteURL.LOGBOOK_SIGN_IN);

  return (
    <Formik
      initialValues={{
        email: getEmail() || "",
        recaptchaToken: ""
      }}
      validationSchema={resetPasswordSchema}
      onSubmit={async (values: IFormValues, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);
          await handleResetPassword(values);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          recaptchaRef.current.reset();
          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<any>) => {
        const { errors, touched, setFieldValue, isSubmitting } = props;

        return (
          <FormikForm>
            <div className="py-3">
              <Form.Group>
                <TextField placeholder={t("email")} type="email" name="email" />
              </Form.Group>
              <Form.Group>
                <ReCaptcha
                  name="recaptchaToken"
                  ref={recaptchaRef}
                  onChange={setFieldValue}
                  errors={errors.recaptchaToken}
                  touched={touched.recaptchaToken}
                />
              </Form.Group>
            </div>

            <Form.Group>
              <SubmitButton
                label={t("reset_password_title")}
                loadingLabel={t("reset_password_btn_loading_label")}
                icon="unlock-alt"
                disabled={isSubmitting}></SubmitButton>

              <p className="mt-3 mb-0 d-lg-flex">
                <SecondaryButton label={t("auth.back_to_sign_in")} icon="arrow-left" onClick={handleSignIn} />
              </p>
            </Form.Group>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
