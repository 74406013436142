import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import defaultAvatar from "../../assets/images/default-avatar.png";

export enum AvatarSize {
  XS = 2.5,
  S = 3,
  M = 4,
  L = 5,
  XL = 6
}

interface IProps {
  className?: string;
  url: string;
  size: AvatarSize;
}

const UserAvatar = (props: IProps): JSX.Element => {
  const { className, url, size } = props;
  const [hasError, setHasError] = useState<boolean>(false);

  //Reset error state when url changes.
  useEffect(() => setHasError(false), [url]);

  return (
    <Image
      className={"user-avatar " + (className ? className : "")}
      src={hasError ? defaultAvatar : url}
      style={{ height: size + "rem", width: size + "rem" }}
      onError={() => setHasError(true)}
    />
  );
};

export default UserAvatar;
