import React from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import i18n from "../../../../localization/i18n";

interface IProps {
  textBox: number;
  onClick: () => void;
}

const style: React.CSSProperties = {
  display: "block",
  width: "100%",
  margin: "3rem 0",
  fontSize: "1rem",
  borderRadius: "50px",
  backgroundColor: "#FF7300"
};

const UploadTextButton = (props: IProps): JSX.Element | null => {
  if (props.textBox !== 1) {
    return null;
  }

  return (
    <PrimaryButton
      className="btn-hero-lg"
      icon="paste"
      label={i18n.t("upload_text_title")}
      onClick={props.onClick}
      style={style}
    />
  );
};

export default UploadTextButton;
