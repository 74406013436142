import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { IAuthorizeThirdparty } from "../../../interfaces";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { signInSchema } from "../../../utils/Validation";
import SubmitButton from "./../../../components/buttons/SubmitButton";
import TextField from "./../../../components/inputs/TextField";
import { RouteComponentProps } from "react-router-dom";

interface IProps {
  onSubmit: (values: IAuthorizeThirdparty) => Promise<void>;
  denyThirdpartysAuthorize: (values: IAuthorizeThirdparty) => string;
  queryParams: any;
  routeComponentProps: RouteComponentProps;
}

export const ThirdpartyAuthorizeForm = (props: IProps): JSX.Element => {
  const { onSubmit, denyThirdpartysAuthorize } = props;
  const { t } = useTranslation();
  const onDeny: string = denyThirdpartysAuthorize(props.queryParams);

  return (
    <Formik
      initialValues={{
        email: "",
        signInPassword: "",
        consumer_key: props.queryParams.consumer_key || "",
        redirect_uri: props.queryParams.redirect_uri || "",
        code_challenge: props.queryParams.code_challenge || "",
        state: props.queryParams.state || ""
      }}
      validationSchema={signInSchema}
      onSubmit={async (values: IAuthorizeThirdparty, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);

          await onSubmit(values);
        } catch (error) {
          showErrorMessage(error);

          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<any>) => (
        <FormikForm>
          <div className="py-3">
            <Form.Group>
              <TextField placeholder={t("email")} type="email" name="email" />
            </Form.Group>
            <Form.Group>
              <TextField placeholder={t("password")} type="password" name="signInPassword" />
            </Form.Group>
          </div>
          <Form.Group className="d-flex">
            <div style={{ display: props.isSubmitting ? "none" : "initial" }}>
              <a className="btn-hero-lg btn-danger btn thirdparty-deny-btn" href={onDeny}>
                {t("third_party_deny_title")}
              </a>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <SubmitButton
                label={t("third_party_authorize_title")}
                loadingLabel={t("third_party_authorize_in_btn_loading_label")}
                disabled={props.isSubmitting}
              />
            </div>
          </Form.Group>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ThirdpartyAuthorizeForm;
