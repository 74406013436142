import { action } from "mobx";
import { RBApp } from "../../../enums/RBApp";
import { AbstractScopedRequestService } from "./AbstractScopedRequestService";

export class ScopedRequestServiceRB extends AbstractScopedRequestService {
  @action.bound
  protected async authenticate(tempToken: string): Promise<void> {
    try {
      // Check if already authenticated.
      if (this.authStore.authenticated && this.authStore.app === RBApp.ROSTERBUSTER) {
        return;
      }

      if (!tempToken) {
        throw new Error(this.AUTH_ERROR);
      }

      this.authStore.setTempTokenForRB(tempToken);
    } catch (error) {
      throw error;
    }
  }
}

let service: ScopedRequestServiceRB | null = null;

export const scopedRequestServiceRB = (): ScopedRequestServiceRB => service ?? (service = new ScopedRequestServiceRB());
