import { AxiosResponse } from "axios";
import { IExportEventRequest } from "../../interfaces";
import { exportEvents, exportToPDF } from "../api/ApiService";

class ExportEventService {
  /**
   * Exports events to the chosen provider (RB Logbook, SafeLog, LogTen, etc.)
   *
   * @param {IExportEventRequest} request Contains the provider, start and end date
   */
  public async export(request: IExportEventRequest): Promise<void> {
    try {
      await exportEvents(request);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Exports events to LogTen
   *
   * @param {IExportEventRequest} request Contains the provider, start and end date
   */
  public async exportToLogTen(request: IExportEventRequest): Promise<void> {
    try {
      const response: AxiosResponse = await exportEvents(request);

      window.location.href = response.data.prefix + response.data.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Exports events to CrewAlert
   *
   * @param {IExportEventRequest} request Contains the provider, start and end date
   */
  public async exportToCrewAlert(request: IExportEventRequest): Promise<void> {
    try {
      const response: AxiosResponse = await exportEvents(request);

      window.location.href = response.data.prefix + JSON.stringify(response.data.data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Exports events to CSV file
   *
   * @param {IExportEventRequest} request Contains the start and end date
   */
  public async exportCSV(request: IExportEventRequest): Promise<void> {
    try {
      const response: AxiosResponse = await exportEvents(request);

      this.createCSV(request.start, request.end, response.data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Exports events to ICS file
   *
   * @param {IExportEventRequest} request Contains the start and end date
   */
  public async exportICS(request: IExportEventRequest): Promise<void> {
    try {
      const response: AxiosResponse = await exportEvents(request);

      this.downloadFile(response.data.url);
    } catch (error) {
      throw error;
    }
  }

  public async exportPDF(request: IExportEventRequest): Promise<void> {
    try {
      const response: AxiosResponse = await exportToPDF(request);
      this.createPDF(request.start, request.end, response.data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Creates a CSV File
   *
   * @param startDate Start date of the roster
   * @param endDate End date of the roster
   * @param data The response data
   */
  private createCSV(startDate: string, endDate: string, data: string): void {
    const csvContent: string = "data:text/csv;charset=utf-8," + data;

    const encodedUri: string = encodeURI(csvContent);

    const fileName: string = `Roster_${startDate}_${endDate}.csv`;

    this.downloadFile(encodedUri, fileName);
  }

  /**
   * Creates a PDF File
   *
   * @param startDate Start date of the roster
   * @param endDate End date of the roster
   * @param data The response data
   */
  private createPDF(startDate: string, endDate: string, data: string): void {
    const file: Blob = new Blob([data], { type: "application/pdf" });

    const fileURL: string = URL.createObjectURL(file);

    this.downloadFile(fileURL, `Roster_${startDate}_${endDate}.pdf`);
  }

  /**
   * Downloads the export file.
   *
   * @param {string} uri The uri/url of the file
   * @param {string} fileName Optional file name
   */
  private downloadFile(uri: string, fileName?: string): void {
    const link: HTMLAnchorElement = document.createElement("a");

    link.href = uri;

    if (fileName) {
      link.download = fileName;
    }

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}

let service: ExportEventService | null = null;

export const exportEventService = (): ExportEventService => service ?? (service = new ExportEventService());
