import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageLoader from "../../components/loaders/PageLoader";
import { PageDescription, PageTitle } from "../../enums/Page";
import { userService } from "../../services/user/UserService";
import CompleteProfilePage from "../auth/complete-profile/CompleteProfilePage";
import ErrorPage from "../error/ErrorPage";
import PageHead from "../PageHead";

const CompleteProfileCampaignPage = (props: RouteComponentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [successful, setSuccessful] = useState<boolean>(false);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async (): Promise<void> => {
    try {
      await userService().getUser();
      setSuccessful(true);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return successful ? (
    <>
      <PageHead title={PageTitle.COMPLETE_PROFILE} description={PageDescription.COMPLETE_PROFILE} />
      <CompleteProfilePage />
    </>
  ) : (
    <ErrorPage {...props} />
  );
};

export default CompleteProfileCampaignPage;
