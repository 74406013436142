import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import GenericUnsubscribePage from "../../../components/generics/GenericUnsubscribePage";
import PageLoader from "../../../components/loaders/PageLoader";
import { RBApp } from "../../../enums/RBApp";
import ErrorPage from "../../../pages/error/ErrorPage";
import { logbookUserService } from "../../services/LogbookUserService";

const LogbookUnsubscribe = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const [successful, setSuccessful] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleUnsubscribe: () => Promise<void> = useCallback(async (): Promise<void> => {
    try {
      await logbookUserService().unsubscribeUserFromEmails();

      setSuccessful(true);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleUnsubscribe().catch();
  }, [handleUnsubscribe]);

  if (loading) {
    return <PageLoader />;
  }

  return successful ? (
    <GenericUnsubscribePage appName={t("logbook.app_name")} messagePath="unsubscribe" />
  ) : (
    <ErrorPage {...props} app={RBApp.LOGBOOK} />
  );
};

export default LogbookUnsubscribe;
