//tslint:disable no-magic-numbers
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import backGroundImage from "../../../assets/images/background/logbook-bg.jpg";
import rbLogoBlue from "../../../assets/images/logo/rb-logbook-logo.png";
import { PageDescription, PageTitle } from "../../../enums/Page";
import PageHead from "../../../pages/PageHead";
import LogbookResetPasswordForm from "./ResetPasswordForm";

export const ResetPasswordPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PageHead title={PageTitle.FORGOT_PASSWORD} description={PageDescription.FORGOT_PASSWORD} />

      <div className="bg-image" style={{ backgroundImage: `url(${backGroundImage})` }}>
        <Row className="no-gutters">
          <Col md={6} className="hero-static d-flex align-items-center bg-white">
            <div className="p-3 w-100">
              <div className="mb-3 text-center">
                <Image src={rbLogoBlue} style={{ height: "150px", width: "auto" }} />
                <p className="text-uppercase font-w700 font-size-sm text-muted">{t("reset_password_title")}</p>
              </div>
              <Row className="no-gutters justify-content-center">
                <Col sm={8} xl={6}>
                  <LogbookResetPasswordForm {...props} />
                </Col>
              </Row>
            </div>
          </Col>

          <Col
            md={6}
            className="hero-static d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
            <div className="p-3">
              <p className="display-4 font-w700 text-white mb-3">{t("logbook.app_name")}</p>
              <p className="font-size-lg font-w600 text-white-75 mb-0">{t("logbook.app_subtitle")}</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPasswordPage;
