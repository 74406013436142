import {
  authorizeThirdparty,
  allThirdpartyData,
  allUserAllowedThirdpartyData,
  removeUserAllowedThirdpartyData,
  authorizeThirdpartyLogbook
} from "../api/AuthApiService";
import { IAuthorizeThirdparty, IThirdpartyData, IThirdpartyDataAllowedByUser } from "../../interfaces";
import { RouteURL } from "../../enums/routing/Route";
import { AxiosResponse } from "axios";

export class ThirdpartyService {
  /**
   * Makes call to authentication api to generate an authorization code for the third party.
   */
  public async authorizeThirdparty(input: IAuthorizeThirdparty, logbook: boolean): Promise<AxiosResponse<any>> {
    try {
      if (!logbook) {
        return await authorizeThirdparty(input);
      } else {
        return await authorizeThirdpartyLogbook(input);
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Returns a new url with the given redirect uri with fail parameters or
   * returns sign in page endpoint if redirect uri is undefined.
   */
  public denyThirdpartyAuthorize(input: IAuthorizeThirdparty): string {
    if (input.redirect_uri === undefined) {
      return RouteURL.SIGN_IN;
    }
    const denyUrl = new URL(input.redirect_uri);

    denyUrl.searchParams.append("status", "FAIL");
    if (input.state !== undefined) {
      denyUrl.searchParams.append("state", input.state);
    }

    return denyUrl.href;
  }

  /**
   * Makes call to authentication api to get all scopes, name and platform of the third party.
   */
  public async getAllThirdpartyData(consumer_key: string): Promise<IThirdpartyData> {
    try {
      return (await allThirdpartyData(consumer_key)).data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Makes call to authentication api to get all scopes, name and platform of the third party that was allowed by the user.
   */
  public async getAllUserAllowedThirdpartyData(user_id?: number): Promise<IThirdpartyDataAllowedByUser> {
    try {
      return (await allUserAllowedThirdpartyData(user_id)).data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Makes call to authentication api to get all scopes, name and platform of the third party that was allowed by the user.
   */
  public async removeUserAllowedThirdpartyData(
    consumer_id: number,
    user_id?: number
  ): Promise<IThirdpartyDataAllowedByUser> {
    try {
      return (await removeUserAllowedThirdpartyData(consumer_id, user_id)).data;
    } catch (error) {
      throw error;
    }
  }
}

let service: ThirdpartyService | null = null;

export const thirdpartyService = (): ThirdpartyService => service ?? (service = new ThirdpartyService());
