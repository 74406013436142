import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { IPreferences, IUserData } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import EventDateFormat from "./EventDateFormat";
import EventDutyFilter from "./EventDutyFilter";
import EventGenerateReport from "./EventGenerateReport";
import EventWeekStart from "./EventWeekStart";

const EventSettings: () => JSX.Element = observer(() => {
  const userData: IUserData = createUserStore().userData!;
  const preference: IPreferences = userData.preferences;

  const updateUserPref = async (pref: Preference, value: string): Promise<void> => {
    try {
      await settingsService().updatePreference(pref, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleDateFormat = async (value: string): Promise<void> => {
    preference.dateformat = value;
    await updateUserPref(Preference.DATE_FORMAT, value);
  };

  const handleWeekStart = async (value: string): Promise<void> => {
    preference.calendar_weekday = value;
    await updateUserPref(Preference.CALENDAR_WEEK_DAY, value);
  };

  const handleReportGenerate = async (value: string): Promise<void> => {
    preference.report_minutes = value;
    await updateUserPref(Preference.REPORT_GENERATE, value);
  };

  const handleDutyFilter = async (value: string): Promise<void> => {
    preference.dutycode_filter = value;
    await updateUserPref(Preference.DUTY_FILTER, value);
  };

  return (
    <ComponentBoundary componentName="Event settings">
      <PageHead title={PageTitle.SETTINGS_EVENT} description={PageDescription.SETTINGS_EVENT} />

      <EventDateFormat defaultValue={preference?.dateformat} onChange={handleDateFormat} />

      <EventWeekStart defaultValue={preference?.calendar_weekday} onChange={handleWeekStart} />

      <EventGenerateReport defaultValue={preference?.report_minutes} onChange={handleReportGenerate} />

      <EventDutyFilter defaultValue={preference?.dutycode_filter} onChange={handleDutyFilter} />
    </ComponentBoundary>
  );
});

export default EventSettings;
