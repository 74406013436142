import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../../components/buttons/SubmitButton";
import TextField from "../../../../components/inputs/TextField";
import { IChangePassword, IUser, IUserData } from "../../../../interfaces";
import { logbookPasswordService } from "../../../services/auth/LogbookPasswordService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../../stores/user/UserStore";
import { changePasswordSchema } from "../../../../utils/Validation";

interface IProps {
  onSuccess: () => void;
}

const ChangePasswordForm = (props: IProps): JSX.Element => {
  const { onSuccess } = props;

  const { t } = useTranslation();

  const userData: IUser | null = createUserStore().logbookUser;
  const email: string = userData?.email ?? "";

  const handleSubmit = async (newPassword: IChangePassword): Promise<void> => {
    try {
      await logbookPasswordService().setNewPassword(newPassword);
      onSuccess();
    } catch (error) {
      throw error;
    }
  };

  return (
    <Formik
      initialValues={{
        email: email,
        new_password: "",
        new_password_confirmation: ""
      }}
      validationSchema={changePasswordSchema}
      onSubmit={async (values: IChangePassword, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);

          await handleSubmit(values);
        } catch (error) {
          showErrorMessage(error);

          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<IChangePassword>) => (
        <FormikForm>
          <div className="py-3">
            <Form.Group>
              <TextField placeholder={t("email")} type="email" name="email" disabled />
            </Form.Group>

            <Form.Group>
              <TextField placeholder={t("change_password.new_password")} type="password" name="new_password" />
            </Form.Group>

            <Form.Group>
              <TextField placeholder={t("confirm_password")} type="password" name="new_password_confirmation" />
            </Form.Group>
          </div>

          <Form.Group>
            <SubmitButton label={t("confirm_btn")} loadingLabel={t("save_btn_loading")} disabled={props.isSubmitting} />
          </Form.Group>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
