//tslint:disable:no-magic-numbers
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import placeHolder from "../../assets/images/mobile-app-phone.png";
import AppStoreButton from "../../components/buttons/third-party/AppStoreButton";
import GooglePlayButton from "../../components/buttons/third-party/GooglePlayButton";
import { PageDescription, PageTitle } from "../../enums/Page";
import PageHead from "../PageHead";

const DownloadAppPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PageHead title={PageTitle.DOWNLOAD_APP} description={PageDescription.DOWNLOAD_APP} />

      <Row noGutters className="justify-content-center">
        <Col sm={10} md={10} lg={10} xl={8} className="hero-static d-flex p-2 px-sm-0">
          <Row noGutters style={{ width: "100%" }}>
            <Col md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className="block-content block-content-full" style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "3rem" }}>{t("download_app.title")}</h1>
                <h4>{t("download_app.subtitle")}</h4>
                <div>
                  <AppStoreButton />
                  <GooglePlayButton />
                </div>
              </div>
            </Col>

            <Col md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className="block-content block-content-full text-center">
                <Image src={placeHolder} style={{ maxHeight: "700px" }} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DownloadAppPage;
