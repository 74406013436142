import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";

const UploadRosterButton = (): JSX.Element | null => {
  const { t } = useTranslation();

  const handleOnClick = (): void => History.push(RouteURL.UPLOAD);

  if (!window.location.pathname.includes(RouteURL.HOME)) {
    return null;
  }

  return (
    <Button id="upload-btn" onClick={handleOnClick}>
      <i className="fa fa-cloud-upload-alt" />
      <span className="d-none d-md-inline ml-2">{t("upload_roster_btn")}</span>
    </Button>
  );
};

export default UploadRosterButton;
