import React from "react";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";
import SettingSwitch from "../../../components/settings/SettingSwitch";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => Promise<void>;
}

const ExcludeDayOff = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  return (
    <SettingsRow
      label={t("preferences.calendar_filter.exclude.day_off.label")}
      description={t("preferences.calendar_filter.exclude.day_off.description")}>
      <SettingSwitch id="exclude-day-off" initialValue={defaultValue} onChange={onChange} />
    </SettingsRow>
  );
};

export default ExcludeDayOff;
