import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, signInWithCustomToken } from "firebase/auth";
import { IToken } from "../../interfaces";
import { createAuthStore } from "../../stores/auth/AuthStore";

export const firebaseApp: FirebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

export class FirebaseAuthService {
  private readonly auth: Auth;

  public constructor() {
    this.auth = getAuth(firebaseApp);
  }

  public async signIn(): Promise<void> {
    const tokens: IToken = createAuthStore().token;
    if (tokens && tokens.access_token) {
      try {
        await signInWithCustomToken(this.auth, tokens.access_token);
      } catch (e) {
        console.log(e);
      }
    }
  }

  public async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }
}

let service: FirebaseAuthService | null = null;

export const createFirebaseAuthService = (): FirebaseAuthService => {
  if (service === null) {
    service = new FirebaseAuthService();
  }

  return service;
};
