import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import CancelButton from "../buttons/CancelButton";
import PrimaryButton from "../buttons/PrimaryButton";

interface IProps {
  title: string;
  description: string;
  onHide: () => void;
  onConfirm: () => void;
  size?: "sm" | "lg" | "xl";
}

const ConfirmationModal = (props: IProps): JSX.Element => {
  const { title, description, onHide, onConfirm, size } = props;

  const { t } = useTranslation();

  return (
    <Modal show onHide={onHide} size={size ? size : undefined} aria-labelledby="confirmation-modal-title" centered>
      <Modal.Header>
        <Modal.Title id="confirmation-modal-title" className="rb-color-primary">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <CancelButton label={t("cancel_btn")} onClick={onHide} />
        <PrimaryButton
          label={t("confirm_btn")}
          onClick={() => {
            onConfirm();
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
