import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { Preference } from "../../../../enums/settings/Preference";
import { IPreferences, IUserData } from "../../../../interfaces";
import { settingsService } from "../../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../../stores/user/UserStore";
import PageHead from "../../../PageHead";
import SafelogKey from "./SafelogKey";

const SafelogSettings: () => JSX.Element = observer(() => {
  const userData: IUserData = createUserStore().userData!;

  const preference: IPreferences = userData.preferences;

  const handleSafelogKey = async (value: string): Promise<void> => {
    try {
      preference.output_safelog_key = value;
      await settingsService().updatePreference(Preference.SAFELOG_KEY, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <ComponentBoundary componentName="Safelog settings">
      <PageHead title={PageTitle.SETTINGS_RB_LOGBOOK} description={PageDescription.SETTINGS_RB_LOGBOOK} />

      <SafelogKey defaultValue={preference?.output_safelog_key} onChange={handleSafelogKey} />
    </ComponentBoundary>
  );
});

export default SafelogSettings;
