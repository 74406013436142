import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import TagsInput from "react-tagsinput";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const EventDutyFilter = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const values: Array<string> | null = defaultValue ? defaultValue.split(",") : null;

  const [tags, setTags] = useState<Array<string>>(values ? values : new Array<string>());

  const handleChange = (tags: Array<string>): void => {
    setTags(tags);
    onChange(tags.join());
  };

  const defaultRenderTag = (props: TagsInput.RenderTagProps): React.ReactNode => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <Button
        size="sm"
        variant="outline-primary"
        key={key}
        className={classNameRemove + " mr-1"}
        {...other}
        onClick={() => onRemove(key)}>
        {getTagDisplayValue(tag)} &nbsp; x
      </Button>
    );
  };

  return (
    <SettingsRow label={t("settings_events_filter_events_label")} description={t("settings_events_filter_events_desc")}>
      <TagsInput
        onlyUnique
        renderTag={defaultRenderTag}
        value={tags}
        onChange={(tags: Array<string>) => handleChange(tags)}
        inputProps={{
          style: { marginTop: "0.875rem" },
          placeholder: t("settings_events_filter_events_placeholder")
        }}
      />
    </SettingsRow>
  );
};

export default EventDutyFilter;
