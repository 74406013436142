import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../components/buttons/SubmitButton";
import AirlineSelector from "../../../components/inputs/AirlineSelector";
import AirportSelector from "../../../components/inputs/AirportSelector";
import AvatarSelector from "../../../components/inputs/AvatarSelector";
import JobSelector from "../../../components/inputs/JobSelector";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";
import { IUserData } from "../../../interfaces";
import { userService } from "../../../services/user/UserService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import { onboardingProfileSchema } from "../../../utils/Validation";

type IFormValues = {
  avatar: File | undefined;
  jobtype: string;
  homeairline: string;
  base: string;
};

const handleSubmit = async (values: IFormValues): Promise<void> => {
  try {
    const user: {} = {
      jobtype: values.jobtype,
      homeairline: values.homeairline,
      base: values.base
    };

    if (values.avatar) {
      await userService().uploadAvatar(values.avatar);
    }

    await userService().updateUser(user);

    await userService().getUser(true);

    History.replace(RouteURL.HOME);
  } catch (error) {
    throw error;
  }
};

const getInitialValues = (): IFormValues => {
  const userData: IUserData | null = createUserStore().userData;

  return {
    avatar: undefined,
    jobtype: userData && userData.jobtype ? userData.jobtype.value : "",
    homeairline: userData && userData.homeairline ? userData.homeairline.pk : "",
    base: userData && userData.base ? userData.base.IATA : ""
  };
};

const CompleteProfileForm = (): JSX.Element => {
  const { t } = useTranslation();

  const userData: IUserData | null = createUserStore().userData;

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={onboardingProfileSchema}
      onSubmit={async (values: IFormValues, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);

          await handleSubmit(values);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<IFormValues>) => {
        const { errors, touched, setFieldValue, setFieldTouched, isSubmitting } = props;

        return (
          <FormikForm>
            <Form.Group>
              <AvatarSelector
                label={t("upload_avatar_btn_label")}
                name="avatar"
                onChange={(file: File) => setFieldValue("avatar", file)}
              />
            </Form.Group>

            <div className="py-3">
              <Form.Group>
                <JobSelector
                  name="jobtype"
                  placeholder={t("complete_profile_job_label")}
                  defaultJob={userData && userData.jobtype ? userData.jobtype : undefined}
                  onChange={setFieldValue}
                  fieldTouched={setFieldTouched}
                  errors={errors.jobtype}
                  touched={touched.jobtype}
                />
              </Form.Group>

              <Form.Group>
                <AirlineSelector
                  name="homeairline"
                  placeholder={t("complete_profile_airline_label")}
                  defaultAirline={userData && userData.homeairline ? userData.homeairline : undefined}
                  onChange={setFieldValue}
                  fieldTouched={setFieldTouched}
                  errors={errors.homeairline}
                  touched={touched.homeairline}
                />
              </Form.Group>

              <Form.Group>
                <AirportSelector
                  name="base"
                  placeholder={t("complete_profile_homebase_label")}
                  defaultAirport={userData && userData.base ? userData.base : undefined}
                  onChange={setFieldValue}
                  fieldTouched={setFieldTouched}
                  errors={errors.base}
                  touched={touched.base}
                />
              </Form.Group>
            </div>

            <Form.Group>
              <SubmitButton
                label={t("complete_profile_btn_label")}
                loadingLabel={t("complete_profile_btn_loading_label")}
                icon="address-card"
                disabled={isSubmitting}
              />
            </Form.Group>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default CompleteProfileForm;
