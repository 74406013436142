import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const AggregateFlights = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  return (
    <SettingsRow
      label={t("preferences.calendar.aggregate_flights.label")}
      description={t("preferences.calendar.aggregate_flights.description")}>
      <Form.Check
        type="switch"
        id="aggregate-flights"
        checked={defaultValue ? Boolean(Number(defaultValue)) : false}
        label=""
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(String(+event.target.checked))}
      />
    </SettingsRow>
  );
};

export default AggregateFlights;
