import React, { ReactNode } from "react";

interface IProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

const UploadHeader = (props: IProps): JSX.Element => {
  const { title, subtitle, children } = props;

  const titleStyle: React.CSSProperties = {
    fontWeight: "bold",
    marginBottom: "3rem",
    textAlign: "center"
  };

  return (
    <h1 className="rb-color-primary" style={titleStyle}>
      {title}
      {subtitle ? <p className="text-uppercase font-w700 font-size-sm text-muted">{subtitle}</p> : null}
      {children}
    </h1>
  );
};

export default UploadHeader;
