/**
 * TODO: Add these enums to localization and delete this file.
 */

export enum PageTitle {
  HOME = "RosterBuster - Home",
  SIGN_IN = "RosterBuster - Sign In",
  SIGN_UP = "RosterBuster - Sign Up",
  FORGOT_PASSWORD = "RosterBuster - Forgot Password",
  COMPLETE_PROFILE = "RosterBuster - Complete Profile",
  UPLOAD_ROSTER = "RosterBuster - Upload Roster",
  UPLOAD_ROSTER_PORTAL = "RosterBuster - Upload Portal",
  UPLOAD_ROSTER_FILE = "RosterBuster - Upload File",
  UPLOAD_ROSTER_SRC = "RosterBuster - Upload Source",
  DOWNLOAD_APP = "RosterBuster - Download App",
  CHANGE_PASSWORD = "RosterBuster - Change password",
  SETTINGS_PROFILE = "RosterBuster - Profile Settings",
  SETTINGS_DISPLAY = "RosterBuster - Display Settings",
  SETTINGS_ROSTER = "RosterBuster - Roster Settings",
  SETTINGS_EVENT = "RosterBuster - Event Settings",
  SETTINGS_CALENDAR = "RosterBuster - Calendar Settings",
  SETTINGS_CALENDAR_FILTER = "RosterBuster - Calendar Filter Settings",
  SETTINGS_RB_LOGBOOK = "RosterBuster - RB Logbook Settings",
  SETTINGS_SAFELOG = "RosterBuster - Safelog Settings",
  SETTINGS_PRIVACY = "RosterBuster - Privacy Settings",
  SETTINGS_RESET_PASSWORD = "RosterBuster - Reset Password",
  THIRD_PARTY_AUTHORIZE = "RosterBuster - Third party authorization",
  SETTINGS_THIRD_PARTY = "RosterBuster - Third party settings",

  LOGBOOK_SIGN_IN = "Logbook - Sign In"
}

export enum PageDescription {
  HOME = "Simplify your life, on and off the runway.",
  SIGN_IN = "Sign In to your RosterBuster account.",
  SIGN_UP = "Create a new RosterBuster account.",
  FORGOT_PASSWORD = "Recover your lost RosterBuster password.",
  COMPLETE_PROFILE = "Complete your RosterBuster profile.",
  UPLOAD_ROSTER = "Upload your airline's roster to RosterBuster.",
  UPLOAD_ROSTER_PORTAL = "Upload your roster through your airline's Crew Portal.",
  UPLOAD_ROSTER_FILE = "Upload your roster from a file.",
  UPLOAD_ROSTER_SRC = "Upload your roster from a text source.",
  DOWNLOAD_APP = "Download the RosterBuster mobile app.",
  CHANGE_PASSWORD = "Change your RosterBuster password.",
  SETTINGS_PROFILE = "Edit your profile.",
  SETTINGS_DISPLAY = "Manage your display settings.",
  SETTINGS_ROSTER = "Manage your roster settings.",
  SETTINGS_EVENT = "Manage your event settings.",
  SETTINGS_CALENDAR = "Manage your calendar settings.",
  SETTINGS_CALENDAR_FILTER = "Manage your calendar filters.",
  SETTINGS_RB_LOGBOOK = "Manage your RB Logbook settings.",
  SETTINGS_SAFELOG = "Manage your Safelog settings.",
  SETTINGS_PRIVACY = "Manage your privacy settings.",
  SETTINGS_RESET_PASSWORD = "Reset your password.",
  THIRD_PARTY_AUTHORIZE = "Authorize the third party.",
  SETTINGS_THIRD_PARTY = "Manage your third party settings",

  LOGBOOK_SIGN_IN = "Sign In to your RB Logbook account."
}
