import React from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { IToggleButtonOption } from "../../interfaces";

interface IProps {
  defaultValue: any;
  options: Array<IToggleButtonOption>;
  onChange: (value: any) => void;
}

const CustomButtonGroup = (props: IProps) => {
  const { defaultValue, options, onChange } = props;

  const getToggleButton = (option: IToggleButtonOption): JSX.Element => {
    const { label, value } = option;

    return (
      <ToggleButton
        key={label}
        className="primary-toggle-btn"
        variant="outline-primary"
        value={value}
        style={{ flex: 1 }}>
        {label}
      </ToggleButton>
    );
  };

  return (
    <ToggleButtonGroup
      className="primary-toggle-btn"
      type="radio"
      name="toggle-button-group"
      defaultValue={defaultValue}
      onChange={onChange}
      style={{ width: "100%" }}>
      {options.map((option: IToggleButtonOption) => getToggleButton(option))}
    </ToggleButtonGroup>
  );
};

export default CustomButtonGroup;
