import { AxiosInstance, AxiosResponse } from "axios";
import {
  IChangePassword,
  IData,
  ISignInCredentials,
  ISignUpUser,
  IToken,
  IAuthorizeThirdparty
} from "../../interfaces";
import { logbookHttpService } from "../../logbook/services/LogbookHttpService";
import { httpService } from "./HttpService";

enum AuthApi {
  SIGN_UP = "/v1/register",
  SIGN_IN = "/v1/auth",
  REFRESH_TOKEN = "/v1/auth/refresh",
  FORCE_LOGOUT = "/v1/auth/forcelogout",
  CHANGE_PASSWORD = "/v1/password/reset",
  VERIFY_USER = "v1/email/verification/verify",
  THIRD_PARTY_ALL_SCOPES = "/v1/thirdparty/scopes",
  THIRD_PARTY_USER_SCOPES = "/v1/thirdparty/user/scopes",
  THIRD_PARTY_AUTHORIZE = "/v1/thirdparty/login",
  THIRD_PARTY_REMOVE_SCOPES = "/v1/thirdparty/user"
}

const _consumerKey: string = process.env.REACT_APP_CONSUMER_KEY!;
const _consumerKeyLB: string = process.env.REACT_APP_CONSUMER_KEY_LB!;
const _channel: string = process.env.REACT_APP_CHANNEL!;

const _authClient = async (): Promise<AxiosInstance> => await httpService().authClient();

const _guestAuthClient = (): AxiosInstance => httpService().guestAuthClient();

const _baseGuestAuthClient = (): AxiosInstance => httpService().baseGuestAuthClient();

const _logbookAuthClient = async (): Promise<AxiosInstance> => await logbookHttpService().authClient();

const _logbookGuestAuthClient = (): AxiosInstance => logbookHttpService().guestAuthClient();

/**
 * Creates a new user account
 *
 * @param {ISignUpUser} user The user's info
 */
export const signUp = async (user: ISignUpUser): Promise<AxiosResponse> => {
  user.channel = _channel;
  user.consumer_key = _consumerKey;

  return await _guestAuthClient().post(AuthApi.SIGN_UP, user);
};

/**
 * Signs a user in
 * @param {ISignInCredentials} credentials The user's credentials (email and password)
 */
export const signIn = async (credentials: ISignInCredentials): Promise<AxiosResponse<IData<IToken>>> => {
  return await _guestAuthClient().post(AuthApi.SIGN_IN, {
    email: credentials.email,
    password: credentials.signInPassword,
    consumer_key: _consumerKey
  });
};

/**
 * Signs a user in
 * @param {ISignInCredentials} credentials The user's credentials (email and password)
 */
export const logbookSignIn = async (credentials: ISignInCredentials): Promise<AxiosResponse<IData<IToken>>> => {
  return await _logbookGuestAuthClient().post(AuthApi.SIGN_IN, {
    email: credentials.email,
    password: credentials.signInPassword,
    consumer_key: _consumerKeyLB
  });
};

/**
 * Verify the user's account
 */
export const verifyUser = async (email: string, token: string): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = _baseGuestAuthClient();
  return await client.get(AuthApi.VERIFY_USER, { params: { email, token } });
};

/**
 * Refreshes the auth token for RosterBuster.
 * @param {IToken} token Expired token to refresh
 */
export const refreshRBToken = async (token: IToken): Promise<AxiosResponse<IData<IToken>>> => {
  return await _guestAuthClient().post(AuthApi.REFRESH_TOKEN, { refresh_token: token.refresh_token });
};

/**
 * Refreshes the auth token for RB Logbook.
 * @param {IToken} token Expired token to refresh
 */
export const refreshLBToken = async (token: IToken): Promise<AxiosResponse<IData<IToken>>> => {
  return await _logbookGuestAuthClient().post(AuthApi.REFRESH_TOKEN, { refresh_token: token.refresh_token });
};

/**
 * Change the user's password.
 *
 * @param newPassword The new password and confirmation.
 */
export const changePassword = async (newPassword: IChangePassword): Promise<AxiosResponse<IData<any>>> => {
  const client: AxiosInstance = await _authClient();
  return await client.post(AuthApi.CHANGE_PASSWORD, newPassword);
};

/**
 * Change the user's password.
 *
 * @param newPassword The new password and confirmation.
 */
export const changeLBPassword = async (newPassword: IChangePassword): Promise<AxiosResponse<IData<any>>> => {
  const client: AxiosInstance = await _logbookAuthClient();
  return await client.post(AuthApi.CHANGE_PASSWORD, newPassword);
};

/**
 * Sign the user out on all devices
 */
export const forceSignOut = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _authClient();
  return await client.post(AuthApi.FORCE_LOGOUT);
};

/**
 * Returns all required data of a third party for authorization.
 */
export const allThirdpartyData = async (consumer_key: string): Promise<AxiosResponse> => {
  return await _guestAuthClient().get(AuthApi.THIRD_PARTY_ALL_SCOPES, {
    params: {
      consumer_key: consumer_key
    }
  });
};

/**
 * Returns all required data of third parties that where allowed by the user.
 */
export const allUserAllowedThirdpartyData = async (user_id?: number): Promise<AxiosResponse> => {
  const client: AxiosInstance = await _authClient();
  return await client.get(AuthApi.THIRD_PARTY_USER_SCOPES, {
    params: {
      user_id: user_id
    }
  });
};

/**
 * Authorize the requests of a third party that requests rosterbuster data for a specific user.
 * @param {IAuthorizeThirdparty} input The user's credentials (email and password)
 */
export const authorizeThirdparty = async (input: IAuthorizeThirdparty): Promise<AxiosResponse<any>> => {
  return await _guestAuthClient().post(AuthApi.THIRD_PARTY_AUTHORIZE, {
    email: input.email,
    password: input.signInPassword,
    consumer_key: input.consumer_key,
    redirect_uri: input.redirect_uri,
    code_challenge: input.code_challenge,
    state: input.state
  });
};

/**
 * Authorize the requests of a third party that requests logbook data for a specific user.
 * @param {IAuthorizeThirdparty} input The user's credentials (email and password)
 */
export const authorizeThirdpartyLogbook = async (input: IAuthorizeThirdparty): Promise<AxiosResponse<any>> => {
  return await _logbookGuestAuthClient().post(AuthApi.THIRD_PARTY_AUTHORIZE, {
    email: input.email,
    password: input.signInPassword,
    consumer_key: input.consumer_key,
    redirect_uri: input.redirect_uri,
    code_challenge: input.code_challenge,
    state: input.state
  });
};

/**
 * Returns all required data of third parties that where allowed by the user.
 */
export const removeUserAllowedThirdpartyData = async (
  consumer_id: number,
  user_id?: number
): Promise<AxiosResponse> => {
  const client: AxiosInstance = await _authClient();
  return await client.delete(AuthApi.THIRD_PARTY_REMOVE_SCOPES, {
    params: {
      consumer_id: consumer_id,
      user_id: user_id
    }
  });
};
