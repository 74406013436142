import React from "react";
import { Route } from "react-router-dom";
import MainContainer from "../../components/containers/MainContainer";
import { ThemeProvider, themes } from "../../utils/Theming";

const PublicRouteRB: ({ component: Component, render, ...rest }: any) => JSX.Element = ({
  component: Component,
  render,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <ThemeProvider theme={themes.rb}>
            <MainContainer>{Component ? <Component {...props} /> : render(props)}</MainContainer>
          </ThemeProvider>
        );
      }}
    />
  );
};

export default PublicRouteRB;
