import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { IPreferences, IUserData } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import ExpiredRosters from "./ExpiredRosters";

const RosterSettings: () => JSX.Element = observer(() => {
  const userData: IUserData = createUserStore().userData!;
  const preference: IPreferences = userData.preferences;

  const handleExpiredRoster = async (value: string): Promise<void> => {
    try {
      preference.auto_delete_roster = value;
      await settingsService().updatePreference(Preference.DELETE_ROSTER, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <ComponentBoundary componentName="Roster settings">
      <PageHead title={PageTitle.SETTINGS_ROSTER} description={PageDescription.SETTINGS_ROSTER} />

      <ExpiredRosters defaultValue={Number(preference.auto_delete_roster)} onChange={handleExpiredRoster} />
    </ComponentBoundary>
  );
});

export default RosterSettings;
