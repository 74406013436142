import React from "react";
import Button from "react-bootstrap/Button";

interface IProps {
  label: string;
  icon: string;
  onClick: any;
}

const SecondaryButton = (props: IProps): JSX.Element => {
  const { label, icon, onClick } = props;

  return (
    <Button className="btn-sm secondary-btn d-block d-lg-inline-block mb-1" onClick={onClick}>
      <i className={"fa fw fa-" + icon + " mr-2"} />
      {label}
    </Button>
  );
};

export default SecondaryButton;
