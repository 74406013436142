//tslint:disable:no-magic-numbers
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import rbLogoBlue from "../../../assets/images//logo/rb-logo-blue.png";
import backgroundImage from "../../../assets/images/background/rb-payment-bg.jpg";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PageLoader from "../../../components/loaders/PageLoader";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { signOut } from "../../../services/auth/SignOutService";
import { userService } from "../../../services/user/UserService";
import ErrorPage from "../../error/ErrorPage";
import PageHead from "../../PageHead";
import ChangePasswordForm from "./ChangePasswordForm";

export const ChangePasswordPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async (): Promise<void> => {
    try {
      await userService().getUser();
      setSuccessful(true);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = (): void => setSuccess(true);

  if (loading) {
    return <PageLoader />;
  }

  return successful ? (
    <>
      <PageHead title={PageTitle.CHANGE_PASSWORD} description={PageDescription.CHANGE_PASSWORD} />

      <div className="bg-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row className="no-gutters hero-static justify-content-center">
          <Col sm={8} md={6} xl={4} className="d-flex align-items-center p-2 px-sm-0">
            <Card className="block-content block-content-full p-4" style={{ maxWidth: "500px" }}>
              <div className="mb-3 text-center">
                <Image src={rbLogoBlue} style={{ height: "100px", width: "auto" }} />
                <p className="text-uppercase font-w700 font-size-sm text-muted">{t("change_password.title")}</p>
              </div>

              {success ? (
                <div style={{ textAlign: "center" }}>
                  <h2 className="text-muted">{t("change_password.success_title")}</h2>
                  <p className="text-muted">{t("change_password.success_message")}</p>
                  <PrimaryButton label={t("sign_in")} onClick={signOut} />
                </div>
              ) : (
                <ChangePasswordForm onSuccess={handleSuccess} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <ErrorPage {...props} />
  );
};

export default ChangePasswordPage;
