import { ErrorMessage } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";

interface IProps {
  id: string;
  name: string;
  title: string;
  accept: string;
  onChange: Function;
}

const FileSelector = (props: IProps): JSX.Element => {
  const { id, name, title, accept, onChange } = props;

  return (
    <>
      <label className="btn btn-primary" htmlFor={id} style={{ color: "#FFFFFF", borderRadius: "50px" }}>
        {title}
      </label>
      <Form.Control
        id={id}
        type="file"
        hidden
        accept={accept}
        onChange={(event: any) => onChange(event.currentTarget.files[0])}
      />
      <ErrorMessage className="input-feedback" name={name} component="div" />
    </>
  );
};

export default FileSelector;
