//tslint:disable:no-magic-numbers
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

interface IProps {
  label: string;
  description?: string;
  children?: React.ReactNode;
}

const SettingsRow = (props: IProps): JSX.Element => {
  const { label, description, children } = props;

  return (
    <Form.Group>
      <Row>
        <Col md={3}>
          <div className="settings-row-label">{label}</div>
        </Col>
        <Col md={9}>
          {children}
          <div style={{ fontSize: "0.875rem" }}>{description}</div>
        </Col>
      </Row>
      <hr style={{ width: "100%", borderWidth: "1px" }} />
    </Form.Group>
  );
};

export default SettingsRow;
