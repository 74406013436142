import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import backgroundImage from "../../../assets/images/background/lb-payment-bg.jpg";
import PageLoader from "../../../components/loaders/PageLoader";
import UserAvatar, { AvatarSize } from "../../../components/user/UserAvatar";
import { Url } from "../../../enums/Url";
import ErrorPage from "../../../pages/error/ErrorPage";
import { createUserStore, UserStore } from "../../../stores/user/UserStore";
import { Theme, useTheme } from "../../../utils/Theming";
import { logbookUserService } from "../../services/LogbookUserService";

const LogbookEmailVerify = (props: RouteComponentProps): JSX.Element => {
  const [isUserVerified, setUserVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const userStore: UserStore = createUserStore();
  const theme: Theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    fetchUser().catch();
  }, []);

  const fetchUser = async (): Promise<void> => {
    try {
      await logbookUserService().fetchUser();
      await logbookUserService().updateLogbookUser({ email_me: 1 });

      setUserVerified(true);
    } catch (error) {
      setUserVerified(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return isUserVerified ? (
    <div className="bg-image w-100" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className="no-gutters hero-static justify-content-center">
        <Col sm={8} md={6} xl={4} className="d-flex align-items-center p-2 px-sm-0 text-center">
          <Card className="block-content block-content-full p-4">
            <UserAvatar
              className="mb-2"
              url={userStore.logbookUser?.avatar_url ?? Url.AVATAR_PLACEHOLDER}
              size={AvatarSize.L}
            />
            <span style={{ fontWeight: "bold" }}>
              {userStore.logbookUser?.firstname + " " + userStore.logbookUser?.lastname}
            </span>
            <span style={{ fontWeight: "bold" }}>{userStore.logbookUser?.email}</span>
            <div className="mt-4">
              <h1 style={{ color: theme.primaryColor, fontWeight: "bold" }}>{t("logbook_email_verified")}</h1>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <ErrorPage {...props} />
  );
};

export default LogbookEmailVerify;
