import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import toString from "lodash/toString";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../../components/buttons/SubmitButton";
import FileSelector from "../../../../components/inputs/FileSelector";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { uploadRosterService } from "../../../../services/roster/UploadRosterService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { ROSTER_FORMATS, uploadRosterFileSchema } from "../../../../utils/Validation";
import PageHead from "../../../PageHead";
import UploadHeader from "../UploadHeader";
import UploadModal from "../UploadModal";

const UploadFile = (): JSX.Element => {
  const [fileName, setFileName] = useState<string>();

  const { t } = useTranslation();

  const handleSubmit = async (selectedFile: File): Promise<void> => {
    try {
      if (selectedFile != null) {
        await uploadRosterService().uploadRosterFile(selectedFile);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <PageHead title={PageTitle.UPLOAD_ROSTER_FILE} description={PageDescription.UPLOAD_ROSTER_FILE} />

      <UploadModal showBackBtn>
        <div style={{ textAlign: "center" }}>
          <UploadHeader title={t("upload_file_title")} />

          <i className="far fa-fw fa-file-alt text-muted mb-2" style={{ fontSize: "60px" }} />
          <p className="text-muted mb-0">{fileName}</p>

          <Formik
            initialValues={{
              file: ""
            }}
            validationSchema={uploadRosterFileSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values: any, actions: FormikHelpers<any>) => {
              try {
                actions.setSubmitting(true);

                await handleSubmit(values.file);
              } catch (error) {
                showErrorMessage(error);
              } finally {
                actions.setSubmitting(false);
              }
            }}>
            {(props: FormikProps<any>) => {
              const { setFieldValue, isSubmitting } = props;

              return (
                <FormikForm>
                  <div className="py-3">
                    <Form.Group style={{ marginBottom: "3rem" }}>
                      <FileSelector
                        id="upload-roster-file"
                        name="file"
                        title={t("select_file")}
                        accept={toString(ROSTER_FORMATS)}
                        onChange={(file: File) => {
                          setFileName(file.name);
                          setFieldValue("file", file);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <Form.Group>
                    <SubmitButton
                      icon="file-upload"
                      label={t("upload_roster")}
                      loadingLabel={t("uploading")}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      </UploadModal>
    </>
  );
};

export default UploadFile;
