import { AxiosResponse } from "axios";
import { action } from "mobx";
import moment from "moment-timezone";
import { IEvent, IRoute, IStatisticsRequest, IStatisticsRoute } from "../../interfaces";
import { createTimelineStore } from "../../stores/timeline/TimelineStore";
import { getUserTimezone } from "../../utils/DateTimeUtils";
import { fetchStatistics } from "../api/ApiService";
import { eventService } from "../event/EventsService";

export class TimelineService {
  private readonly millis: number = 1000;

  /**
   * Saves the events in the store and applies filter to calculate statistics
   */
  public async initDashboard(): Promise<void> {
    try {
      const events: Array<IEvent> = createTimelineStore().events;

      if (!events || events.length === 0) {
        return;
      }

      const firstEvent: IEvent = events[0];
      const lastEvent: IEvent = events[events.length - 1];

      const startDate: moment.Moment = moment(firstEvent.dutyStartTimestamp * this.millis)
        .tz(getUserTimezone())
        .startOf("day");
      const endDate: moment.Moment = moment(lastEvent.dutyStartTimestamp * this.millis)
        .tz(getUserTimezone())
        .endOf("day");

      createTimelineStore().initialStartDate = startDate;
      createTimelineStore().initialEndDate = endDate;

      await this.getStatistics(startDate, endDate);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Filters events based on start & end date and calculates statistics
   *
   * @param {moment.Moment} Start Date to filter from
   * @param {moment.Moment} End Date to filter to
   */
  @action.bound
  public async fetchEvents(startDate: moment.Moment, endDate: moment.Moment): Promise<void> {
    try {
      createTimelineStore().events = await eventService().fetchEvents(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );

      createTimelineStore().initialStartDate = startDate;
      createTimelineStore().initialEndDate = endDate;

      await this.getStatistics(startDate, endDate);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Converts IStatisticRoutes into IRoutes
   *
   * @param {Array<IStatisticsRoute>} statsRoutes list of IStaticRoute
   * @returns {Array<IRoute>} list of IRoute
   */
  public getFlightRoutes(statsRoutes: Array<IStatisticsRoute>): Array<IRoute> {
    const routes: Array<IRoute> = new Array<IRoute>();

    statsRoutes.forEach((statsRoute: IStatisticsRoute) => {
      const route: IRoute = {
        start: {
          lat: statsRoute.start.latitude,
          lng: statsRoute.start.longitude
        },
        end: {
          lat: statsRoute.end.latitude,
          lng: statsRoute.end.longitude
        }
      };

      routes.push(route);
    });

    return routes;
  }

  /**
   * Fetches statistics and saves in TimelineStore
   *
   * @param {moment.Moment} start Start date
   * @param {moment.Moment} end End date
   */
  public async getStatistics(start: moment.Moment, end: moment.Moment): Promise<void> {
    const statsRequest: IStatisticsRequest = {
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD")
    };

    try {
      const response: AxiosResponse = await fetchStatistics(statsRequest);

      createTimelineStore().statistics = response.data.CUSTOM;
    } catch (error) {
      throw error;
    }
  }
}

let service: TimelineService | null = null;

export const timelineService = (): TimelineService => service ?? (service = new TimelineService());
