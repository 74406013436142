import React from "react";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { IUploadType } from "../../../../interfaces";
import { getAirlineLogo } from "../../../../utils/Utils";
import PageHead from "../../../PageHead";
import UploadModal from "../UploadModal";
import PortalForm from "./PortalForm";

interface IProps {
  airline: string;
  picture: string;
  uploadType: IUploadType;
}

const UploadPortal = (props: IProps): JSX.Element => {
  const { airline, picture, uploadType } = props;

  const { t } = useTranslation();

  return (
    <>
      <PageHead title={PageTitle.UPLOAD_ROSTER_PORTAL} description={PageDescription.UPLOAD_ROSTER_PORTAL} />

      <UploadModal showBackBtn>
        <div style={{ marginBottom: "2rem", textAlign: "center" }}>
          <div style={{ marginBottom: "1rem" }}>
            {picture && <Image src={getAirlineLogo(picture)} style={{ width: "auto", height: "30px" }} />}
            <p className="text-muted" style={{ fontWeight: "bold" }}>
              {airline}
            </p>
          </div>
          <p className="text-uppercase font-w700 font-size-sm text-muted">
            {uploadType.wiz_intro_text ?? t("portal_subtitle")}
          </p>
        </div>
        <PortalForm />
      </UploadModal>
    </>
  );
};

export default UploadPortal;
