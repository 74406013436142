import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import backGroundImage from "../../assets/images/background/error-bg.jpg";
import { RBApp } from "../../enums/RBApp";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { redirectToLogbookSignIn, redirectToSignIn } from "../../utils/RedirectToSignIn";

interface IProps extends RouteComponentProps {
  app?: RBApp;
}

const ErrorPage = (props: IProps): JSX.Element => {
  const { app = createAuthStore().app, location } = props;

  const { t } = useTranslation();

  const appName: string = app === RBApp.LOGBOOK ? t("logbook.app_name") : t("app_name");

  const handleOnClick = (): void => {
    const { from } = (location?.state as any) || { from: { pathname: location?.pathname, search: location?.search } };

    if (app === RBApp.LOGBOOK) {
      redirectToLogbookSignIn(from);
    } else {
      redirectToSignIn(from);
    }
  };

  return (
    <div className="bg-image" style={{ backgroundImage: `url(${backGroundImage})` }}>
      <div className="hero" style={{ backgroundColor: "rgba(255, 255, 255, 0.80)" }}>
        <div className="hero-inner">
          <div className="content content-full">
            <div className="px-3 py-5 text-center">
              <div
                id="error-page-title"
                className="display-1 font-w700 js-appear-enabled animated fadeInDown"
                data-toggle="appear"
                data-class="animated fadeInDown"
                style={{ color: app === RBApp.LOGBOOK ? "#FF7300" : "#049fd9" }}>
                {t("error.unauthorized.title", { appName })}
              </div>
              <h1
                className="text-muted mt-5 mb-3 js-appear-enabled animated fadeInUp"
                data-toggle="appear"
                data-class="animated fadeInUp">
                {t("error.unauthorized.message")}
              </h1>
              <div className="js-appear-enabled animated zoomIn" data-toggle="appear" data-class="animated zoomIn">
                <Button size="lg" variant="success" onClick={handleOnClick}>
                  <i className={"fa fa-fw fa-arrow-left mr-2"} />
                  {t("sign_in")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
