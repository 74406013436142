import React, { useEffect, useState } from "react";
import { AllTypeaheadOwnAndInjectedProps, Typeahead } from "react-bootstrap-typeahead";
import { IAirline } from "../../interfaces";
import { airlineService } from "../../services/airline/AirlineService";
import { showErrorMessage } from "../../stores/error/SnackbarStore";

interface IProps {
  name: string;
  placeholder: string;
  defaultAirline?: IAirline;
  onChange: Function;
  fieldTouched: Function;
  errors: string | undefined;
  touched: boolean | undefined;
}

const maxResults: number = 10;

const getDefault = (defaultValue: IAirline): Array<IAirline> => {
  return new Array<IAirline>(defaultValue);
};

const filter = (airline: IAirline, props: AllTypeaheadOwnAndInjectedProps<IAirline>): boolean => {
  const query: string = props.text.toLowerCase();
  if (
    airline.name?.toLowerCase()?.includes(query) ||
    airline.cs_iata?.toLowerCase()?.includes(query) ||
    airline.cs_icao?.toLowerCase()?.includes(query)
  ) {
    return true;
  }
  return false;
};

const AirlineSelector = (props: IProps): JSX.Element => {
  const { name, placeholder, defaultAirline, onChange, fieldTouched, errors, touched } = props;

  const [airlines, setAirlines] = useState<Array<IAirline>>([]);

  useEffect(() => {
    fetchAirlines();
  }, []);

  const fetchAirlines = async (): Promise<void> => {
    try {
      const data: Array<IAirline> = await airlineService().fetchAirlines();

      setAirlines(data);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleOnBlur = (): void => fieldTouched(name, true);

  const handleOnChange = (airline: IAirline): void => (airline ? onChange(name, airline.pk) : onChange(name, ""));

  return (
    <>
      <Typeahead
        id="airline-selection"
        defaultSelected={defaultAirline && defaultAirline.pk ? getDefault(defaultAirline) : undefined}
        maxResults={maxResults}
        paginate={true}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        options={airlines}
        filterBy={filter}
        labelKey={(airline: IAirline) => `${airline.name} - (${airline.cs_icao})`}
        onChange={(selected: Array<IAirline>) => handleOnChange(selected[0])}
        inputProps={{ style: { border: "none", backgroundColor: "#f4f6fa" } }}
      />
      {errors && touched && <p className="input-feedback">{errors}</p>}
    </>
  );
};

export default AirlineSelector;
