import { AxiosResponse } from "axios";
import { createUserStore, UserStore } from "../../stores/user/UserStore";
import { fetchUser, unsubscribeFromEmail, updateUser } from "./LogbookApiService";

export class LogbookUserService {
  /**
   * Fetches the Logbook user and saves it in the UserStore
   */
  public async fetchUser(force: boolean = false): Promise<void> {
    const userStore: UserStore = createUserStore();

    if ((userStore.userFetched && !force) || userStore.isLoading) {
      return;
    }

    userStore.isLoading = true;

    try {
      const response: AxiosResponse = await fetchUser();

      userStore.logbookUser = response.data.data;

      userStore.isLoading = false;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Updates the Logbook user
   */
  public async updateLogbookUser(userData: {}): Promise<void> {
    const userStore: UserStore = createUserStore();

    if (!userStore.userFetched || userStore.isLoading) {
      return;
    }

    userStore.isLoading = true;

    try {
      const response: AxiosResponse = await updateUser(userData);
      userStore.logbookUser = response.data.data;

      userStore.isLoading = false;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Unsubscribe the user from Logbook emails.
   */
  public async unsubscribeUserFromEmails(): Promise<void> {
    try {
      await unsubscribeFromEmail();
    } catch (error) {
      throw error;
    }
  }
}

let service: LogbookUserService | null = null;

export const logbookUserService = (): LogbookUserService => service ?? (service = new LogbookUserService());
