import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageLoader from "../../components/loaders/PageLoader";
import { scopedRequestServiceRB } from "../../services/campaign/scoped-request/ScopedRequestServiceRB";
import ErrorPage from "../error/ErrorPage";

interface IProps extends RouteComponentProps {
  component: any;
}

const CampaignContainer = (props: IProps): JSX.Element => {
  const { component: Component, location } = props;

  const [successful, setSuccessful] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleScopedRequestToken: () => Promise<void> = useCallback(async (): Promise<void> => {
    try {
      await scopedRequestServiceRB().authenticateUser(location?.search);

      setSuccessful(true);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    handleScopedRequestToken().catch();
  }, [handleScopedRequestToken]);

  if (loading) {
    return <PageLoader />;
  }

  return successful ? <Component {...props} /> : <ErrorPage {...props} />;
};

export default CampaignContainer;
