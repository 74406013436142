import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import SettingsRow from "../../../components/settings/SettingsRow";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";

const RequestData = (): JSX.Element => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  const toggleModal = (): void => setShow(!show);

  const handleDataRequest = async (): Promise<void> => {
    try {
      await settingsService().requestData();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <>
      <SettingsRow
        label={t("settings_privacy_request_data_label")}
        description={t("settings_privacy_request_data_desc")}>
        <PrimaryButton label={t("settings_privacy_request_data_label")} size="sm" onClick={toggleModal} />
      </SettingsRow>
      {show && (
        <ConfirmationModal
          title={t("settings_privacy_request_data_label")}
          description={t("settings_privacy_request_data_dialog_desc")}
          onHide={toggleModal}
          onConfirm={handleDataRequest}
        />
      )}
    </>
  );
};

export default RequestData;
