import queryString from "query-string";
import { AuthStore, createAuthStore } from "../../../stores/auth/AuthStore";

export abstract class AbstractScopedRequestService {
  protected AUTH_ERROR: string = "Unable to authenticate.";

  protected authStore: AuthStore = createAuthStore();

  /**
   * Handles the URL params
   *
   * @param params The URL params
   */
  public async authenticateUser(params: string): Promise<void> {
    try {
      const queryParams: any = queryString.parse(params);

      const tempToken: string = queryParams.tempToken;

      await this.authenticate(tempToken);
    } catch (error) {
      throw new Error(this.AUTH_ERROR);
    }
  }

  protected abstract async authenticate(tempToken: string): Promise<void>;
}
