import React from "react";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/images/background/app-download-bg.jpg";
import Brand from "../framework/header/Brand";

interface IProps {
  appName: string;
  messagePath: string;
}

const textStyle: React.CSSProperties = { textAlign: "center", color: "#FFFFFF" };

const GenericUnsubscribePage = (props: IProps): JSX.Element => {
  const { appName, messagePath } = props;

  const { t } = useTranslation();

  return (
    <div className="bg-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className="hero-static no-gutters" style={{ justifyContent: "center", alignItems: "center" }}>
        <div className="p-4" style={{ textAlign: "center" }}>
          <div style={{ marginBottom: 120, textAlign: "center", color: "#FFFFFF" }}>
            <h1 style={textStyle}>{t(`${messagePath}.title`)}</h1>
            <h3 style={textStyle}>{t(`${messagePath}.subtitle`, { appName })}</h3>
          </div>
        </div>
        <div className="p-4" style={{ position: "absolute", bottom: 20 }}>
          <Brand />
        </div>
      </Row>
    </div>
  );
};

export default GenericUnsubscribePage;
