import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import logbookLogo from "../../assets/images//logo/rb-logbook-logo.png";
import backGroundImage from "../../assets/images/background/logbook-bg.jpg";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import GenericSignInForm from "../../components/generics/sign-in/GenericSignInForm";
import GenericSignInPage from "../../components/generics/sign-in/GenericSignInPage";
import { blockedEmailDomains } from "../../constants/BlockedEmailDomains";
import { PageDescription, PageTitle } from "../../enums/Page";
import { RouteURL } from "../../enums/routing/Route";
import History from "../../History";
import { ISignInCredentials } from "../../interfaces";
import PageHead from "../../pages/PageHead";
import { logbookSignInService } from "../services/LogbookSignInService";

export const LogbookSignInPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const handleSignIn = async (credentials: ISignInCredentials): Promise<void> => {
    try {
      const domain: string | undefined = credentials.email.split("@").pop();
      if (domain && blockedEmailDomains.includes(domain)) {
        throw `It is not possible to login with @${domain}, since we have an enterprise agreement with your airline. Use our RosterBuster mobile application to login with ENTERPRISE option.`;
      }
      await logbookSignInService().signIn(credentials);

      const { from } = (props.location.state as any) || { from: { pathname: RouteURL.LOGBOOK_SIGN_IN } };
      History.replace(from);
    } catch (error) {
      throw error;
    }
  };

  const handleForgotPassword = (): void => History.push(RouteURL.LOGBOOK_FORGOT_PASSWORD);

  return (
    <>
      <PageHead title={PageTitle.LOGBOOK_SIGN_IN} description={PageDescription.LOGBOOK_SIGN_IN} />

      <GenericSignInPage
        appName={t("logbook.app_name")}
        appSubtitle={t("logbook.app_subtitle")}
        logo={logbookLogo}
        backgroundImage={backGroundImage}>
        <GenericSignInForm onSubmit={handleSignIn} />
        <SecondaryButton label={t("forgot_password")} icon="lock" onClick={handleForgotPassword}></SecondaryButton>
      </GenericSignInPage>
    </>
  );
};

export default LogbookSignInPage;
