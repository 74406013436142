import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../../components/buttons/CancelButton";

interface IProps {
  instructions: string | undefined;
  onHide: () => void;
}

const PortalInstructions = (props: IProps): JSX.Element => {
  const { instructions, onHide } = props;

  const { t } = useTranslation();

  return (
    <Modal show onHide={onHide} size="lg" centered style={{ padding: 0 }}>
      <Modal.Header>
        <Modal.Title className="rb-color-primary">{t("portal_instructions_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: instructions ?? "" }} />
      <Modal.Footer>
        <CancelButton label={t("close_btn")} onClick={onHide} />
      </Modal.Footer>
    </Modal>
  );
};

export default PortalInstructions;
