import { IJobType } from "../interfaces";

export const JobTypes: Array<IJobType> = [
  { value: "PIC", name: "Captain", ispilot: 1 },
  { value: "RCA", name: "Relief Captain", ispilot: 1 },
  { value: "SENFO", name: "Senior First Officer", ispilot: 1 },
  { value: "FO", name: "First Officer", ispilot: 1 },
  { value: "SFO", name: "Second Officer", ispilot: 1 },
  { value: "CMANAGER", name: "Cabin Manager", ispilot: 0 },
  { value: "SCREW", name: "Senior Cabin Crew", ispilot: 0 },
  { value: "CREW", name: "Cabin Crew", ispilot: 0 },
  { value: "ATTENDANT", name: "Flight Attendant", ispilot: 0 },
  { value: "FE", name: "Flight Engineer", ispilot: 1 },
  { value: "PURSER", name: "Purser", ispilot: 0 },
  { value: "CHEF", name: "Flying Chef", ispilot: 0 },
  { value: "TRN", name: "Crew Trainer", ispilot: 0 },
  { value: "LM", name: "Loadmaster", ispilot: 0 },
  { value: "SIC", name: "Second-in-Command", ispilot: 1 }
];
