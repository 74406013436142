import React from "react";
import { Button } from "react-bootstrap";
import { RouteIcon, RouteLabel } from "../../../enums/routing/Route";
import { Url } from "../../../enums/Url";
import { IUser } from "../../../interfaces";
import { createUserStore } from "../../../stores/user/UserStore";
import { SubscriptionStatus } from "../../../enums/upgrade/Subscription";

const UpgradeButton = (): JSX.Element => {
  const user: IUser | undefined = createUserStore().userData?.user;

  const handleOnClick = (): void => {
    const url: Url =
      user?.status_expiry === SubscriptionStatus.PREMIUM || user?.status_expiry === SubscriptionStatus.LIFETIME
        ? Url.MANAGE_SUBSCRIPTION
        : Url.SUBSCRIPTIONS_FAQ;
    window.open(url, "_blank");
  };

  const getButtonDetails = () => {
    // Default Icon and Label for Upgrade Button
    var icon = RouteIcon.UPGRADE;
    var label = RouteLabel.UPGRADE;

    if (user?.status_expiry === SubscriptionStatus.LIFETIME) {
      icon = RouteIcon.LIFETIME;
      label = RouteLabel.LIFETIME;
    } else if (user?.status_expiry === SubscriptionStatus.PREMIUM) {
      icon = RouteIcon.PREMIUM;
      label = RouteLabel.PREMIUM;
    }
    return { buttonIcon: icon, buttonLabel: label };
  };

  const { buttonIcon, buttonLabel } = getButtonDetails();

  return (
    <Button id="upgrade-btn" onClick={handleOnClick}>
      <i className={`fa fa-${buttonIcon}`} />
      <span className="d-none d-md-inline ml-2">{buttonLabel}</span>
    </Button>
  );
};

export default UpgradeButton;
