import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../components/buttons/SubmitButton";
import AirlineSelector from "../../../components/inputs/AirlineSelector";
import AirportSelector from "../../../components/inputs/AirportSelector";
import AvatarSelector from "../../../components/inputs/AvatarSelector";
import JobSelector from "../../../components/inputs/JobSelector";
import TextField from "../../../components/inputs/TextField";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { IUser, IUserData } from "../../../interfaces";
import { IProfile } from "../../../interfaces";
import { userService } from "../../../services/user/UserService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import { settingsProfileSchema } from "../../../utils/Validation";
import PageHead from "../../PageHead";

type IFormValues = {
  avatar: File | undefined;
  profile: IProfile;
};

const handleSubmit = async (values: IFormValues): Promise<void> => {
  try {
    if (values.avatar) {
      await userService().uploadAvatar(values.avatar);
    }

    await userService().updateUser(values.profile);
  } catch (error) {
    throw error;
  }
};

const initialValues = (userData: IUserData | null): IFormValues => {
  const user: IUser | null = userData ? userData.user : null;

  return {
    avatar: undefined,
    profile: {
      firstname: user && user.firstname ? user.firstname : "",
      lastname: user && user.lastname ? user.lastname : "",
      email: user && user.email ? user.email : "",
      phonenumber_prefix: user && user.phonenumber_prefix ? user.phonenumber_prefix : "",
      phonenumber: user && user.phonenumber ? user.phonenumber : "",
      homeairline: userData && userData.homeairline ? userData.homeairline.pk : "",
      base: userData && userData.base ? userData.base.IATA : "",
      jobtype: userData && userData.jobtype ? userData.jobtype.value : ""
    }
  };
};

const ProfileSettings = (): JSX.Element => {
  const { t } = useTranslation();

  const userData: IUserData | null = createUserStore().userData;

  return (
    <>
      <PageHead title={PageTitle.SETTINGS_PROFILE} description={PageDescription.SETTINGS_PROFILE} />

      <Formik
        initialValues={initialValues(userData)}
        validationSchema={settingsProfileSchema}
        onSubmit={async (values: IFormValues, actions: FormikHelpers<any>) => {
          try {
            actions.setSubmitting(true);

            await handleSubmit(values);
          } catch (error) {
            showErrorMessage(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}>
        {(props: FormikProps<IFormValues>) => {
          const { errors, touched, setFieldValue, setFieldTouched, isSubmitting } = props;

          return (
            <FormikForm>
              <Form.Group>
                <AvatarSelector
                  label={t("settings_profile_avatar")}
                  name="avatar"
                  onChange={(file: File) => setFieldValue("avatar", file)}
                />
              </Form.Group>

              <div className="py-3">
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <TextField placeholder={t("first_name")} type="text" name="profile.firstname" />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <TextField placeholder={t("last_name")} type="text" name="profile.lastname" />
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <TextField placeholder={t("email")} type="email" name="profile.email" />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <TextField
                      placeholder={t("settings_profile_phone_prefix")}
                      type="text"
                      name="profile.phonenumber_prefix"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <TextField placeholder={t("settings_profile_phone")} type="text" name="profile.phonenumber" />
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <JobSelector
                    name="profile.jobtype"
                    placeholder={t("complete_profile_job_label")}
                    defaultJob={userData ? userData.jobtype : undefined}
                    onChange={setFieldValue}
                    fieldTouched={setFieldTouched}
                    errors={errors.profile?.jobtype}
                    touched={touched.profile?.jobtype}
                  />
                </Form.Group>

                <Form.Group>
                  <AirlineSelector
                    name="profile.homeairline"
                    placeholder={t("complete_profile_airline_label")}
                    defaultAirline={userData ? userData.homeairline : undefined}
                    onChange={setFieldValue}
                    fieldTouched={setFieldTouched}
                    errors={errors.profile?.homeairline}
                    touched={touched.profile?.homeairline}
                  />
                </Form.Group>

                <Form.Group>
                  <AirportSelector
                    name="profile.base"
                    placeholder={t("complete_profile_homebase_label")}
                    defaultAirport={userData ? userData.base : undefined}
                    onChange={setFieldValue}
                    fieldTouched={setFieldTouched}
                    errors={errors.profile?.base}
                    touched={touched.profile?.base}
                  />
                </Form.Group>
              </div>

              <Form.Group>
                <SubmitButton
                  label={t("save_btn")}
                  loadingLabel={t("save_btn_loading")}
                  icon="save"
                  disabled={isSubmitting}
                />
              </Form.Group>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default ProfileSettings;
