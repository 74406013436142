import { AxiosResponse } from "axios";
import moment from "moment";
import { UploadType } from "../../enums/roster/UploadType";
import { RouteURL } from "../../enums/routing/Route";
import History from "../../History";
import { ICrewPortalCredentials, IRoute, IUploadRoute, IUser } from "../../interfaces";
import { createUploadRosterStore } from "../../stores/roster/UploadRosterStore";
import { createUserStore } from "../../stores/user/UserStore";
import { trackFailedRosterUpload, trackSuccessfulRosterUpload } from "../../utils/GoogleAnalytics";
import { fetchRosterFromPortal, uploadRoster as uploadRosterFileOrText } from "../api/ApiService";

enum UploadMethod {
  PORTAL = "Portal",
  FILE = "File",
  SRC = "Source"
}

export class UploadRosterService {
  /**
   * Upload a roster as File
   * @param {File} file File of the roster
   */
  public async uploadRosterFile(file: File): Promise<void> {
    try {
      const response: AxiosResponse = await uploadRosterFileOrText(file, UploadType.FILE);

      this.uploadSuccessful(response, UploadMethod.FILE);
    } catch (error) {
      this.uploadFailed(UploadMethod.FILE);
      throw error;
    }
  }

  /**
   * Upload a roster as Text
   * @param {string} src Text source of the roster
   */
  public async uploadRosterText(src: string): Promise<void> {
    try {
      const response: AxiosResponse = await uploadRosterFileOrText(src, UploadType.TEXT);

      this.uploadSuccessful(response, UploadMethod.SRC);
    } catch (error) {
      this.uploadFailed(UploadMethod.SRC);
      throw error;
    }
  }

  /**
   * Fetch the roster from the Crew Portal
   * @param {ICrewPortalCredentials} request Contains the Crew Portal credentials
   */
  public async fetchRosterFromPortal(request: ICrewPortalCredentials): Promise<void> {
    let newRequest: ICrewPortalCredentials = request;

    try {
      if (request.start_date) {
        newRequest = {
          ...request,
          start_date: moment(request.start_date).format("DD/MM/YYYY")
        };
      }

      const response: AxiosResponse = await fetchRosterFromPortal(newRequest);

      this.uploadSuccessful(response, UploadMethod.PORTAL);
    } catch (error) {
      this.uploadFailed(UploadMethod.PORTAL);
      throw error;
    }
  }

  /**
   * Converts IUploadRoutes into IRoutes
   * @param {Array<IStatisticsRoute>} statsRoutes List of IUploadRoute
   * @returns {Array<IRoute>} list of IRoute
   */
  public getRoutes(uploadRoutes: Array<IUploadRoute>): Array<IRoute> {
    const routes: Array<IRoute> = new Array<IRoute>();

    uploadRoutes.forEach((uploadRoute: IUploadRoute) => {
      const route: IRoute = {
        start: {
          lat: uploadRoute.start.LatNorth,
          lng: uploadRoute.start.LongEast
        },
        end: {
          lat: uploadRoute.end.LatNorth,
          lng: uploadRoute.end.LongEast
        }
      };

      routes.push(route);
    });

    return routes;
  }

  /**
   * Handles and tracks a successful upload.
   *
   * @param method The used upload method.
   */
  private uploadSuccessful(response: AxiosResponse<any>, method: UploadMethod): void {
    const user: IUser | undefined = createUserStore().userData?.user;

    trackSuccessfulRosterUpload(method, user?.homeairline);

    createUploadRosterStore().uploadResponse = response.data;

    History.push(RouteURL.UPLOAD_SUCCESS);
  }

  /**
   * Tracks a failed upload.
   *
   * @param method The used upload method.
   */
  private uploadFailed(method: UploadMethod): void {
    const user: IUser | undefined = createUserStore().userData?.user;

    trackFailedRosterUpload(method, user?.homeairline);
  }
}

let service: UploadRosterService | null = null;

export const uploadRosterService = (): UploadRosterService => service ?? (service = new UploadRosterService());
