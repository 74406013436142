import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Form, ToggleButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import TextField from "../../../components/inputs/TextField";
import SettingsRow from "../../../components/settings/SettingsRow";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";

enum Value {
  NEVER = 0,
  EXPIRED = -1,
  EXCEED = 1
}

interface IFormValues {
  selected: Value;
  exceed_value: number;
}

interface IProps {
  defaultValue?: number;
  onChange: (value: string) => Promise<void>;
}

const ExpiredRosters = (props: IProps): JSX.Element => {
  const { defaultValue = 0, onChange } = props;

  const { t } = useTranslation();

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    let valueToSave: number;
    if (values.selected === Value.EXCEED) {
      valueToSave = values.exceed_value;
    } else {
      valueToSave = values.selected;
    }
    await onChange(String(valueToSave));
  };

  const getDefaultValue = (): Value => {
    switch (defaultValue) {
      case Value.NEVER:
        return Value.NEVER;
      case Value.EXPIRED:
        return Value.EXPIRED;
      default:
        return Value.EXCEED;
    }
  };

  const getInitialValues = (): IFormValues => {
    return {
      selected: defaultValue,
      exceed_value: defaultValue > 0 ? defaultValue : 1
    };
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={async (values: IFormValues, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);
          await handleSubmit(values);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<any>) => {
        const { values, setFieldValue, isSubmitting } = props;

        return (
          <FormikForm>
            <SettingsRow label={t("settings_roster_delete_label")} description={t("settings_roster_delete_desc")}>
              <ToggleButtonGroup
                vertical
                type="radio"
                name="delete-rosters"
                defaultValue={getDefaultValue()}
                onChange={(value: Value) => setFieldValue("selected", value)}>
                <Form.Check
                  custom
                  id="never"
                  value={Value.NEVER}
                  label={t("settings_roster_delete_never")}
                  type="radio"
                />
                <Form.Check
                  custom
                  id="expired"
                  value={Value.EXPIRED}
                  label={t("settings_roster_delete_expired")}
                  type="radio"
                />
                <Form.Check
                  custom
                  id="exceed"
                  value={Value.EXCEED}
                  label={t("settings_roster_delete_exceed")}
                  type="radio"
                />
              </ToggleButtonGroup>

              <Form.Group>
                <TextField name="exceed_value" type="number" min={1} disabled={values.selected < 1} />
              </Form.Group>

              <PrimaryButton
                type="submit"
                label={t("save_btn")}
                loadingLabel={t("save_btn_loading")}
                disabled={isSubmitting}
              />
            </SettingsRow>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ExpiredRosters;
