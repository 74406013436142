//tslint:disable no-magic-numbers
import { ErrorMessage, Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SettingsRow from "../../../components/settings/SettingsRow";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";

interface IProps {
  station: string | undefined;
  triggerTime: number | undefined;
  onSubmit: (station: string, time: number) => Promise<void>;
}

const FlightAlarm = (props: IProps): JSX.Element => {
  const { station, triggerTime, onSubmit } = props;

  const { t } = useTranslation();

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    station: Yup.string().required(t("preferences.calendar.flight_alarm.iata.required"))
  });

  const getInitialTriggerTime = (): string => {
    if (!triggerTime) {
      return "";
    }

    const minutes: number = Math.floor((triggerTime / 60) % 60);
    const hours: number = Math.floor((triggerTime / (60 * 60)) % 24);

    const minutesAsString: string = String(minutes < 10 ? "0" + minutes : minutes);
    const hoursAsString: string = String(hours < 10 ? "0" + hours : hours);

    return hoursAsString + ":" + minutesAsString;
  };

  /**
   * Creates options for alarm trigger times
   */
  const getTriggerTimeOptions = (): Array<JSX.Element> => {
    const triggerTimes: Array<JSX.Element> = [];

    triggerTimes.push(<option key="disabled">{t("preferences.calendar.flight_alarm.disabled")}</option>);

    for (let i: number = 300; i < 86400; i += 300) {
      const minutes: number = Math.floor((i / 60) % 60);
      const hours: number = Math.floor((i / (60 * 60)) % 24);

      const minutesAsString: string = String(minutes < 10 ? "0" + minutes : minutes);
      const hoursAsString: string = String(hours < 10 ? "0" + hours : hours);

      triggerTimes.push(<option key={hoursAsString + minutesAsString}>{`${hoursAsString}:${minutesAsString}`}</option>);
    }

    triggerTimes.push(<option key="24:00">24:00</option>);

    return triggerTimes;
  };

  /**
   * Converts the selected trigger time string to seconds.
   *
   * @param {string} time Selected trigger time.
   * @returns {number} Trigger time in seconds.
   */
  const convertTimeToSeconds = (time: string): number => {
    if (time === t("preferences.calendar.flight_alarm.disabled")) {
      return 0;
    }

    const timeArray: Array<string> = time.split(":");

    const hours: number = Number(timeArray[0]);
    const minutes: number = Number(timeArray[1]);

    const timeInSeconds: number = hours * 60 * 60 + minutes * 60;

    return timeInSeconds;
  };

  return (
    <SettingsRow label={t("preferences.calendar.flight_alarm.label")}>
      <Formik
        initialValues={{
          station: station ?? "",
          time: getInitialTriggerTime()
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async (values: { station: string; time: string }, actions: FormikHelpers<any>) => {
          try {
            actions.setSubmitting(true);
            await onSubmit(values.station, convertTimeToSeconds(values.time));
          } catch (error) {
            showErrorMessage(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}>
        {(props: FormikProps<any>) => {
          const { handleChange, handleBlur, isSubmitting, values } = props;

          return (
            <FormikForm>
              <div className="pb-3">
                <Form.Group>
                  <Form.Label>
                    {t("preferences.calendar.flight_alarm.iata.title")}
                    <span className="text-muted" style={{ fontSize: "0.875rem", fontStyle: "italic" }}>
                      {" "}
                      {t("preferences.calendar.flight_alarm.iata.subtitle")}
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="station"
                    type="text"
                    minLength={3}
                    maxLength={3}
                    value={values.station}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="input-feedback" name="station" component="div" />
                </Form.Group>

                <Form.Group>
                  <Form.Label>{t("preferences.calendar.flight_alarm.trigger_time.title")}</Form.Label>
                  <InputGroup>
                    <Form.Control name="time" as="select" onChange={handleChange} value={values.time}>
                      {getTriggerTimeOptions()}
                    </Form.Control>
                    <InputGroup.Append>
                      <InputGroup.Text>{t("preferences.calendar.flight_alarm.trigger_time.subtitle")}</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                <PrimaryButton
                  label={t("save_btn")}
                  loadingLabel={t("save_btn_loading")}
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
      <div
        dangerouslySetInnerHTML={{ __html: t("preferences.calendar.flight_alarm.description") }}
        style={{ fontSize: "0.875rem" }}
      />
    </SettingsRow>
  );
};

export default FlightAlarm;
