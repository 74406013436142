import { AxiosResponse } from "axios";
import { IChangePassword } from "../../../interfaces";
import { changeLBPassword } from "../../../services/api/AuthApiService";
import { showSuccessMessage } from "../../../stores/error/SnackbarStore";
import { resetPassword } from "../LogbookApiService";

export class LogbookPasswordService {
  /**
   * Request a password reset for rosterbuster users.
   * @param {string} email The user's email
   * @param {string} recaptchaToken The token received from the recaptcha
   */
  public async resetPassword(email: string, recaptchaToken: string): Promise<void> {
    try {
      const response: AxiosResponse = await resetPassword(email, recaptchaToken);

      showSuccessMessage(response);
    } catch (error) {
      throw error;
    }
  }

  public async setNewPassword(newPassword: IChangePassword): Promise<void> {
    try {
      await changeLBPassword(newPassword);
    } catch (error) {
      throw error;
    }
  }
}

let service: LogbookPasswordService | null = null;

export const logbookPasswordService = (): LogbookPasswordService => service ?? (service = new LogbookPasswordService());
