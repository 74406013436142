import { IAuthorizeThirdparty } from "../../../interfaces";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { AvailableLogos } from "../../../enums/thirdparty/AvailableLogos";
import { RouteComponentProps } from "react-router";
import { thirdpartyService } from "../../../services/thirdparty/ThirdpartyService";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import PageHead from "../../PageHead";
import rbLogoBlue from "../../../assets/images//logo/rb-logo-blue.png";
import logbookLogo from "../../../assets/images//logo/rb-logbook-logo.png";
import airsideLogo from "../../../assets/images//logo/airside-logo.png";
import React, { useEffect, useState } from "react";
import ThirdpartyAuthoirzeForm from "./ThirdpartyAuthorizeForm";
import { IThirdpartyData } from "../../../interfaces";
import queryString from "query-string";

export const ThirdpartyAuthorizePage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const queryParams: any = queryString.parse(props.location?.search);
  let logbookRequest: boolean = false;

  const handleAuthorize = async (input: IAuthorizeThirdparty): Promise<void> => {
    try {
      const res = await thirdpartyService().authorizeThirdparty(input, logbookRequest);
      if (res.data.status === "OK" && res.status === 200) {
        const redirect: string = res.data.message;
        window.location.replace(redirect);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDeny = (input: IAuthorizeThirdparty): string => {
    try {
      return thirdpartyService().denyThirdpartyAuthorize(input);
    } catch (error) {
      throw error;
    }
  };

  const handleThirdpartyData = async (): Promise<IThirdpartyData> => {
    try {
      return await thirdpartyService().getAllThirdpartyData(queryParams.consumer_key);
    } catch (error) {
      throw error;
    }
  };

  const defaultData: IThirdpartyData = {
    consumer: t("third_party_loading_text"),
    scopes: [t("third_party_loading_text")],
    logo: ""
  };

  const [thirdpartyScopes, setThirdpartyScopes] = useState<IThirdpartyData>(defaultData);

  useEffect(() => {
    handleThirdpartyData().then((response) => setThirdpartyScopes(response));
  }, []);

  const logo = (requestDataLogo: string) => {
    if (requestDataLogo === AvailableLogos.LOGBOOK) {
      logbookRequest = true;
      return logbookLogo;
    }
    logbookRequest = false;
    return rbLogoBlue;
  };

  const appName = (requestDataLogo: string) => {
    if (requestDataLogo === AvailableLogos.LOGBOOK) {
      return "RB Logbook";
    }
    return "Rosterbuster";
  };

  const getConsumerLogo = (requestDataLogo: string): string | undefined => {
    if (requestDataLogo.startsWith("Airside")) {
      return airsideLogo;
    }

    return undefined;
  };

  return (
    <>
      <PageHead title={PageTitle.THIRD_PARTY_AUTHORIZE} description={PageDescription.THIRD_PARTY_AUTHORIZE} />
      <div className="justify-content-center align-items-center d-flex w-auto">
        <div className="w-25" style={{ minWidth: "280px" }}>
          <div className="text-center my-4 d-flex align-items-center justify-content-around">
            <Image src={logo(thirdpartyScopes.logo)} style={{ height: "100px", width: "auto" }} />
            {getConsumerLogo(thirdpartyScopes.consumer) && (
              <Image src={getConsumerLogo(thirdpartyScopes.consumer)} style={{ height: "100px", width: "auto" }} />
            )}
          </div>
          <div className="rb-bg-primary textarea" style={{ paddingBlock: 10, paddingBottom: 10, borderRadius: 10 }}>
            <div className="m-3">
              <p className="text-uppercase font-w700 font-size-h5 text-center text-light">
                {t("third_party_authorize_text")}
                {thirdpartyScopes.consumer}
              </p>
              <p className="text-uppercase mb-2 font-size-sm text-light">{t("third_party_data_sharing_text")}</p>
              <ul className="text-uppercase mt-0 font-size-sm text-light">
                {thirdpartyScopes.scopes.map((scopes) => {
                  return <li key={`${scopes}`}>{scopes}</li>;
                })}
              </ul>
              {thirdpartyScopes.consumer === "Crew Access" && (
                <p className="font-w600 mt-3 mb-0 font-size-sm text-light">
                  Do not have a RosterBuster account?{" "}
                  <a href="https://k5745.app.goo.gl/crewaccess" className={"rb-active-nav-davygray-link"}>
                    Tap here !
                  </a>
                </p>
              )}
              <p className="font-w600 mt-3 mb-0 font-size-sm text-light">
                {t("third_party_data_creds_text", {
                  app: appName(thirdpartyScopes.logo),
                  other: thirdpartyScopes.consumer
                })}
              </p>
            </div>
          </div>
          <ThirdpartyAuthoirzeForm
            onSubmit={handleAuthorize}
            denyThirdpartysAuthorize={handleDeny}
            queryParams={queryParams}
            routeComponentProps={props}
          />
        </div>
      </div>
    </>
  );
};

export default ThirdpartyAuthorizePage;
