import { AxiosResponse } from "axios";
import { ISignUpUser } from "../../interfaces";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { signUp } from "../api/AuthApiService";

export class SignUpService {
  /**
   * Create a new user account
   *
   * @param {ISignUpUser} user Contains the user's info
   */
  public async signUp(user: ISignUpUser): Promise<void> {
    try {
      const response: AxiosResponse = await signUp(user);

      createAuthStore().setTokenForRB(response.data.data.tokens);
    } catch (error) {
      throw error;
    }
  }
}

let service: SignUpService | null = null;

export const signUpService = (): SignUpService => service ?? (service = new SignUpService());
