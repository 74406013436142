import { AxiosInstance, AxiosResponse } from "axios";
import { UploadType } from "../../enums/roster/UploadType";
import { Preference } from "../../enums/settings/Preference";
import { ICrewPortalCredentials, IExportEventRequest, IStatisticsRequest } from "../../interfaces";
import { httpService } from "./HttpService";

enum EndPoint {
  RESET_PASSWORD = "/v2/auth/resetpassword",
  USER = "/v3/user/me",
  USER_UPDATE = "/v2/user",
  USER_AVATAR = "/v2/user/avatar",
  USER_CANCEL = "/v2/user/cancel/",
  AIRLINE = "/v2/airline",
  AIRPORT = "/v2/airport",
  EVENTS = "/v2/event",
  ROSTER_UPLOAD = "/v2/roster/upload",
  ROSTER_PORTAL = "/v2/roster/fetch",
  STATISTICS = "/v2/user/statistics",
  PREFERENCE_UPDATE = "/v2/user/me/pref",
  DATA_REQUEST = "/v2/user/profiledata",
  CALENDAR_OUTPUT_FORMATS = "/v2/calendar/outputformats",
  EXPORT_EVENTS = "/v2/export/",
  EXPORT_PDF = "/v2/calendar/pdf",
  UNSUBSCRIBE_EMAIL = "v3/user/email/unsubscribe",
  AMBASSADOR_OPT_OUT = "v4/user/ambassador"
}

const _channel: string = process.env.REACT_APP_CHANNEL!;

const _guestClient = (): AxiosInstance => {
  return httpService().guestClient();
};

const _client = async (): Promise<AxiosInstance> => {
  return await httpService().client();
};

/**
 * Request a password reset
 * @param {string} email The user's email
 * @param {string} recaptchaResponse Google recaptcha response token
 */
export const resetPassword = async (email: string, recaptchaResponse: string): Promise<AxiosResponse<any>> => {
  return await _guestClient().get(EndPoint.RESET_PASSWORD, {
    headers: {
      email: email,
      "g-recaptcha-response": recaptchaResponse
    }
  });
};

/**
 * Fetch the signed-in user's account
 */
export const fetchUser = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.USER);
};

/**
 * Update the signed-in user's account
 * @param {object} userData Contains the data to update
 */
export const updateUser = async (userData: {}): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(EndPoint.USER_UPDATE, userData);
};

/**
 * Upload an avatar
 * @param {File} image The image to be uploaded as avatar
 */
export const uploadAvatar = async (image: File): Promise<AxiosResponse<any>> => {
  const headers: any = { "Content-Type": "multipart/form-data" };
  const data: FormData = new FormData();
  data.append("avatar", image);

  const client: AxiosInstance = await _client();
  return await client.post(EndPoint.USER_AVATAR, data, headers);
};

/**
 * Fetches all airlines or by query
 * @param {string} query Optional param to fetch specific airlines
 */
export const fetchAirlines = async (query?: string): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.AIRLINE, { headers: { query: query } });
};

/**
 * Fetches all airports or by query
 * @param {string} query optional param to fetch specific airports
 */
export const fetchAirports = async (query?: string): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.AIRPORT, { headers: { query: query } });
};

/**
 * Fetches all events
 */
export const fetchEvents = async (startDate: string, endDate?: string): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  const headers: any = { startdate: startDate };
  if (endDate) {
    headers["enddate"] = endDate;
  }

  return await client.get(EndPoint.EVENTS, {
    headers: headers
  });
};

/**
 * Upload a roster manually
 * @param {string} src Source of the roster
 * @param {string} type Type of the roster ('file' or 'text')
 */
export const uploadRoster = async (
  src: string | File,
  type: UploadType.FILE | UploadType.TEXT
): Promise<AxiosResponse<any>> => {
  const headers: any = { "Content-Type": "multipart/form-data" };
  const data: FormData = new FormData();
  data.append("source", src);
  data.append("upload_type", type);
  data.append("channel", _channel);

  const client: AxiosInstance = await _client();
  return await client.post(EndPoint.ROSTER_UPLOAD, data, headers);
};

/**
 * Fetch a roster from the airline's Crew Portal
 * @param {ICrewPortalCredentials} request contains the Crew Portal credentials
 */
export const fetchRosterFromPortal = async (request: ICrewPortalCredentials): Promise<AxiosResponse<any>> => {
  request.channel = _channel;
  const client: AxiosInstance = await _client();
  return await client.post(EndPoint.ROSTER_PORTAL, request);
};

/**
 * Fetch statistics for a certain period
 * @param {IStatisticsRequest} request Contains a fixed or a custom period to fetch statistics
 */
export const fetchStatistics = async (request: IStatisticsRequest): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.STATISTICS, { params: request });
};

/**
 * Update a user preference
 * @param {Preference} preference The preference to update
 * @param {string | number} value The value to set
 */
export const updatePreference = async (preference: Preference, value: string | number): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(EndPoint.PREFERENCE_UPDATE, {}, { headers: { preference: preference, value: value } });
};

/**
 * Request user's data
 */
export const requestData = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.DATA_REQUEST);
};

/**
 * Sets the user's account to inactive and deletes the user's account after 30 days of inactivity
 * @param {number} pk The user's pk
 */
export const cancelUser = async (pk: number): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(EndPoint.USER_CANCEL + pk);
};

/**
 * Fetches all calendar output formats.
 */
export const fetchCalendarOutputFormats = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.CALENDAR_OUTPUT_FORMATS);
};

/**
 * Exports events
 *
 * @param {IExportEventRequest} request Contains the provider, startDate and endDate
 */
export const exportEvents = async (request: IExportEventRequest): Promise<AxiosResponse<any>> => {
  const { provider, start, end } = request;
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.EXPORT_EVENTS + provider, {
    params: {
      start: start,
      end: end
    }
  });
};

/**
 * Exports events to PDF
 *
 * @param {IExportEventRequest} request Contains the startDate and endDate
 */
export const exportToPDF = async (request: IExportEventRequest): Promise<AxiosResponse<any>> => {
  const { start, end } = request;
  const client: AxiosInstance = await _client();
  return await client.get(EndPoint.EXPORT_PDF, {
    responseType: "blob",
    headers: {
      startdate: start,
      enddate: end
    }
  });
};

/**
 * Unsubscribe from RosterBuster emails.
 */
export const unsubscribeFromEmail = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(EndPoint.UNSUBSCRIBE_EMAIL);
};

/**
 * Unsubscribe from the RosterBuster Ambassador program
 */
export const optOutFromAmbassadorProgram = async (): Promise<AxiosResponse<any>> => {
  const client: AxiosInstance = await _client();
  return await client.put(EndPoint.AMBASSADOR_OPT_OUT, { ambassador: false });
};
