import { ErrorMessage, Field } from "formik";
import React from "react";

interface IProps {
  id?: string;
  placeholder?: string;
  type: string;
  name: string;
  min?: number;
  disabled?: boolean;
}

const TextField = (props: IProps): JSX.Element => {
  const { id, placeholder, type, name, min, disabled } = props;

  return (
    <>
      <Field
        id={id}
        className="text-input"
        type={type}
        min={min}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "#f4f6fa"
        }}
      />
      <ErrorMessage className="input-feedback" name={name} component="div" />
    </>
  );
};

export default TextField;
