import { AxiosResponse } from "axios";
import { action } from "mobx";
import { createUserStore, UserStore } from "../../stores/user/UserStore";
import {
  fetchUser,
  optOutFromAmbassadorProgram,
  unsubscribeFromEmail,
  updateUser,
  uploadAvatar
} from "../api/ApiService";
import { verifyUser } from "../api/AuthApiService";
import { createFirebaseAuthService } from "../firebase/FirebaseAuthService";

export class UserService {
  /**
   * Fetches the user's account
   *
   * @param {boolean} force Pass true to force fetching a new user.
   */
  @action.bound
  public async getUser(force: boolean = false): Promise<void> {
    const userStore: UserStore = createUserStore();

    if ((userStore.userFetched && !force) || userStore.isLoading) {
      return;
    }

    userStore.isLoading = true;

    try {
      const response: AxiosResponse = await fetchUser();

      userStore.userData = response.data.data;

      try {
        await createFirebaseAuthService().signIn();
      } catch (e) {
        console.log(e);
      }

      userStore.isLoading = false;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Updates user's info and fetches new user data
   *
   * @param {object} userData Contains user data to update
   */
  @action.bound
  public async updateUser(userData: {}): Promise<void> {
    try {
      await updateUser(userData);

      await this.getUser(true);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Verifies the user and fetches new user data
   */
  @action.bound
  public async verifyUser(email: string, token: string): Promise<void> {
    try {
      await verifyUser(email, token);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Use this to update a single user property. (Make sure to manually update the user property in the UserStore.)
   *
   * @param {object} userData Contains user property to update
   */
  public async updateSingleUserProperty(property: {}): Promise<void> {
    try {
      await updateUser(property);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Uploads user's avatar
   *
   * @param {File} image The user's avatar
   */
  @action.bound
  public async uploadAvatar(image: File): Promise<void> {
    try {
      const response: AxiosResponse = await uploadAvatar(image);

      if (createUserStore().userData !== null) {
        createUserStore().userData!.user.avatar_url = response.data.data.url;
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Unsubscribe the user from emails.
   */
  public async unsubscribeUserFromEmails(): Promise<void> {
    try {
      await unsubscribeFromEmail();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Unsubscribe the user from the RosterBuster ambassador program.
   */
  public async optOutUserFromAmbassadorProgram(): Promise<void> {
    try {
      await optOutFromAmbassadorProgram();
    } catch (error) {
      throw error;
    }
  }
}

let service: UserService | null = null;

export const userService = (): UserService => service ?? (service = new UserService());
