import { observer } from "mobx-react";
import React from "react";
import { Router } from "react-router-dom";
import Snackbar from "../components/error/Snackbar";
import { RBApp } from "../enums/RBApp";
import History from "../History";
import { createAuthStore } from "../stores/auth/AuthStore";
import LogbookApp from "./logbook/LogbookApp";
import PublicApp from "./PublicApp";
import RosterBusterApp from "./rosterbuster/RosterBusterApp";

const MainApp: () => JSX.Element = observer(
  (): JSX.Element => {
    const { authenticated, app } = createAuthStore();

    const renderApp = (): JSX.Element => {
      if (authenticated && app === RBApp.ROSTERBUSTER) {
        return <RosterBusterApp />;
      } else if (authenticated && app === RBApp.LOGBOOK) {
        return <LogbookApp />;
      } else {
        return <PublicApp />;
      }
    };

    return (
      <>
        <Router history={History}>{renderApp()}</Router>

        <Snackbar />
      </>
    );
  }
);

export default MainApp;
