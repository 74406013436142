import { Location, LocationState } from "history";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useLocation } from "react-router-dom";
import backgroundImage from "../../../assets/images/background/rb-payment-bg.jpg";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import PageLoader from "../../../components/loaders/PageLoader";
import UserAvatar, { AvatarSize } from "../../../components/user/UserAvatar";
import { RouteURL } from "../../../enums/routing/Route";
import { Url } from "../../../enums/Url";
import History from "../../../History";
import { userService } from "../../../services/user/UserService";
import { Theme, useTheme } from "../../../utils/Theming";
import ErrorPage from "../../error/ErrorPage";

const EmailVerification = (props: RouteComponentProps): JSX.Element => {
  const [isUserVerified, setUserVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userEmailId, setUserEmailId] = useState<string>("");

  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const location: Location = useLocation<LocationState>();

  useEffect(() => {
    fetchUser().catch();
  }, []);

  const fetchUser = async (): Promise<void> => {
    const query: URLSearchParams = new URLSearchParams(location.search);
    try {
      setUserEmailId(query.get("email") ?? "");
      await userService().verifyUser(query.get("email")!, query.get("tempToken")!);
      setUserVerified(true);
    } catch (error) {
      setUserVerified(false);
    } finally {
      setLoading(false);
    }
  };

  const handleHomeNavigation = (): void => History.replace(RouteURL.HOME);

  if (loading) {
    return <PageLoader />;
  }

  return isUserVerified ? (
    <div className="bg-image w-100" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className="no-gutters hero-static justify-content-center">
        <Col sm={8} md={6} xl={4} className="d-flex align-items-center p-2 px-sm-0 text-center">
          <Card className="block-content block-content-full p-4">
            <UserAvatar className="mb-2" url={Url.AVATAR_PLACEHOLDER} size={AvatarSize.L} />
            <span style={{ fontWeight: "bold" }}>{userEmailId}</span>
            <div className="mt-4">
              <h1 style={{ color: theme.primaryColor, fontWeight: "bold" }}>{t("email_verified")}</h1>
            </div>
            <div>
              <SecondaryButton label={t("auth.back_to_home")} icon="arrow-left" onClick={handleHomeNavigation} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <ErrorPage {...props} />
  );
};

export default EmailVerification;
