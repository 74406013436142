import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import History from "../History";

interface IProps {
  title: string;
  description: string;
}

let page: string | null = null;

const PageHead = (props: IProps): JSX.Element => {
  useEffect(() => {
    if (page !== History.location.pathname) {
      page = History.location.pathname;

      ReactGA.set({
        page
      });
      ReactGA.pageview(page);
    }
  }, []);

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
    </Helmet>
  );
};

export default PageHead;
