//tslint:disable: no-magic-numbers
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ComponentBoundary from "../../components/error/boundaries/ComponentBoundary";
import PageLoader from "../../components/loaders/PageLoader";
import FlightMap from "../../components/map/FlightMap";
import StatsCard from "../../components/statistics/StatsCard";
import { IRoute, IStats } from "../../interfaces";
import { timelineService } from "../../services/timeline/TimelineService";
import { showErrorMessage } from "../../stores/error/SnackbarStore";
import { createTimelineStore, TimelineStore } from "../../stores/timeline/TimelineStore";
import EventList from "./eventlist/EventList";
import EventRangePicker from "./EventRangePicker";

export const Timeline: () => JSX.Element = observer(() => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initDashboard().catch();
  }, []);

  const initDashboard = async (): Promise<void> => {
    try {
      await timelineService().initDashboard();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilter = async (startDate: moment.Moment, endDate: moment.Moment): Promise<void> => {
    try {
      await timelineService().fetchEvents(startDate, endDate);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  if (loading) {
    return <PageLoader primary />;
  }

  const dashboardStore: TimelineStore = createTimelineStore();

  const stats: IStats | null = dashboardStore.stats;

  let routes: Array<IRoute> | null = null;

  if (dashboardStore.routes) {
    routes = timelineService().getFlightRoutes(dashboardStore.routes);
  }

  return (
    <div id="dashboard-content" className="content content-full">
      <Row>
        <Col md={6} xl={8}>
          <Row>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.flights")} count={stats ? stats.flights : 0} />
            </Col>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.airports")} count={stats ? stats.airports : 0} />
            </Col>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.countries")} count={stats ? stats.countries : 0} />
            </Col>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.regions")} count={stats ? stats.regions : 0} />
            </Col>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.block")} count={dashboardStore.blockHours} />
            </Col>
            <Col xs={4} lg={4} xl={2}>
              <StatsCard title={t("stats.duty")} count={dashboardStore.dutyHours} />
            </Col>
          </Row>
        </Col>

        <Col md={6} xl={4}>
          <ComponentBoundary componentName="Event Range Picker">
            <EventRangePicker
              initialStartDate={dashboardStore.initialStartDate!}
              initialEndDate={dashboardStore.initialEndDate!}
              onChange={applyFilter}
            />
          </ComponentBoundary>
        </Col>
      </Row>

      <Row
        id="events-map-row"
        style={{
          flex: 1,
          minHeight: "300px"
        }}>
        <Col id="flight-map-container" md={6} xl={8} style={{ height: "100%" }}>
          <ComponentBoundary componentName="Flight Map">
            <FlightMap routes={routes} />
          </ComponentBoundary>
        </Col>

        <Col md={6} xl={4} style={{ height: "100%" }}>
          <ComponentBoundary componentName="Event List">
            <EventList events={dashboardStore.events} />
          </ComponentBoundary>
        </Col>
      </Row>
    </div>
  );
});

export default Timeline;
