import React from "react";
import { Route } from "react-router-dom";
import MainContainer from "../../components/containers/MainContainer";
import PageContainer from "../../components/containers/PageContainer";
import Header from "../../components/framework/header/Header";
import CompleteProfilePage from "../../pages/auth/complete-profile/CompleteProfilePage";
import { createUserStore } from "../../stores/user/UserStore";
import { ThemeProvider, themes } from "../../utils/Theming";

const loadComponent = (Component: any, props: any): JSX.Element => {
  if (createUserStore().userProfileIncomplete) {
    return (
      <MainContainer>
        <CompleteProfilePage />
      </MainContainer>
    );
  } else {
    return (
      <PageContainer>
        <Header />
        <MainContainer>
          <Component {...props} />
        </MainContainer>
      </PageContainer>
    );
  }
};

const PrivateRouteRB: ({ component: Component, ...rest }: any) => JSX.Element = ({
  component: Component,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return <ThemeProvider theme={themes.rb}>{loadComponent(Component, props)}</ThemeProvider>;
      }}
    />
  );
};

export default PrivateRouteRB;
