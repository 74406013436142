import { AxiosResponse } from "axios";
import { Preference } from "../../enums/settings/Preference";
import { ICalendarOutputFormat } from "../../interfaces";
import { createUserStore } from "../../stores/user/UserStore";
import { cancelUser, fetchCalendarOutputFormats, requestData, updatePreference } from "../api/ApiService";
import { forceSignOut } from "../api/AuthApiService";
import { signOut } from "../auth/SignOutService";

export class SettingsService {
  /**
   * Updates a user preference
   * @param {Preference} preference The name of the preference to update
   * @param {string} value The value to set
   */
  public async updatePreference(preference: Preference, value: string | number): Promise<void> {
    try {
      await updatePreference(preference, value);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Requests the user's data
   */
  public async requestData(): Promise<void> {
    try {
      await requestData();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Signs the user out on all devices
   */
  public async forceSignOut(): Promise<void> {
    try {
      await forceSignOut();

      signOut();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Sets the user's account to inactive and deletes the user's account after 30 days of inactivity
   */
  public async cancelUser(): Promise<void> {
    try {
      const pk: number = createUserStore().userData!.user.pk;
      await cancelUser(pk);

      signOut();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Fetches calendar output formats.
   *
   * @returns {Promise<Array<ICalendarOutputFormat>>} Calendar output formats.
   */
  public async getCalendarOutputFormats(): Promise<Array<ICalendarOutputFormat>> {
    try {
      const response: AxiosResponse = await fetchCalendarOutputFormats();

      const outputFormats: Array<ICalendarOutputFormat> = response.data;

      return outputFormats;
    } catch (error) {
      throw error;
    }
  }
}

let service: SettingsService | null = null;

export const settingsService = (): SettingsService => service ?? (service = new SettingsService());
