import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import GenericUnsubscribePage from "../../components/generics/GenericUnsubscribePage";
import PageLoader from "../../components/loaders/PageLoader";
import { userService } from "../../services/user/UserService";
import ErrorPage from "../error/ErrorPage";

const AmbassadorOptOutPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const [successful, setSuccessful] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleUnsubscribe: () => Promise<void> = useCallback(async (): Promise<void> => {
    try {
      await userService().optOutUserFromAmbassadorProgram();

      setSuccessful(true);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleUnsubscribe().catch();
  }, [handleUnsubscribe]);

  if (loading) {
    return <PageLoader />;
  }

  return successful ? (
    <GenericUnsubscribePage appName={t("app_name")} messagePath="ambassador_opt_out" />
  ) : (
    <ErrorPage {...props} />
  );
};

export default AmbassadorOptOutPage;
