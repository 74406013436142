import { ErrorMessage, Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SettingsRow from "../../../components/settings/SettingsRow";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";

interface IProps {
  calendarName: string | undefined;
  onSubmit: (name: string) => Promise<void>;
}

const CalendarName = (props: IProps): JSX.Element => {
  const { calendarName, onSubmit } = props;

  const { t } = useTranslation();

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    calendarName: Yup.string().required(t("preferences.calendar.name.required"))
  });

  return (
    <SettingsRow label={t("preferences.calendar.name.label")} description={t("preferences.calendar.name.description")}>
      <Formik
        initialValues={{
          calendarName: calendarName ?? String(t("app_name"))
        }}
        validationSchema={validationSchema}
        onSubmit={async (values: { calendarName: string }, actions: FormikHelpers<any>) => {
          try {
            actions.setSubmitting(true);
            await onSubmit(values.calendarName);
          } catch (error) {
            showErrorMessage(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}>
        {(props: FormikProps<any>) => {
          const { values, handleChange, handleBlur, isSubmitting } = props;

          return (
            <FormikForm>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <Form.Control
                    name="calendarName"
                    type="text"
                    value={values.calendarName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage className="input-feedback" name="calendarName" component="div" />
                </div>
                <PrimaryButton
                  label={t("save_btn")}
                  loadingLabel={t("save_btn_loading")}
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </SettingsRow>
  );
};

export default CalendarName;
