import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { IPreferences, IUser, IUserData } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { userService } from "../../../services/user/UserService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import CityDeals from "./CityDeals";
import DeleteAccount from "./DeleteAccount";
import EmailMe from "./EmailMe";
import EmailMeFlightRoutes from "./EmailMeFlightRoutes";
import ForceSignOut from "./ForceSignOut";
import PublicProfile from "./PublicProfile";
import RequestData from "./RequestData";

const PrivacySettings: () => JSX.Element = observer(() => {
  const userData: IUserData = createUserStore().userData!;
  const user: IUser = userData.user!;
  const preference: IPreferences = userData.preferences!;

  const updateServerUserProperty = async (property: {}): Promise<void> => {
    try {
      await userService().updateSingleUserProperty(property);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const updateServerUserPref = async (pref: Preference, value: string): Promise<void> => {
    try {
      await settingsService().updatePreference(pref, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handlePublicProfile = async (value: string): Promise<void> => {
    user.profile_public = value;
    await updateServerUserProperty({ profile_public: value });
  };

  const handleEmail = async (value: number): Promise<void> => {
    user.emailme = value;
    await updateServerUserProperty({ emailme: value });
  };

  const handleEmailFlightRoutes = async (value: number): Promise<void> => {
    user.emailme_flightroutes = value;
    await updateServerUserProperty({ emailme_flightroutes: value });
  };

  const handleCityDeals = async (value: string): Promise<void> => {
    preference.citydeals = value;
    await updateServerUserPref(Preference.CITY_DEALS, value);
  };

  return (
    <ComponentBoundary componentName="Privacy settings">
      <PageHead title={PageTitle.SETTINGS_PRIVACY} description={PageDescription.SETTINGS_PRIVACY} />

      <PublicProfile defaultValue={user.profile_public ?? undefined} onChange={handlePublicProfile} />

      <EmailMe defaultValue={user.emailme ?? undefined} onChange={handleEmail} />

      <EmailMeFlightRoutes defaultValue={user.emailme_flightroutes ?? undefined} onChange={handleEmailFlightRoutes} />

      <CityDeals defaultValue={preference.citydeals ?? undefined} onChange={handleCityDeals} />

      <RequestData />

      <DeleteAccount />

      <ForceSignOut />
    </ComponentBoundary>
  );
});

export default PrivacySettings;
