import IKeys from "../Keys";

export const English: IKeys = {
  translations: {
    app_name: "RosterBuster",
    app_subtitle: "Simplify your life, on and off the runway.",

    // User Menu Items
    settings: "Settings",
    contact_us: "Contact Us",
    sign_out: "Sign Out",

    // Validation
    name_regex: "Numbers and symbols are not allowed.",
    firstname_required: "First name is required.",
    firstname_length: "First name must contain at least 2 characters.",
    lastname_required: "Last name is required.",
    lastname_length: "Last name must contain at least 2 characters.",
    email_required: "Email is required.",
    email_invalid: "Please provide a valid emailaddress.",
    password_required: "Password is required.",
    password_regex: "Use at least one uppercase and lowercase character, and one digit.",
    password_length: "Password must be at least 8 characters long.",
    confirm_password_required: "Password confirmation is required.",
    confirm_password_match: "Password does not match.",
    recaptcha_required: "Please verify you're not a robot.",
    position_required: "Position is required.",
    airline_required: "Airline is required.",
    homebase_required: "Homebase is required.",
    unsupported_image_format: "Only .jpg, .jpeg or .png image allowed.",
    file_required: "A file is required.",
    unsupported_file_format: "Only .txt or .pdf file allowed.",
    avatar_too_large: "Avatar may not exceed 1MB.",
    file_too_large: "File may not exceed 10MB.",
    field_required: "Field may not be empty.",
    roster_source_required: "Copy & Paste your roster in the textbox.",
    email_verified: "Thank you for completing your RB account activation!",
    logbook_email_verified: "Thank you for completing your RB Logbook account activation!",

    // Authentication
    sign_in: "Sign In",
    sign_up: "Sign Up",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    password: "Password",
    confirm_password: "Confirm password",
    promo_code: "Promo code",
    forgot_password: "Forgot Password?",
    sign_in_title: "Sign in",
    sign_in_btn_loading_label: "SIGNING IN...",
    sign_up_title: "Sign up",
    sign_up_btn_loading_label: "SIGNING UP...",
    reset_password_title: "Reset password",
    reset_password_btn_loading_label: "Sending request...",
    promocode_apply_successfully: "Promocode applied successfully",
    verification_mail: "A mail has been sent to {{email}}. Click on the link to verify.",

    auth: {
      back_to_sign_in: "Back to Sign In",
      back_to_home: "Back to Home"
    },

    // Complete Profile
    complete_profile_title: "COMPLETE YOUR PROFILE",
    complete_profile_job_label: "Select your position...",
    complete_profile_airline_label: "Search and select your airline...",
    complete_profile_homebase_label: "Search and select your homebase...",
    complete_profile_btn_label: "UPDATE",
    complete_profile_btn_loading_label: "UPDATING...",
    upload_avatar_btn_label: "Select Avatar",

    // Upgrade
    upgrade_monthly: "Monthly",
    upgrade_yearly: "Yearly",
    upgrade_premium_sub: "Premium Subscription",
    upgrade_monthly_renew: "(Renewing monthly)",
    upgrade_yearly_renew: "(Renewing yearly)",
    upgrade_price_vat: "(Price incl. VAT, can vary per country)",
    upgrade_discount_terms_yearly: "*The discount is valid for 1 year. Then the price will return to",
    upgrade_discount_terms_monthly: "*The discount is valid for 3 months. Then the price will return to",
    upgrade_success_msg1: "Thank you for subscribing",
    upgrade_success_msg2: "to {{appName}}!",
    upgrade_success_upgraded_msg1: "Your account has been upgraded",
    upgrade_success_upgraded_msg2: "to ",
    upgrade_success_upgraded_msg3: "Premium",
    upgrade_btn_label: "GO PREMIUM",
    upgrade_btn_loading_label: "LOADING...",
    upgrade_btn_loading_label_alt: "PROCESSING...",
    subscription_premium: "You have a Premium account",
    subscription_lifetime: "You have a Lifetime Premium account",
    subscription_expiry: "Your subscription renews on:",
    subscription_faq1: "If you have any questions regarding your subscription,",
    subscription_faq2: "please read our ",
    subscription_faq3: "FAQ.",

    // Dashboard
    stats: {
      flights: "Flights",
      airports: "Airports",
      countries: "Countries",
      regions: "Regions",
      block: "Block",
      duty: "Duty"
    },
    events_empty1: "You have no events in",
    events_empty2: "the selected date range.",
    event_all_day: "All Day",
    no_events: "You have no upcoming events.",
    no_events_message: "Upload your roster by clicking the button below.",
    show_history: "Show History",

    // EventDatePicker
    date_picker_title: "Select a date range",
    all: "All",
    next_seven_days: "Next 7 Days",
    next_thirty_days: "Next 30 Days",
    custom: "Custom Range",
    from: "From",
    to: "To",
    cancel: "Cancel",
    apply: "Apply",

    // Upload
    upload_roster_title: "Import Your Roster",
    upload_portal_btn: "Connect with Crew Portal",
    upload_file_title: "Upload a File",
    upload_text_title: "Upload Text/HTML",
    upload_text_subtitle: "Copy & Paste your roster",
    upload_text_placeholder: "Paste your roster here...",
    select_file: "Browse your files",
    upload_roster: "UPLOAD",
    uploading: "UPLOADING...",
    portal_subtitle: "Please enter the credentials of the crew portal where you login to download your roster.",
    portal_username: "Username/ID",
    portal_memorable: "Code/Pin/Token",
    portal_extra_tooltip: "Start date of the roster.",
    portal_days: "Number of days",
    portal_days_tooltip: "Number of days to include.",
    portal_btn_label: "CONNECT",
    portal_btn_loading_label: "CONNECTING...",
    portal_instructions_btn: "Need help? Read the Instructions!",
    portal_instructions_title: "Upload Instructions",
    upload_successful_title: "Roster uploaded successfully!",

    roster_upload: {
      months: {
        current: "Current",
        next: "Next",
        current_next: "Current and next",
        months: "Month(s)",
        period: "Period"
      }
    },

    // Download app
    download_app: {
      title: "Download RosterBuster!",
      subtitle: "Simplify your life, on and off the runway."
    },

    // Change password
    change_password: {
      title: "Change your password",
      success_title: "Password has been changed successfully!",
      success_message: "Sign in with your new password.",
      new_password: "New password"
    },

    // Campaigns
    unsubscribe: {
      title: "You've successfully unsubscribed.",
      subtitle: "You will no longer receive any emails for {{appName}}."
    },

    // Ambassador opt out
    ambassador_opt_out: {
      title: "You have opted-out of the Brand Ambassador Campaign.",
      subtitle: "Sorry to see you go!"
    },

    // Settings
    settings_title: "Settings",
    settings_profile_avatar: "Change avatar",
    settings_profile_phone: "Phone number",
    settings_profile_phone_prefix: "Phone prefix",

    settings_display_distance_label: "Distance",
    settings_display_distance_desc: "Metric used for distances.",
    settings_display_temperature_label: "Temperature",
    settings_display_temperature_desc: "Metric used for temperatures.",

    settings_roster_delete_label: "Delete rosters",
    settings_roster_delete_desc: "Automatically delete expired rosters.",
    settings_roster_delete_never: "Never",
    settings_roster_delete_expired: "When expired",
    settings_roster_delete_exceed: "When number of rosters exceeds:",

    settings_events_date_format_label: "Date format",
    settings_events_date_format_desc: "Format used to display dates.",
    settings_events_start_week_label: "First day",
    settings_events_start_week_desc: "First day of the week.",
    settings_events_generate_report_label: "Generate Report",
    settings_events_generate_report_desc: "Automatically generate Report events.",
    settings_events_filter_events_label: "Filter events",
    settings_events_filter_events_desc:
      "Enter duty codes you'd like to exclude from your roster. These will be excluded on the next roster upload.",
    settings_events_filter_events_placeholder: "Enter a duty code...",

    settings_privacy_profile_label: "Profile",
    settings_privacy_profile_desc: "Show/hide your profile from other users.",
    settings_privacy_profile_public: "Public",
    settings_privacy_profile_private: "Private",
    settings_privacy_email_me_label: "Email",
    settings_privacy_email_me_desc: "Receive emails about new features, updates and product discounts.",
    settings_privacy_email_me_flight_routes_label: "Email flight routes",
    settings_privacy_email_me_flight_routes_desc:
      "Receive emails about new locations you've visited or routes you've flown.",
    settings_privacy_city_deals_label: "Email city deals",
    settings_privacy_city_deals_desc: "Get exclusive deals at your homebase and destinations.",
    settings_privacy_request_data_label: "Request data",
    settings_privacy_request_data_desc: "Request an overview of your stored data.",
    settings_privacy_request_data_dialog_desc:
      "By clicking Confirm, you'll receive an email containing an overview of your stored data. This email will be send within 24 hours.",
    settings_privacy_delete_account_label: "Delete account",
    settings_privacy_delete_account_desc: "Delete your account permanently.",
    settings_privacy_delete_account_dialog_desc:
      "By clicking Confirm, you are signed out and your account will be set to inactive. After 30 days of inactivity, your account will be deleted permanently. If you sign in before 30 days of inactivity, your account will be reactivated.",
    settings_privacy_force_sign_out_label: "Force sign out",
    settings_privacy_force_sign_out_desc: "Sign out on all devices.",
    settings_privacy_force_sign_out_dialog_desc:
      "By clicking Confirm, your account will sign out on all devices. You can use this to block access to certain devices. It may take up to one hour to complete this process.",

    event_export: {
      to: "Export to",
      prepare_message: "Preparing to export",
      successful: "Export successful!",
      fridge_pdf: "Fridge PDF Calendar"
    },

    preferences: {
      account: "Account",
      export: "Export",

      profile: {
        title: "Profile"
      },

      display: {
        title: "Display",
        time: {
          label: "Timezone (WebApp / Fridge PDF)",
          description: "The timezone used for events."
        }
      },

      roster: {
        title: "Roster"
      },

      events: {
        title: "Events"
      },

      calendar: {
        title: "Calendar",
        name: {
          label: "Calendar name",
          required: "A name is required.",
          description: "Display name of your calendar."
        },
        aggregate_flights: {
          label: "Aggregate flights",
          description: "Group flights into one block. This will create one event covering all flights for that day."
        },
        output: {
          flight: {
            label: "Flight output format",
            description: "Customize the way flights appear in your calendar."
          },
          report: {
            label: "Report output format",
            description: "Customize the way reports appear in your calendar."
          },
          summary: "Title",
          location: "Location",
          description: "Description"
        },
        flight_alarm: {
          label: "Flight alarms",
          description:
            "Make sure that your device is <b>NOT</b> set to remove alarms.<br/>You will receive an alert for the first report time / flight on a given day (GMT).<br/>Setting the station to '999' will activate the alarm for all flights regardless of station.",
          iata: {
            title: "IATA",
            subtitle: "(999 for all airports)",
            required: "IATA is required."
          },
          trigger_time: {
            title: "Trigger time",
            subtitle: "before report / flight"
          },
          disabled: "Disabled"
        }
      },

      calendar_filter: {
        title: "Calendar filter",
        today_onwards: {
          label: "Today onwards",
          description: "If enabled, only future events will be exported."
        },
        exclude: {
          deadhead: {
            label: "Deadhead",
            description: "Exclude 'Deadhead' flights."
          },
          standby: {
            label: "Standby",
            description: "Exclude 'Standby' events."
          },
          day_off: {
            label: "Day Off",
            description: "Exclude 'Day Off' events."
          },
          report: {
            label: "Report",
            description: "Exclude 'Report' events."
          },
          debrief: {
            label: "Debrief",
            description: "Exclude 'Debrief' events."
          },
          simulator: {
            label: "Simulator",
            description: "Exclude 'Simulator' events."
          },
          layover: {
            label: "Layover",
            description: "Exclude 'Layover' events."
          }
        }
      },

      rb_logbook: {
        title: "RB Logbook",
        export_flights: {
          label: "Export flights",
          description: "Enable to export your roster automatically to RB Logbook after a roster upload."
        },
        crew_names: {
          label: "Export crew names",
          description: "The crew names to export.",
          all: "All",
          flight_crew: "Flight crew",
          none: "None"
        }
      },

      safelog: {
        title: "Safelog",
        key: {
          label: "API key",
          description: "Enter your Safelog API key.",
          required: "API key is required."
        }
      },

      privacy: {
        title: "Privacy"
      },

      reset_password: {
        title: "Reset password",
        description: "An email will be sent to reset your password."
      }
    },

    // Days
    today: "Today",
    monday: "Monday",
    sunday: "Sunday",

    // Metrics
    miles: "Miles",
    nautic_miles: "Nautic miles",
    kilometers: "Kilometers",
    celsius: "Celsius",
    fahrenheit: "Fahrenheit",

    // Buttons
    ok_btn: "Ok",
    close_btn: "Close",
    cancel_btn: "Cancel",
    confirm_btn: "Confirm",
    back_btn: "Back",
    save_btn: "Save",
    save_btn_loading: "Saving...",
    app_store_btn: "Download on the App Store",
    google_play_btn: "Get it on Google Play",
    upload_roster_btn: "Upload Roster",

    // Error messages
    error: {
      default: {
        title: "Oops...",
        message: "Something went wrong."
      },
      unauthorized: {
        title: "{{appName}}",
        message: "Please Sign In to continue."
      }
    },

    logbook: {
      app_name: "RB Logbook",
      app_subtitle: "Simplify your pilot life."
    },

    // Third party
    third_party_authorize_title: "Authorize",
    third_party_authorize_in_btn_loading_label: "AUTHORIZING...",
    third_party_deny_title: "Deny",
    third_party_authorize_text: "Authorize ",
    third_party_data_sharing_text: "Following data is shared:",
    third_party_data_creds_text:
      "For existing user, please provide your RB credentials to connect {{app}} to {{other}}.",
    third_party_remove_btn: "Remove",
    third_party_cannot_find_third_party: "Could not find any third party connections.",
    third_party_loading_text: "Loading...",
    third_party_settings: {
      title: "Third party settings",
      third_party: {
        description: "This third party can access: "
      }
    }
  }
};
