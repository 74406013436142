import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteURL } from "../../enums/routing/Route";
import DownloadAppPage from "../../pages/app-download/DownloadAppPage";
import SettingsPage from "../../pages/settings/SettingsPage";
import PrivateRouteRB from "../custom-routes/PrivateRouteRB";
import { SharedPublicRoutes } from "../SharedPublicRoutes";

const LiteApp = (): JSX.Element => {
  return (
    <Switch>
      <PrivateRouteRB exact path={RouteURL.DOWNLOAD_APP} component={DownloadAppPage} />
      <PrivateRouteRB path={RouteURL.SETTINGS} component={SettingsPage} />

      {SharedPublicRoutes()}

      <Redirect to={RouteURL.DOWNLOAD_APP} />
    </Switch>
  );
};

export default LiteApp;
