import { observer } from "mobx-react";
import React from "react";
import Form from "react-bootstrap/Form";

interface IProps {
  id: string;
  initialValue: string | number | undefined;
  onChange: (value: string) => Promise<void>;
}

const SettingSwitch: (props: IProps) => JSX.Element = observer(
  (props: IProps): JSX.Element => {
    const { id, initialValue, onChange } = props;

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      // Convert boolean to string;
      const value: string = String(+event.target.checked);

      await onChange(value);
    };

    return (
      <Form.Check
        type="switch"
        id={id}
        checked={initialValue ? Boolean(Number(initialValue)) : false}
        label=""
        onChange={handleOnChange}
      />
    );
  }
);

export default SettingSwitch;
