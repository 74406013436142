import { IStats, IUploadResponse, IUploadRoute } from "../../interfaces";

export class UploadRosterStore {
  private _uploadResponse: IUploadResponse | null = null;

  public set uploadResponse(response: IUploadResponse | null) {
    this._uploadResponse = response;
  }

  public get stats(): IStats | null {
    return this._uploadResponse ? this._uploadResponse.stats : null;
  }

  public get routes(): Array<IUploadRoute> | null {
    return this._uploadResponse ? this._uploadResponse.routes : null;
  }

  public clearStore(): void {
    store = null;
  }
}

let store: UploadRosterStore | null = null;

export const createUploadRosterStore = (): UploadRosterStore => store ?? (store = new UploadRosterStore());
