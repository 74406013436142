import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { ICalendarOutputFormat, IPreferences } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import AggregateFlights from "./AggregateFlights";
import CalendarName from "./CalendarName";
import FlightAlarm from "./FlightAlarm";
import FlightOutput from "./FlightOutput";
import ReportOutput from "./ReportOutput";

enum FormatType {
  FLIGHT,
  REPORT
}

const CalendarSettings: () => JSX.Element = observer(
  (): JSX.Element => {
    const preference: IPreferences | undefined = createUserStore().userData!.preferences;

    const [outputFormats, setOutputFormats] = useState<Array<ICalendarOutputFormat>>();

    useEffect(() => {
      getCalendarOutputFormats();
    }, []);

    const getCalendarOutputFormats = async (): Promise<void> => {
      try {
        const formats: Array<ICalendarOutputFormat> = await settingsService().getCalendarOutputFormats();

        setOutputFormats(formats);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const getFilteredFormats = (formatType: FormatType): Array<ICalendarOutputFormat> | undefined => {
      return outputFormats?.filter((format: ICalendarOutputFormat) => format.isreport === formatType);
    };

    const updateUserPref = async (pref: Preference, value: string | number): Promise<void> => {
      try {
        await settingsService().updatePreference(pref, value);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleCalendarNameChange = async (name: string): Promise<void> => {
      preference.rostername = name;
      await updateUserPref(Preference.ROSTERNAME, name);
    };

    const handleAggregateFlightsChange = async (value: string): Promise<void> => {
      preference.output_ical_aggregate_flights = value;
      await updateUserPref(Preference.OUTPUT_ICAL_AGGREGATE_FLT, value);
    };

    const handleFlightSummaryChange = async (pk: string): Promise<void> => {
      preference.output_ical_summary_flt = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_SUMMARY_FLT, pk);
    };

    const handleFlightLocationChange = async (pk: string): Promise<void> => {
      preference.output_ical_location_flt = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_LOCATION_FLT, pk);
    };

    const handleFlightDescriptionChange = async (pk: string): Promise<void> => {
      preference.output_ical_description_flt = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_DESCRIPTION_FLT, pk);
    };

    const handleReportSummaryChange = async (pk: string): Promise<void> => {
      preference.output_ical_summary_rep = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_SUMMARY_REP, pk);
    };

    const handleReportLocationChange = async (pk: string): Promise<void> => {
      preference.output_ical_location_rep = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_LOCATION_REP, pk);
    };

    const handleReportDescriptionChange = async (pk: string): Promise<void> => {
      preference.output_ical_description_rep = pk;
      await updateUserPref(Preference.OUTPUT_ICAL_DESCRIPTION_REP, pk);
    };

    const handleFlightAlarmChange = async (iata: string, triggerTime: number): Promise<void> => {
      preference.report_add_alarm_iata = iata;
      preference.report_add_alarm_seconds = triggerTime;

      await Promise.all([
        updateUserPref(Preference.REPORT_ALARM_IATA, iata),
        updateUserPref(Preference.REPORT_ALARM_TIME, triggerTime)
      ]);
    };

    return (
      <ComponentBoundary componentName="Calendar settings">
        <PageHead title={PageTitle.SETTINGS_CALENDAR} description={PageDescription.SETTINGS_CALENDAR} />

        <CalendarName calendarName={preference?.rostername} onSubmit={handleCalendarNameChange} />

        <AggregateFlights
          defaultValue={preference?.output_ical_aggregate_flights}
          onChange={handleAggregateFlightsChange}
        />

        <FlightOutput
          summary={preference?.output_ical_summary_flt}
          location={preference?.output_ical_location_flt}
          description={preference?.output_ical_description_flt}
          onSummaryChange={handleFlightSummaryChange}
          onLocationChange={handleFlightLocationChange}
          onDescriptionChange={handleFlightDescriptionChange}
          outputFormats={getFilteredFormats(FormatType.FLIGHT)}
        />

        <ReportOutput
          summary={preference?.output_ical_summary_rep}
          location={preference?.output_ical_location_rep}
          description={preference?.output_ical_description_rep}
          onSummaryChange={handleReportSummaryChange}
          onLocationChange={handleReportLocationChange}
          onDescriptionChange={handleReportDescriptionChange}
          outputFormats={getFilteredFormats(FormatType.REPORT)}
        />

        <FlightAlarm
          station={preference?.report_add_alarm_iata}
          triggerTime={preference?.report_add_alarm_seconds}
          onSubmit={handleFlightAlarmChange}
        />
      </ComponentBoundary>
    );
  }
);

export default CalendarSettings;
