import { AxiosResponse } from "axios";
import { IChangePassword } from "../../interfaces";
import { showSuccessMessage } from "../../stores/error/SnackbarStore";
import { resetPassword } from "../api/ApiService";
import { changePassword } from "../api/AuthApiService";

export class PasswordService {
  /**
   * Request a password reset
   * @param {string} email The user's email
   * @param {string} recaptchaToken The token received from the recaptcha
   */
  public async resetPassword(email: string, recaptchaToken: string): Promise<void> {
    try {
      const response: AxiosResponse = await resetPassword(email, recaptchaToken);

      showSuccessMessage(response);
    } catch (error) {
      throw error;
    }
  }

  public async setNewPassword(newPassword: IChangePassword): Promise<void> {
    try {
      await changePassword(newPassword);
    } catch (error) {
      throw error;
    }
  }
}

let service: PasswordService | null = null;

export const passwordService = (): PasswordService => service ?? (service = new PasswordService());
