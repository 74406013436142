import { AxiosResponse } from "axios";
import { cloneDeep, first, last } from "lodash";
import { action } from "mobx";
import moment, { Moment } from "moment";
import { IEvent } from "../../interfaces";
import { timelineService } from "../../services/timeline/TimelineService";
import { createTimelineStore, TimelineStore } from "../../stores/timeline/TimelineStore";
import { getUserTimezone } from "../../utils/DateTimeUtils";
import { fetchEvents } from "../api/ApiService";

export class EventService {
  private readonly MILLIS: number = 1000;
  private readonly HISTORY_PERIOD: number = 6;
  private readonly HISTORY_PREVIOUS_DAY: number = -1;

  /**
   * Fetches events of given date range and saves them in the UserStore
   */
  @action.bound
  public async fetchEvents(startDate: string, endDate?: string): Promise<Array<IEvent>> {
    try {
      const response: AxiosResponse = await fetchEvents(startDate, endDate);

      return response.data.event || [];
    } catch (error) {
      throw error;
    }
  }

  /**
   * async fetchAllEvents
   */
  @action.bound
  public async fetchAllEvents(): Promise<void> {
    try {
      const timelineStore: TimelineStore = createTimelineStore();

      timelineStore.showAllEvents = true;

      const startDate: Moment = moment
        .tz(getUserTimezone())
        // tslint:disable-next-line: no-magic-numbers
        .add(-this.HISTORY_PERIOD, "months")
        .startOf("day");

      timelineStore.events = await this.fetchEvents(startDate.format("YYYY-MM-DD"));
      //Do not wait for statistics to load
      timelineService()
        .getStatistics(startDate, moment("9999-12-31", "YYYY-MM-DD"))
        .catch();

      if (timelineStore.events.length > 0) {
        const firstEvent: IEvent | undefined = first(timelineStore.events);
        const lastEvent: IEvent | undefined = last(timelineStore.events);

        const startDate: moment.Moment | undefined =
          firstEvent &&
          moment(firstEvent.dutyStartTimestamp * this.MILLIS)
            .tz(getUserTimezone())
            .startOf("day");

        const endDate: moment.Moment | undefined =
          lastEvent &&
          moment(lastEvent.dutyStartTimestamp * this.MILLIS)
            .tz(getUserTimezone())
            .startOf("day");

        timelineStore.allEventsStartDate = startDate;

        timelineStore.initialStartDate = startDate;
        timelineStore.initialEndDate = endDate;
      } else {
        timelineStore.allEventsStartDate = startDate;

        timelineStore.initialStartDate = startDate;
        timelineStore.initialEndDate = moment
          .tz(getUserTimezone())
          // tslint:disable-next-line: no-magic-numbers
          .add(this.HISTORY_PERIOD, "months")
          .startOf("day");
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * async show history events
   */
  @action.bound
  public async showHistory(): Promise<void> {
    try {
      const timelineStore: TimelineStore = createTimelineStore();

      timelineStore.showAllEvents = true;

      let startDate: Moment | undefined = timelineStore.allEventsStartDate;
      let endDate: Moment | undefined;

      //Calculate date range to history events.
      if (startDate) {
        endDate = cloneDeep(startDate)
          .tz(getUserTimezone())
          .add(this.HISTORY_PREVIOUS_DAY, "day");
        // tslint:disable-next-line: no-magic-numbers
        startDate = startDate.tz(getUserTimezone()).add(-this.HISTORY_PERIOD, "months");
      } else {
        startDate = moment
          .tz(getUserTimezone())
          // tslint:disable-next-line: no-magic-numbers
          .add(-this.HISTORY_PERIOD, "months")
          .startOf("day");
      }

      //Fetch history events of given date range.
      const fetchedEvents: Array<IEvent> = await this.fetchEvents(
        startDate!.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD")
      );

      //Do not wait for statistics to load
      timelineService()
        .getStatistics(startDate, moment("9999-12-31", "YYYY-MM-DD"))
        .catch();

      //Update start date for history event to calculate next batch of history event.
      if (fetchedEvents.length > 0) {
        //Append fetch event in store.
        timelineStore.events = fetchedEvents.concat(timelineStore.events);
        timelineStore.allEventsStartDate = moment(fetchedEvents[0].dutyStartTimestamp * this.MILLIS)
          .tz(getUserTimezone())
          .startOf("day");
      } else {
        timelineStore.allEventsStartDate = startDate;
      }

      //Update initial dates to show range in timeline
      const allEvents: Array<IEvent> = timelineStore.events;
      if (allEvents.length > 0) {
        const firstEvent: IEvent | undefined = first(allEvents);
        timelineStore.initialStartDate =
          firstEvent &&
          moment(firstEvent.dutyStartTimestamp * this.MILLIS)
            .tz(getUserTimezone())
            .startOf("day");

        const lastEvent: IEvent | undefined = last(allEvents);
        timelineStore.initialEndDate =
          lastEvent &&
          moment(lastEvent.dutyStartTimestamp * this.MILLIS)
            .tz(getUserTimezone())
            .startOf("day");
      } else {
        timelineStore.initialStartDate = startDate;
        timelineStore.initialEndDate = moment
          .tz(getUserTimezone())
          // tslint:disable-next-line: no-magic-numbers
          .add(this.HISTORY_PERIOD, "months")
          .startOf("day");
      }
    } catch (error) {
      throw error;
    }
  }
}

let service: EventService | null = null;

export const eventService = (): EventService => service ?? (service = new EventService());
