import React from "react";
import { useTranslation } from "react-i18next";
import CustomButtonGroup from "../../../components/inputs/CustomButtonGroup";
import SettingsRow from "../../../components/settings/SettingsRow";
import { IToggleButtonOption } from "../../../interfaces";

enum Day {
  MONDAY = "monday",
  SUNDAY = "sunday"
}

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const EventWeekStart = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const options: Array<IToggleButtonOption> = [
    { label: t("monday"), value: Day.MONDAY },
    { label: t("sunday"), value: Day.SUNDAY }
  ];

  return (
    <SettingsRow label={t("settings_events_start_week_label")} description={t("settings_events_start_week_desc")}>
      <CustomButtonGroup
        defaultValue={defaultValue ?? Day.MONDAY}
        onChange={(value: string) => onChange(value)}
        options={options}
      />
    </SettingsRow>
  );
};

export default EventWeekStart;
