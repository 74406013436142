import React from "react";
import { useTranslation } from "react-i18next";
import CustomButtonGroup from "../../../components/inputs/CustomButtonGroup";
import SettingsRow from "../../../components/settings/SettingsRow";
import { IToggleButtonOption } from "../../../interfaces";

enum Metrics {
  MILES = "MI",
  NAUTIC_MILES = "NMI",
  KILOMETERS = "KM"
}

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const DisplayDistance = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const options: Array<IToggleButtonOption> = [
    { label: t("miles"), value: Metrics.MILES },
    { label: t("nautic_miles"), value: Metrics.NAUTIC_MILES },
    { label: t("kilometers"), value: Metrics.KILOMETERS }
  ];

  return (
    <SettingsRow label={t("settings_display_distance_label")} description={t("settings_display_distance_desc")}>
      <CustomButtonGroup defaultValue={defaultValue ?? Metrics.MILES} onChange={onChange} options={options} />
    </SettingsRow>
  );
};

export default DisplayDistance;
