//tslint:disable: no-magic-numbers
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import backGroundImage from "../../../assets/images/background/sign-up-bg.jpg";
import rbLogoBlue from "../../../assets/images/logo/rb-logo-blue.png";
import { PageDescription, PageTitle } from "../../../enums/Page";
import PageHead from "../../PageHead";
import SignUpForm from "./SignUpForm";

export const SignUpPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PageHead title={PageTitle.SIGN_UP} description={PageDescription.SIGN_UP} />

      <div className="bg-image" style={{ backgroundImage: `url(${backGroundImage})` }}>
        <Row className="no-gutters justify-content-center bg-black-75">
          <Col md={8} className="hero-static d-flex align-items-center bg-white">
            <div className="p-3 w-100">
              <div className="mb-3 text-center">
                <Image src={rbLogoBlue} style={{ height: "150px", width: "auto" }} />
                <p className="text-uppercase font-w700 font-size-sm text-muted">{t("sign_up_title")}</p>
              </div>
              <Row className="no-gutters justify-content-center">
                <Col sm={10} xl={8}>
                  <SignUpForm {...props} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SignUpPage;
