import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: number | undefined;
  onChange: (value: number) => void;
}

const EmailMe = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  return (
    <SettingsRow label={t("settings_privacy_email_me_label")} description={t("settings_privacy_email_me_desc")}>
      <Form.Check
        type="switch"
        id="email-me"
        checked={defaultValue ? Boolean(defaultValue) : false}
        label=""
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(+event.target.checked)}
      />
    </SettingsRow>
  );
};

export default EmailMe;
