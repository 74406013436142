import React from "react";
import Button from "react-bootstrap/Button";

interface IProps {
  id?: string;
  label: string;
  loadingLabel: string;
  icon?: string;
  disabled?: any;
}

const SubmitButton = (props: IProps): JSX.Element => {
  const { id, label, loadingLabel, icon, disabled } = props;

  const getIcon = (): JSX.Element | null => {
    if (disabled) {
      return <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />;
    } else if (icon) {
      return <i className={"fa fa-fw fa-" + icon + " mr-2"} />;
    } else {
      return null;
    }
  };

  return (
    <Button
      className="btn-hero-lg submit-btn"
      id={id ? id : ""}
      type="submit"
      block
      disabled={disabled}
      variant="success">
      {getIcon()}
      {disabled ? loadingLabel : label}
    </Button>
  );
};

export default SubmitButton;
