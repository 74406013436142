import React from "react";
import { Form, ToggleButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => void;
}

const PublicProfile = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  return (
    <SettingsRow label={t("settings_privacy_profile_label")} description={t("settings_privacy_profile_desc")}>
      <ToggleButtonGroup type="radio" name="profile" defaultValue={defaultValue ?? "1"} onChange={onChange}>
        <Form.Check custom inline id="public" value={1} label={t("settings_privacy_profile_public")} type="radio" />
        <Form.Check custom inline id="private" value={0} label={t("settings_privacy_profile_private")} type="radio" />
      </ToggleButtonGroup>
    </SettingsRow>
  );
};

export default PublicProfile;
