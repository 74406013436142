import ReactGA from "react-ga";

enum Category {
  ROSTER_UPLOAD = "Roster Upload - Success",
  ROSTER_UPLOAD_FAIL = "Roster Upload - Failed"
}

export const trackSuccessfulRosterUpload = (type: string, airline?: string): void => {
  if (airline) {
    ReactGA.event({
      category: Category.ROSTER_UPLOAD,
      action: `${airline} - ${type}`
    });
  }
};

export const trackFailedRosterUpload = (type: string, airline?: string): void => {
  if (airline) {
    ReactGA.event({
      category: Category.ROSTER_UPLOAD_FAIL,
      action: `${airline} - ${type}`
    });
  }
};
