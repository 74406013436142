import isEmpty from "lodash/isEmpty";
import { action, computed, observable } from "mobx";
import { RBApp } from "../../enums/RBApp";
import { IToken } from "../../interfaces";

export class AuthStore {
  private readonly _storageToken: string = "token";
  private readonly _storageApp: string = "app";

  private _app: RBApp = RBApp.ROSTERBUSTER;
  @observable private _token: IToken = {};

  constructor() {
    this.readLocalStorage();
  }

  @action.bound
  public setTokenForRB(token: IToken): void {
    this.writeLocalStorage(token);

    this._app = RBApp.ROSTERBUSTER;

    this._token = token;
  }

  @action.bound
  public setTokenForLB(token: IToken): void {
    this._app = RBApp.LOGBOOK;

    this._token = token;
  }

  public get token(): IToken {
    return this._token;
  }

  public setTempTokenForRB(token: string): void {
    this._app = RBApp.ROSTERBUSTER;
    this._token.access_token = token;
  }

  public setTempTokenForLB(token: string): void {
    this._app = RBApp.LOGBOOK;
    this._token.access_token = token;
  }

  public get app(): RBApp {
    return this._app;
  }

  @computed
  public get authenticated(): boolean {
    return !isEmpty(this._token.access_token) && !isEmpty(this._token.refresh_token);
  }

  @action.bound
  private readLocalStorage(): void {
    const localStorageApp: string | null = localStorage.getItem(this._storageApp);
    const localStorageToken: string | null = localStorage.getItem(this._storageToken);

    if (localStorageApp) {
      this._app = Number(localStorageApp);
    }

    if (localStorageToken) {
      this._token = JSON.parse(localStorageToken);
    }
  }

  private writeLocalStorage(token: IToken): void {
    localStorage.setItem(this._storageApp, JSON.stringify(RBApp.ROSTERBUSTER));
    localStorage.setItem(this._storageToken, JSON.stringify(token));
  }

  public clear(): void {
    localStorage.clear();
    this._app = RBApp.ROSTERBUSTER;
    this._token = {};
  }
}

let store: AuthStore | null = null;

export const createAuthStore = (): AuthStore => store ?? (store = new AuthStore());
