import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { EventType } from "../../../enums/roster/EventType";
import { IEvent } from "../../../interfaces";
import { getEventTime, getUserTimezone } from "../../../utils/DateTimeUtils";

interface IProps {
  event: IEvent;
}

const EventRow = (props: IProps): JSX.Element => {
  const { event } = props;

  const { t } = useTranslation();
  const isOldEvent: boolean = moment(event.dutyEndTimestamp * 1000)
    .tz(getUserTimezone())
    .isBefore(moment().tz(getUserTimezone()));
  const oldEventStyle: string = isOldEvent ? "old-event" : "";

  const eventTitle = (): string => {
    try {
      switch (event.dutyType) {
        case EventType.DAY_OFF:
          return event.dutyTypeDescription;

        case EventType.UNKNOWN:
        case EventType.LAYOVER:
        case EventType.INFO:
        case EventType.HOTEL:
          if (event.dutyNonFlyCode === "HTL") {
            if (event.dutyAdditional) {
              return `Layover at ${event.arrival?.city} (${event.arrival?.IATA})`;
            } else {
              return "Layover";
            }
          }
          return event.dutyTitle;

        default:
          return event.dutyTitle;
      }
    } catch (error) {
      return event.dutyTitle;
    }
  };

  const eventSubtitle = (): string => {
    try {
      switch (event.dutyType) {
        case EventType.DAY_OFF:
          return event.dutyNonFlyCode;

        case EventType.STANDBY:
          return `${event.dutyNonFlyCode} (${event.departure.IATA})`;

        case EventType.UNKNOWN:
        case EventType.LAYOVER:
        case EventType.INFO:
        case EventType.HOTEL:
          if (event.dutyNonFlyCode === "HTL") {
            if (event.dutyAdditional) {
              return event.dutyAdditional;
            } else {
              return `${event.arrival?.city} (${event.arrival?.IATA})`;
            }
          }
          return event.dutySubtitle;

        default:
          return event.dutySubtitle;
      }
    } catch (error) {
      return event.dutySubtitle;
    }
  };

  return (
    <tr style={{ height: "60px" }}>
      <td className="event-icon">
        <i className={`${oldEventStyle} fa fa-` + event.dutyTypeIconName} style={{ margin: "auto" }} />
      </td>
      <td>
        <span className={`${oldEventStyle} event-title`}>{eventTitle()}</span>
        <br />
        <span className={`${oldEventStyle} event-subtitle`}>{eventSubtitle()}</span>
      </td>
      <td className={`${oldEventStyle} event-time`}>
        {event.dutyIsAllDay
          ? t("event_all_day")
          : getEventTime(event.dutyStartTimestamp) + " - " + getEventTime(event.dutyEndTimestamp)}
      </td>
    </tr>
  );
};

export default EventRow;
