import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IEvent } from "../../../interfaces";
import i18n from "../../../localization/i18n";
import { eventService } from "../../../services/event/EventsService";
import { createTimelineStore } from "../../../stores/timeline/TimelineStore";
import { getUserTimezone } from "../../../utils/DateTimeUtils";
import DateRow from "./DateRow";
import EventRow from "./EventRow";

interface IProps {
  events: Array<IEvent>;
}

const showHistory = (): void => {
  eventService()
    .showHistory()
    .catch();
};

const createEventList = (events: Array<IEvent>): JSX.Element => {
  return (
    <Table borderless size="sm" style={{ margin: 0 }}>
      <tbody id="event-list-body">{getEvents(events)}</tbody>
    </Table>
  );
};

/**
 * Creates a list of date sections and event rows.
 *
 * @param events The user's events
 */
const getEvents = (events: Array<IEvent>): Array<JSX.Element> => {
  const millis: number = 1000;

  let currentEventDate: moment.Moment | null = null;

  const elements: Array<JSX.Element> = new Array<JSX.Element>();

  events.forEach((event: IEvent) => {
    const eventTimezone: string = event.dutyIsAllDay ? "UTC" : getUserTimezone();

    const eventDate: moment.Moment = moment(event.dutyStartTimestamp * millis).tz(eventTimezone);

    // Check if the event is on the same day or not.
    if (eventDate.format("DD-MM-YYYY") !== currentEventDate?.format("DD-MM-YYYY")) {
      // Add a new Date row.
      elements.push(<DateRow key={eventDate.valueOf()} date={eventDate} isFirst={currentEventDate === null} />);

      // Save the date as currentEventDate to compare with in next iteration.
      currentEventDate = eventDate;
    }

    // Add a new Event row.
    elements.push(<EventRow key={event.dutyId} event={event} />);
  });

  return elements;
};

/**
 * Show empty event list
 */
const showEmpty = (): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <div className="text-center">
        <i className="far fa-calendar-times text-muted mb-3" style={{ fontSize: "2rem" }} />
        <p className="text-center text-muted">
          {i18n.t("events_empty1")}
          <br />
          {i18n.t("events_empty2")}
        </p>
      </div>
    </div>
  );
};

const EventList = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { events } = props;

  // Scroll to the current day after loading the event list.
  // Do not change these id's.
  useEffect(() => {
    const table: HTMLElement | null = document.getElementById("event-list");

    const today: HTMLElement | null = document.getElementById("today");

    if (table && today) {
      const topPos: number = today.offsetTop;

      table.scrollTop = topPos + 1;
    } else if (table) {
      // Set scroll position to bottom
      table.scrollTop = table.scrollHeight;
    }
  }, []);

  return (
    <Card className="block" id="event-list">
      {createTimelineStore().showAllEvents && (
        <Card.Header>
          <button
            className="btn btn-primary button-focus-color btn-block"
            data-toggle="click-ripple"
            onClick={showHistory}>
            {t("show_history")}
          </button>
        </Card.Header>
      )}
      {events.length !== 0 ? createEventList(events) : showEmpty()}
    </Card>
  );
};

export default EventList;
