import React from "react";

interface IProps {
  children?: React.ReactNode;
}

const MainContainer = (props: IProps): JSX.Element => {
  return <main id="main-container">{props.children}</main>;
};

export default MainContainer;
