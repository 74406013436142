import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import rbLogoBlue from "../../../assets/images//logo/rb-logo-blue.png";
import backGroundImage from "../../../assets/images/background/sign-in-bg.jpg";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import GenericSignInForm from "../../../components/generics/sign-in/GenericSignInForm";
import GenericSignInPage from "../../../components/generics/sign-in/GenericSignInPage";
import { blockedEmailDomains } from "../../../constants/BlockedEmailDomains";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";
import { ISignInCredentials } from "../../../interfaces";
import { signInService } from "../../../services/auth/SignInService";
import PageHead from "../../PageHead";

export const SignInPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const handleSignIn = async (credentials: ISignInCredentials): Promise<void> => {
    try {
      const domain: string | undefined = credentials.email.split("@").pop();
      if (domain && blockedEmailDomains.includes(domain)) {
        throw `It is not possible to login with @${domain}, since we have an enterprise agreement with your airline. Use our RosterBuster mobile application to login with ENTERPRISE option.`;
      }
      await signInService().signIn(credentials);

      handleSuccessfulSignIn();
    } catch (error) {
      throw error;
    }
  };

  const handleSuccessfulSignIn = (): void => {
    const { from } = (props.location.state as any) || { from: { pathname: RouteURL.HOME } };
    History.replace(from);
  };

  const handleSignUp = (): void => History.push(RouteURL.SIGN_UP);

  const handleForgotPassword = (): void => History.push(RouteURL.FORGOT_PASSWORD);

  return (
    <>
      <PageHead title={PageTitle.SIGN_IN} description={PageDescription.SIGN_IN} />

      <GenericSignInPage
        appName={t("app_name")}
        appSubtitle={t("app_subtitle")}
        logo={rbLogoBlue}
        backgroundImage={backGroundImage}>
        <GenericSignInForm onSubmit={handleSignIn} />

        <p className="mt-3 mb-0 d-lg-flex justify-content-lg-between">
          <SecondaryButton label={t("forgot_password")} icon="lock" onClick={handleForgotPassword}></SecondaryButton>

          <SecondaryButton label={t("sign_up")} icon="user-plus" onClick={handleSignUp}></SecondaryButton>
        </p>
      </GenericSignInPage>
    </>
  );
};

export default SignInPage;
