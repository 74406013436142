import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { IPreferences, IUserData } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import DisplayDistance from "./DisplayDistance";
import DisplayTemperature from "./DisplayTemperature";
import DisplayTime from "./DisplayTime";

const DisplaySettings: () => JSX.Element = observer(() => {
  const userData: IUserData = createUserStore().userData!;

  const preference: IPreferences = userData.preferences;

  const updateUserPref = async (pref: Preference, value: string): Promise<void> => {
    try {
      await settingsService().updatePreference(pref, value);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleTime = async (value: string): Promise<void> => {
    preference.profile_fridge_timezone = value; // Update Store
    await updateUserPref(Preference.TIMEZONE, value); // Update Server
  };

  const handleDistance = async (value: string): Promise<void> => {
    preference.distance = value;
    await updateUserPref(Preference.DISTANCE, value);
  };

  const handleTemperature = async (value: string): Promise<void> => {
    preference.temperature = value;
    await updateUserPref(Preference.TEMPERATURE, value);
  };

  return (
    <ComponentBoundary componentName="Display settings">
      <PageHead title={PageTitle.SETTINGS_DISPLAY} description={PageDescription.SETTINGS_DISPLAY} />

      <DisplayTime defaultValue={preference?.profile_fridge_timezone} onChange={handleTime} />

      <DisplayDistance defaultValue={preference?.distance} onChange={handleDistance} />

      <DisplayTemperature defaultValue={preference?.temperature} onChange={handleTemperature} />
    </ComponentBoundary>
  );
});

export default DisplaySettings;
