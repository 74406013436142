import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import queryString from "query-string";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import SubmitButton from "../../../components/buttons/SubmitButton";
import TextField from "../../../components/inputs/TextField";
import { blockedEmailDomains } from "../../../constants/BlockedEmailDomains";
import { RouteURL } from "../../../enums/routing/Route";
import History from "../../../History";
import { ISignUpUser } from "../../../interfaces";
import { signUpService } from "../../../services/auth/SignUpService";
import { showErrorMessage, showSuccessMessage } from "../../../stores/error/SnackbarStore";
import { signUpSchema } from "../../../utils/Validation";

const SignUpForm = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const getPromoCodeFromUrl = (): string | undefined => {
    const { promocode }: any = queryString.parse(props.location.search);
    return promocode;
  };

  const handleSignUp = async (user: ISignUpUser): Promise<void> => {
    try {
      const domain: string | undefined = user.email.split("@").pop();
      if (domain && blockedEmailDomains.includes(domain)) {
        throw `It is not possible to create accounts with @${domain}, since we have an enterprise agreement with your airline. Use our RosterBuster mobile application to login with ENTERPRISE option.`;
      }
      await signUpService().signUp(user);

      const { from } = (History.location.state as any) || { from: { pathname: RouteURL.HOME } };

      History.replace(from);
    } catch (error) {
      throw error;
    }
  };

  const handleSignIn: () => void = () => History.push(RouteURL.SIGN_IN);

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: "",
        promocode: getPromoCodeFromUrl() || ""
      }}
      validationSchema={signUpSchema}
      onSubmit={async (user: ISignUpUser, actions: FormikHelpers<any>) => {
        try {
          actions.setSubmitting(true);

          await handleSignUp(user);

          if (user.promocode) {
            showSuccessMessage(t("promocode_apply_successfully"));
          }

          showSuccessMessage(t("verification_mail", { email: user.email }));
        } catch (error) {
          showErrorMessage(error);

          actions.setSubmitting(false);
        }
      }}>
      {(props: FormikProps<ISignUpUser>) => (
        <FormikForm>
          <div className="py-3">
            <Form.Row>
              <Form.Group as={Col} md="6">
                <TextField placeholder={t("first_name")} type="text" name="firstname" />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <TextField placeholder={t("last_name")} type="text" name="lastname" />
              </Form.Group>
            </Form.Row>

            <Form.Group>
              <TextField placeholder={t("email")} type="email" name="email" />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} md="6">
                <TextField placeholder={t("password")} type="password" name="password" />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <TextField placeholder={t("confirm_password")} type="password" name="password_confirmation" />
              </Form.Group>
            </Form.Row>

            <Form.Group>
              <TextField placeholder={t("promo_code")} type="text" name="promocode" />
            </Form.Group>
          </div>

          <Form.Group>
            <SubmitButton
              label={t("sign_up_title")}
              loadingLabel={t("sign_up_btn_loading_label")}
              icon="user-plus"
              disabled={props.isSubmitting}></SubmitButton>

            <p className="mt-3 mb-0 d-lg-flex">
              <SecondaryButton label={t("sign_in")} icon="sign-in-alt" onClick={handleSignIn}></SecondaryButton>
            </p>
          </Form.Group>
        </FormikForm>
      )}
    </Formik>
  );
};

export default SignUpForm;
