//tslint:disable: no-magic-numbers
import React from "react";
import Card from "react-bootstrap/Card";

interface IProps {
  title: string;
  icon?: string;
  count: number | string;
}

const StatsCard = (props: IProps): JSX.Element => {
  const { title, count } = props;

  return (
    <Card className="block stats-card animated fadeIn">
      <div className="dashboard-stats-content">
        <div style={{ textAlign: "center" }}>
          <span
            className="stats-card-content rb-color-primary"
            style={{
              fontSize: "1.25rem",
              fontWeight: "bold"
            }}>
            {title}
          </span>
          <br />
          <span className="stats-card-content stats-card-content-color" style={{ fontSize: "1.25rem" }}>
            {count}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default StatsCard;
