import React, { Component } from "react";

interface IState {
  hasError: boolean;
}

interface IProps {
  componentName: string;
}

class ComponentBoundary extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): any {
    return { hasError: true };
  }

  render(): any {
    if (this.state.hasError) {
      return (
        <div className="component-boundary">
          <div style={{ textAlign: "center" }}>
            <i className="fa fa-times-circle text-danger" style={{ fontSize: "3rem", marginBottom: "0.75rem" }} />
            <p>Failed loading {this.props.componentName}.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ComponentBoundary;
