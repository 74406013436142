import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { getUserTimezone } from "../../../utils/DateTimeUtils";

interface IProps {
  date: moment.Moment;
  isFirst?: boolean;
}

const colSpan: number = 3;

const DateRow = (props: IProps): JSX.Element => {
  const { date, isFirst } = props;

  const { t } = useTranslation();

  const today: moment.Moment = moment()
    .tz(getUserTimezone())
    .startOf("day");

  const getDate = (): string => {
    const formattedDate: string = date.format("ddd D MMMM YYYY");

    return date.startOf().isSame(today, "day") ? `${formattedDate} (${t("today")})` : formattedDate;
  };

  // Set the id for the first date that's today or later, so we can scroll to it.
  // Do not change this id.
  const id: string | undefined = date.startOf().isSameOrAfter(today, "day") ? "today" : undefined;

  return (
    <tr className="event-date-row" id={id} style={{ borderTop: isFirst ? "none" : "" }}>
      <td className="event-date" colSpan={colSpan}>
        {getDate()}
      </td>
    </tr>
  );
};

export default DateRow;
