import React from "react";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import appStoreBadge from "../../../assets/images/store-badge/app-store-badge.svg";
import { Url } from "../../../enums/Url";

const AppStoreButton = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="d-inline-block m-2">
      <a href={Url.RB_APP_STORE} target="_blank" rel="noopener noreferrer">
        <Image src={appStoreBadge} alt={t("app_store_btn")} style={{ height: "71px", width: "240px" }} />
      </a>
    </div>
  );
};

export default AppStoreButton;
