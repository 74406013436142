import { action } from "mobx";
import { RBApp } from "../../../enums/RBApp";
import { createUserStore } from "../../../stores/user/UserStore";
import { AbstractScopedRequestService } from "./AbstractScopedRequestService";

export class ScopedRequestServiceLB extends AbstractScopedRequestService {
  protected async authenticate(tempToken: string): Promise<void> {
    try {
      // Check if already authenticated.
      if (this.authStore.authenticated && this.authStore.app === RBApp.LOGBOOK) {
        return;
      }

      if (!tempToken) {
        throw new Error(this.AUTH_ERROR);
      }

      this.setAuthStore(tempToken);
    } catch (error) {
      throw error;
    }
  }

  @action.bound
  private setAuthStore(tempToken: string): void {
    // Clear UserStore (may be necessary if a RB user is stored).
    createUserStore().clear();

    // Clear AuthStore from saved RosterBuster values.
    this.authStore.clear();

    this.authStore.setTempTokenForLB(tempToken);
  }
}

let service: ScopedRequestServiceLB | null = null;

export const scopedRequestServiceLB = (): ScopedRequestServiceLB => service ?? (service = new ScopedRequestServiceLB());
