import { ErrorMessage, Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import SettingsRow from "../../../../components/settings/SettingsRow";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => Promise<void>;
}

const SafelogKey = (props: IProps): JSX.Element => {
  const { defaultValue, onChange } = props;

  const { t } = useTranslation();

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    key: Yup.string().required(t("preferences.safelog.key.required"))
  });

  return (
    <SettingsRow label={t("preferences.safelog.key.label")} description={t("preferences.safelog.key.description")}>
      <Formik
        initialValues={{
          key: defaultValue ?? ""
        }}
        validationSchema={validationSchema}
        onSubmit={async (values: { key: string }, actions: FormikHelpers<any>) => {
          try {
            actions.setSubmitting(true);
            await onChange(values.key);
          } catch (error) {
            showErrorMessage(error);
          } finally {
            actions.setSubmitting(false);
          }
        }}>
        {(props: FormikProps<any>) => {
          const { values, handleChange, handleBlur, isSubmitting } = props;

          return (
            <FormikForm>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <Form.Control name="key" type="text" value={values.key} onChange={handleChange} onBlur={handleBlur} />
                  <ErrorMessage className="input-feedback" name="key" component="div" />
                </div>
                <PrimaryButton
                  label={t("save_btn")}
                  loadingLabel={t("save_btn_loading")}
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </SettingsRow>
  );
};

export default SafelogKey;
