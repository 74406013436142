import React from "react";
import Button from "react-bootstrap/Button";

interface IProps {
  id?: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  icon?: string;
  size?: "sm" | "lg";
  label: string;
  loadingLabel?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

const PrimaryButton = (props: IProps): JSX.Element => {
  const { id, className, icon, label, loadingLabel, disabled, type, style, onClick, size } = props;

  const getIcon = (): JSX.Element | null => {
    if (disabled) {
      return <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />;
    } else if (icon) {
      return <i className={"fa fa-fw fa-" + icon + " mr-2"} />;
    }
    return null;
  };

  return (
    <Button
      className={"primary-btn" + (className ? " " + className : "")}
      id={id}
      style={style}
      onClick={onClick}
      type={type}
      size={size}>
      {getIcon()}
      {disabled ? loadingLabel : label}
    </Button>
  );
};

export default PrimaryButton;
