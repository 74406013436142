import { observer } from "mobx-react";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteURL } from "../../../enums/routing/Route";
import { Url } from "../../../enums/Url";
import History from "../../../History";
import { IUserData } from "../../../interfaces";
import { signOut } from "../../../services/auth/SignOutService";
import { createUserStore } from "../../../stores/user/UserStore";
import UserAvatar, { AvatarSize } from "../../user/UserAvatar";

const UserMenu: () => JSX.Element = observer(() => {
  const { t } = useTranslation();

  const userData: IUserData | null = createUserStore().userData;
  const avatar: string = userData ? userData.user.avatar_url : "";
  const firstName: string = userData ? userData.user.firstname : "";
  const lastName: string = userData ? userData.user.lastname : "";

  const goToSettings = (): void => History.push(RouteURL.SETTINGS);

  const goToContactUs = (): void => {
    window.open(Url.CONTACT_US, "_blank");
  };

  const handleSignOut = (): void => signOut();

  return (
    <DropdownButton
      title={
        <>
          <UserAvatar url={avatar} size={AvatarSize.XS} />
          <span className="d-none d-sm-inline" style={{ margin: "0rem 0.5rem" }}>
            {firstName + " " + lastName}
          </span>
        </>
      }
      id="user-drop-down"
      alignRight>
      <Dropdown.Item eventKey="1" onClick={goToSettings}>
        <i className="fa fa-cog mr-2" />
        {t("settings")}
      </Dropdown.Item>
      <Dropdown.Item eventKey="2" onClick={goToContactUs}>
        <i className="fa fa-envelope mr-2" />
        {t("contact_us")}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="3" onClick={handleSignOut}>
        <i className="fa fa-sign-out-alt mr-2" />
        {t("sign_out")}
      </Dropdown.Item>
    </DropdownButton>
  );
});

export default UserMenu;
