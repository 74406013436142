import React from "react";
import { Route } from "react-router-dom";
import { RouteURL } from "../enums/routing/Route";
import LogbookPublicRoute from "../logbook/LogbookPublicRoute";
import LogbookEmailVerify from "../logbook/pages/auth/LogbookEmailVerify";
import LogbookCampaignContainer from "../logbook/pages/campaign/LogbookCampaignContainer";
import LogbookUnsubscribe from "../logbook/pages/campaign/LogbookUnsubscribe";
import LogbookChangePasswordPage from "../logbook/pages/email-campaigns/change-password/ChangePasswordPage";
import EmailVerification from "../pages/auth/email-verification/EmailVerification";
import ThirdPartyAuthorizePage from "../pages/auth/thirdparty/ThirdpartyAuthorizePage";
import AmbassadorOptOutPage from "../pages/email-campaigns/AmbassadorOptOut";
import CampaignContainer from "../pages/email-campaigns/CampaignContainer";
import ChangePasswordPage from "../pages/email-campaigns/change-password/ChangePasswordPage";
import CompleteProfileCampaignPage from "../pages/email-campaigns/CompleteProfileCampaignPage";
import UnsubscribePage from "../pages/email-campaigns/unsubscribe/UnsubscribePage";
import ErrorPage from "../pages/error/ErrorPage";
import PublicRouteRB from "./custom-routes/PublicRouteRB";

const routes: Array<JSX.Element> = [
  <Route key={RouteURL.UNAUTHORIZED} path={RouteURL.UNAUTHORIZED} render={(props: any) => <ErrorPage {...props} />} />,
  <PublicRouteRB
    key={RouteURL.CHANGE_PASSWORD}
    exact
    path={RouteURL.CHANGE_PASSWORD}
    render={(props: any) => <CampaignContainer {...props} component={ChangePasswordPage} />}
  />,
  <PublicRouteRB
    key={RouteURL.CAMPAIGN_UNSUBSCRIBE}
    exact
    path={RouteURL.CAMPAIGN_UNSUBSCRIBE}
    render={(props: any) => <CampaignContainer {...props} component={UnsubscribePage} />}
  />,
  <PublicRouteRB
    key={RouteURL.CAMPAIGN_COMPLETE_PROFILE}
    exact
    path={RouteURL.CAMPAIGN_COMPLETE_PROFILE}
    render={(props: any) => <CampaignContainer {...props} component={CompleteProfileCampaignPage} />}
  />,
  <PublicRouteRB
    key={RouteURL.AMBASSADOR_OPT_OUT}
    exact
    path={RouteURL.AMBASSADOR_OPT_OUT}
    render={(props: any) => <CampaignContainer {...props} component={AmbassadorOptOutPage} />}
  />,
  <LogbookPublicRoute
    key={RouteURL.LOGBOOK_CAMPAIGN_UNSUBSCRIBE}
    exact
    path={RouteURL.LOGBOOK_CAMPAIGN_UNSUBSCRIBE}
    render={(props: any) => <LogbookCampaignContainer {...props} component={LogbookUnsubscribe} />}
  />,
  <LogbookPublicRoute
    key={RouteURL.LOGBOOK_EMAIL_VERIFY}
    exact
    path={RouteURL.LOGBOOK_EMAIL_VERIFY}
    render={(props: any) => <LogbookCampaignContainer {...props} component={LogbookEmailVerify} />}
  />,
  <LogbookPublicRoute
    key={RouteURL.LOGBOOK_CHANGE_PASSWORD}
    exact
    path={RouteURL.LOGBOOK_CHANGE_PASSWORD}
    render={(props: any) => <LogbookCampaignContainer {...props} component={LogbookChangePasswordPage} />}
  />,
  <PublicRouteRB
    key={RouteURL.THIRD_PARTY_AUTHORIZE}
    exact
    path={RouteURL.THIRD_PARTY_AUTHORIZE}
    render={(props: any) => <CampaignContainer {...props} component={ThirdPartyAuthorizePage} />}
  />,
  <PublicRouteRB
    key={RouteURL.EMAIL_VERIFY}
    exact
    path={RouteURL.EMAIL_VERIFY}
    render={(props: any) => <CampaignContainer {...props} component={EmailVerification} />}
  />
];

export const SharedPublicRoutes = (): Array<JSX.Element> => routes.map((route: JSX.Element) => route);
