export enum Preference {
  CITY_DEALS = "citydeals",
  DATE_FORMAT = "dateformat",
  CALENDAR_WEEK_DAY = "calendar_weekday",
  TIMEZONE = "profile_fridge_timezone",
  DISTANCE = "distance",
  TEMPERATURE = "temperature",
  REPORT_GENERATE = "report_minutes",
  DUTY_FILTER = "dutycode_filter",
  DELETE_ROSTER = "auto_delete_roster",
  ROSTERNAME = "rostername",
  OUTPUT_ICAL_AGGREGATE_FLT = "output_ical_aggregate_flights",
  OUTPUT_ICAL_SUMMARY_FLT = "output_ical_summary_flt",
  OUTPUT_ICAL_LOCATION_FLT = "output_ical_location_flt",
  OUTPUT_ICAL_DESCRIPTION_FLT = "output_ical_description_flt",
  OUTPUT_ICAL_SUMMARY_REP = "output_ical_summary_rep",
  OUTPUT_ICAL_LOCATION_REP = "output_ical_location_rep",
  OUTPUT_ICAL_DESCRIPTION_REP = "output_ical_description_rep",
  REPORT_ALARM_IATA = "report_add_alarm_iata",
  REPORT_ALARM_TIME = "report_add_alarm_seconds",
  EXPORT_TODAY_ONWARDS = "export_todayonwards",
  EXCLUDE_DEADHEAD = "exclude_tvf",
  EXCLUDE_STANDBY = "exclude_sby",
  EXCLUDE_DAY_OFF = "exclude_do",
  EXCLUDE_REPORT = "exclude_rep",
  EXCLUDE_DEBRIEF = "exclude_deb",
  EXCLUDE_SIMULATOR = "exclude_sim",
  EXCLUDE_LAYOVER = "exclude_lay",
  RB_LOGOOK_EXPORT = "rb_logbook_export",
  RB_LOGOOK__CREW_NAMES = "rb_logbook_crew_names",
  SAFELOG_KEY = "output_safelog_key"
}
