import React from "react";
import { OverlayTrigger, Tooltip as Tip } from "react-bootstrap";

interface IProps {
  id: string;
  message: string;
  placement:
    | "left"
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left-start";
}

const Tooltip = (props: IProps): JSX.Element => {
  return (
    <OverlayTrigger
      placement={props.placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tip id={props.id}>{props.message}</Tip>}>
      <i className="far fa-question-circle rb-color-primary" style={{ fontSize: "1.2rem" }} />
    </OverlayTrigger>
  );
};

export default Tooltip;
