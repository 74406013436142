import { AxiosResponse } from "axios";
import { action } from "mobx";
import { ISignInCredentials, IToken } from "../../interfaces";
import { IData } from "../../interfaces";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { signIn } from "../api/AuthApiService";
import { createFirebaseAuthService } from "../firebase/FirebaseAuthService";
import { userService } from "../user/UserService";

export class SignInService {
  /**
   * Sign a user in
   *
   * @param {credentials} -> contains user's email and password
   */
  public async signIn(credentials: ISignInCredentials): Promise<void> {
    try {
      const response: AxiosResponse<IData<IToken>> = await signIn(credentials);

      await this.handleSuccessfulResponse(response.data.data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Handles a successful sign in response
   *
   * @param {AxiosResponse} response
   */
  @action.bound
  private async handleSuccessfulResponse(token: IToken): Promise<void> {
    createAuthStore().setTokenForRB(token);
    createFirebaseAuthService()
      .signIn()
      .catch();
    await userService().getUser();
  }
}

let service: SignInService | null = null;

export const signInService = (): SignInService => service ?? (service = new SignInService());
