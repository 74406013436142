import React from "react";
import { useTranslation } from "react-i18next";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { RouteURL } from "../../../../enums/routing/Route";
import History from "../../../../History";
import { IUploadType } from "../../../../interfaces";
import PageHead from "../../../PageHead";
import UploadHeader from "../UploadHeader";
import UploadModal from "../UploadModal";
import UploadFileButton from "./UploadFileButton";
import UploadPortalButton from "./UploadPortalButton";
import UploadTextButton from "./UploadTextButton";

interface IProps {
  uploadType: IUploadType;
}

const UploadMenu = (props: IProps): JSX.Element => {
  const { uploadType } = props;

  const { t } = useTranslation();

  const handleWindowChange = (url: string): void => History.push(url);

  return (
    <>
      <PageHead title={PageTitle.UPLOAD_ROSTER} description={PageDescription.UPLOAD_ROSTER} />

      <UploadModal>
        <UploadHeader title={t("upload_roster_title")}>
          <hr style={{ width: "50%", borderWidth: "2px", marginBottom: "3rem" }} />
        </UploadHeader>

        <div style={{ width: "100%" }}>
          <UploadPortalButton
            fetchBox={uploadType.fetchbox ?? 0}
            onClick={() => handleWindowChange(RouteURL.UPLOAD_PORTAL)}
          />
          <UploadFileButton
            uploadBox={uploadType.uploadbox ?? 0}
            onClick={() => handleWindowChange(RouteURL.UPLOAD_FILE)}
          />
          <UploadTextButton textBox={uploadType.textbox ?? 1} onClick={() => handleWindowChange(RouteURL.UPLOAD_SRC)} />
        </div>
      </UploadModal>
    </>
  );
};

export default UploadMenu;
