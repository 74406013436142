import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface IProps {
  primary?: boolean;
}

const PageLoader = (props: IProps): JSX.Element => {
  const { primary } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        margin: "auto"
      }}>
      <Spinner
        animation="grow"
        variant="light"
        style={{ width: "10rem", height: "10rem", backgroundColor: primary ? "#049fd9" : undefined }}
      />
    </div>
  );
};

export default PageLoader;
