import i18next from "i18next";
import moment from "moment";
import React, { Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IExportEventRequest } from "../../interfaces";
import { exportEventService } from "../../services/export/ExportEventService";
import { showErrorMessage, showSuccessMessage } from "../../stores/error/SnackbarStore";
import { createTimelineStore } from "../../stores/timeline/TimelineStore";

interface IProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

enum ExportKey {
  RB_LOGBOOK = "RBLogbook",
  CREWALERT = "CrewAlert",
  SAFELOG = "SafeLog",
  LOGTENPROX = "LogTenProX",
  CSV = "csv",
  ICS = "ICS",
  PDF = "PDF"
}

interface IExportType {
  name: string;
  key: string;
}

const exportTypes: Array<IExportType> = [
  { name: "RB Logbook", key: ExportKey.RB_LOGBOOK },
  { name: ExportKey.CREWALERT, key: ExportKey.CREWALERT },
  { name: ExportKey.SAFELOG, key: ExportKey.SAFELOG },
  { name: "LogtenPro X", key: ExportKey.LOGTENPROX },
  { name: "CSV", key: ExportKey.CSV },
  { name: ExportKey.ICS, key: ExportKey.ICS },
  { name: i18next.t("event_export.fridge_pdf"), key: ExportKey.PDF }
];

const ExportEvents = (props: IProps): JSX.Element => {
  const { startDate, endDate } = props;

  const { t } = useTranslation();
  const showAllEvents: boolean = createTimelineStore().showAllEvents;

  const exportEvents = async (provider: string): Promise<void> => {
    const request: IExportEventRequest = {
      provider: provider,
      start: showAllEvents ? "1950-01-01" : startDate.format("YYYY-MM-DD"),
      end: showAllEvents ? "9999-12-30" : endDate.format("YYYY-MM-DD")
    };

    try {
      showSuccessMessage(t("event_export.prepare_message"));

      switch (provider) {
        case ExportKey.LOGTENPROX:
          await exportEventService().exportToLogTen(request);
          break;

        case ExportKey.CREWALERT:
          await exportEventService().exportToCrewAlert(request);
          break;

        case ExportKey.CSV:
          await exportEventService().exportCSV(request);
          break;

        case ExportKey.ICS:
          await exportEventService().exportICS(request);
          break;

        case ExportKey.PDF:
          await exportEventService().exportPDF(request);
          break;

        default:
          await exportEventService().export(request);
          break;
      }

      showSuccessMessage(t("event_export.successful"));
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id="event-date-dropdown" style={{ borderBottomRightRadius: 0 }}>
        {t("event_export.to")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {exportTypes.map((exportType: IExportType) => {
          return (
            <Fragment key={exportType.key}>
              <Dropdown.Item eventKey={exportType.name} onClick={() => exportEvents(exportType.key)}>
                {exportType.name}
              </Dropdown.Item>
              <Dropdown.Divider />
            </Fragment>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExportEvents;
