import React from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import i18n from "../../../../localization/i18n";

interface IProps {
  fetchBox: number;
  onClick: () => void;
}

const style: React.CSSProperties = {
  display: "block",
  width: "100%",
  margin: "3rem 0",
  fontSize: "1rem",
  borderRadius: "50px"
};

const UploadPortalButton = (props: IProps): JSX.Element | null => {
  if (props.fetchBox !== 1) {
    return null;
  }

  return (
    <PrimaryButton
      className="btn-hero-lg"
      icon="globe"
      label={i18n.t("upload_portal_btn")}
      onClick={props.onClick}
      style={style}
    />
  );
};

export default UploadPortalButton;
