//tslint:disable: no-magic-numbers
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import FlightMap from "../../../../components/map/FlightMap";
import StatsCard from "../../../../components/statistics/StatsCard";
import { RouteURL } from "../../../../enums/routing/Route";
import History from "../../../../History";
import { IRoute, IStats } from "../../../../interfaces";
import { uploadRosterService } from "../../../../services/roster/UploadRosterService";
import { createUploadRosterStore, UploadRosterStore } from "../../../../stores/roster/UploadRosterStore";
import UploadHeader from "../UploadHeader";
import UploadModal from "../UploadModal";

const UploadSuccessful = (): JSX.Element => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    History.replace(RouteURL.HOME);
    window.location.reload();
  };

  const uploadRosterStore: UploadRosterStore = createUploadRosterStore();

  const stats: IStats | null = uploadRosterStore.stats;

  let routes: Array<IRoute> | null = null;

  if (uploadRosterStore.routes) {
    routes = uploadRosterService().getRoutes(uploadRosterStore.routes);
  }

  return (
    <UploadModal size="lg">
      <div style={{ textAlign: "center" }}>
        <UploadHeader title={t("upload_successful_title")}>
          <hr style={{ width: "50%", borderWidth: "2px", marginBottom: "3rem" }} />
        </UploadHeader>

        <Row>
          <Col xs={6} md={3}>
            <StatsCard title={t("stats.flights")} count={stats ? stats.flights : 0} />
          </Col>

          <Col xs={6} md={3}>
            <StatsCard title={t("stats.airports")} count={stats ? stats.airports : 0} />
          </Col>

          <Col xs={6} md={3}>
            <StatsCard title={t("stats.countries")} count={stats ? stats.countries : 0} />
          </Col>

          <Col xs={6} md={3}>
            <StatsCard title={t("stats.regions")} count={stats ? stats.regions : 0} />
          </Col>
        </Row>

        <div style={{ width: "100%", height: "400px", marginBottom: "1.75rem" }}>
          <FlightMap routes={routes} />
        </div>

        <PrimaryButton label={t("close_btn")} onClick={handleClose} />
      </div>
    </UploadModal>
  );
};

export default UploadSuccessful;
