import { ErrorMessage, Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../../components/buttons/SubmitButton";
import { PageDescription, PageTitle } from "../../../../enums/Page";
import { uploadRosterService } from "../../../../services/roster/UploadRosterService";
import { showErrorMessage } from "../../../../stores/error/SnackbarStore";
import { uploadRosterHtmlSchema } from "../../../../utils/Validation";
import PageHead from "../../../PageHead";
import UploadHeader from "../UploadHeader";
import UploadModal from "../UploadModal";

const textBoxStyle: React.CSSProperties = {
  width: "100%",
  minHeight: "200px",
  padding: "0.5rem",
  resize: "none",
  borderColor: "lightgrey",
  borderRadius: "5px",
  borderStyle: "inset",
  borderWidth: "1px"
};

export const UploadText = (): JSX.Element => {
  const { t } = useTranslation();

  const handleSubmit = async (source: string): Promise<void> => {
    try {
      if (source != null) {
        await uploadRosterService().uploadRosterText(source);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <PageHead title={PageTitle.UPLOAD_ROSTER_SRC} description={PageDescription.UPLOAD_ROSTER_SRC} />

      <UploadModal showBackBtn>
        <UploadHeader title={t("upload_text_title")} subtitle={t("upload_text_subtitle")} />

        <Formik
          initialValues={{
            source: ""
          }}
          validationSchema={uploadRosterHtmlSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values: any, actions: FormikHelpers<any>) => {
            try {
              actions.setSubmitting(true);

              await handleSubmit(values.source);
            } catch (error) {
              showErrorMessage(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}>
          {(props: any) => (
            <FormikForm>
              <Form.Group>
                <Field
                  name={"source"}
                  component="textarea"
                  placeholder={t("upload_text_placeholder")}
                  style={textBoxStyle}
                />
                <ErrorMessage className="input-feedback" name={"source"} component="div" />
              </Form.Group>
              <Form.Group>
                <SubmitButton
                  icon="file-upload"
                  label={t("upload_roster")}
                  loadingLabel={t("uploading")}
                  disabled={props.isSubmitting}
                />
              </Form.Group>
            </FormikForm>
          )}
        </Formik>
      </UploadModal>
    </>
  );
};

export default UploadText;
