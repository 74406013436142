import { Database, getDatabase, ref, set } from "firebase/database";
import { firebaseApp } from "./FirebaseAuthService";

export class FirebaseDatabaseService {
  private readonly database: Database;

  public constructor() {
    this.database = getDatabase(firebaseApp);
  }

  public async setStatus(status: boolean, userId: number): Promise<void> {
    try {
      await set(ref(this.database, "UserStatus/" + userId), status ? 1 : 0);
    } catch (e) {
      console.log("=============", userId, e);
    }
  }
}

let service: FirebaseDatabaseService | null = null;

export const createFirebaseDatabaseService = (): FirebaseDatabaseService => {
  if (service === null) {
    service = new FirebaseDatabaseService();
  }

  return service;
};
