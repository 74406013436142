import React from "react";
import UpgradeButton from "../navigation/UpgradeButton";
import UploadRosterButton from "../navigation/UploadRosterButton";
import Brand from "./Brand";
import UserMenu from "./UserMenu";

const Header = (): JSX.Element => {
  return (
    <header id="page-header" className="rb-bg-primary">
      <div className="content-header">
        <Brand />
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginRight: "1rem", alignSelf: "center" }}>
            <UploadRosterButton />
            <UpgradeButton />
          </div>
          <UserMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
