import { AxiosResponse } from "axios";
import { action } from "mobx";
import { IAirline } from "../../interfaces";
import { fetchAirlines } from "../api/ApiService";

export class AirlineService {
  /**
   * Fetch all airlines or by query
   *
   * @param {string} -> query to fetch specific airlines
   */
  @action.bound
  public async fetchAirlines(query?: string): Promise<Array<IAirline>> {
    try {
      const inputQuery: string = query ?? "";

      const response: AxiosResponse = await fetchAirlines(inputQuery);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

let service: AirlineService | null = null;

export const airlineService = (): AirlineService => service ?? (service = new AirlineService());
