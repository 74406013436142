import moment from "moment-timezone";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SettingsRow from "../../../components/settings/SettingsRow";

interface IProps {
  defaultValue: string | undefined;
  onChange: (value: string) => Promise<void>;
}

const DisplayTime = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { defaultValue, onChange } = props;

  const timezones: Array<string> = moment.tz.names();

  const handleTimezoneChange = async (event: any): Promise<void> => {
    const timezone: string = event.target.value;

    await onChange(timezone);
  };

  return (
    <SettingsRow label={t("preferences.display.time.label")} description={t("preferences.display.time.description")}>
      <Form.Control
        as="select"
        value={timezones.find((timezone: string) => timezone === defaultValue)}
        onChange={handleTimezoneChange}>
        {timezones.map((timezone: string) => (
          <option key={timezone}>{timezone}</option>
        ))}
      </Form.Control>
    </SettingsRow>
  );
};

export default DisplayTime;
