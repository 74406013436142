import { RBApp } from "../../enums/RBApp";
import { RouteURL } from "../../enums/routing/Route";
import History from "../../History";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { createUserStore, UserStore } from "../../stores/user/UserStore";
import { createFirebaseAuthService } from "../firebase/FirebaseAuthService";
import { createFirebaseDatabaseService } from "../firebase/FirebaseDatabaseService";

/**
 * Sign the user out of the app
 */
export const signOut = (): void => {
  // Get redirect URL to correct Sign In page.
  const redirectUrl: string = createAuthStore().app === RBApp.LOGBOOK ? RouteURL.LOGBOOK_SIGN_IN : RouteURL.SIGN_IN;

  const userStore: UserStore = createUserStore();

  if (createAuthStore().app === RBApp.ROSTERBUSTER) {
    // Set status to offline
    if (userStore.userData) {
      createFirebaseDatabaseService()
        .setStatus(false, userStore.userData.user.pk)
        .catch();
    }

    // Signout from firebase
    createFirebaseAuthService()
      .signOut()
      .catch();
  }

  // Clear AuthStore
  createAuthStore().clear();

  // Clear UserStore
  userStore.clear();

  // Redirect to Sign In
  History.replace(redirectUrl);
};

/**
 * Display unauthorized page.
 * History is used to go back to the page of origin after signing in again.
 */
export const unauthorized = (): void => {
  History.push({
    pathname: RouteURL.UNAUTHORIZED,
    state: { from: History.location?.pathname + History.location?.search }
  });
};
