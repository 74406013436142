import { observer } from "mobx-react";
import React from "react";
import ComponentBoundary from "../../../components/error/boundaries/ComponentBoundary";
import { PageDescription, PageTitle } from "../../../enums/Page";
import { Preference } from "../../../enums/settings/Preference";
import { IPreferences } from "../../../interfaces";
import { settingsService } from "../../../services/settings/SettingsService";
import { showErrorMessage } from "../../../stores/error/SnackbarStore";
import { createUserStore } from "../../../stores/user/UserStore";
import PageHead from "../../PageHead";
import ExcludeDayOff from "./ExcludeDayOff";
import ExcludeDeadhead from "./ExcludeDeadhead";
import ExcludeDebrief from "./ExcludeDebrief";
import ExcludeLayover from "./ExcludeLayover";
import ExcludeReport from "./ExcludeReport";
import ExcludeSimulator from "./ExcludeSimulator";
import ExcludeStandby from "./ExcludeStandby";
import TodayOnwards from "./TodayOnwards";

const CalendarFilterSettings: () => JSX.Element = observer(
  (): JSX.Element => {
    const preference: IPreferences | undefined = createUserStore().userData?.preferences;

    const updatePreference = async (preference: Preference, value: string): Promise<void> => {
      try {
        await settingsService().updatePreference(preference, value);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleTodayOnwardsChange = async (value: string): Promise<void> => {
      // Update Store and server
      preference!.export_todayonwards = value;

      await updatePreference(Preference.EXPORT_TODAY_ONWARDS, value);
    };

    const handleExcludeDeadheadChange = async (value: string): Promise<void> => {
      preference!.exclude_tvf = value;

      await updatePreference(Preference.EXCLUDE_DEADHEAD, value);
    };

    const handleExcludeStandbyChange = async (value: string): Promise<void> => {
      preference!.exclude_sby = value;

      await updatePreference(Preference.EXCLUDE_STANDBY, value);
    };

    const handleExcludeDayOffChange = async (value: string): Promise<void> => {
      preference!.exclude_do = value;

      await updatePreference(Preference.EXCLUDE_DAY_OFF, value);
    };

    const handleExcludeReportChange = async (value: string): Promise<void> => {
      preference!.exclude_rep = value;

      await updatePreference(Preference.EXCLUDE_REPORT, value);
    };

    const handleExcludeDebriefChange = async (value: string): Promise<void> => {
      preference!.exclude_deb = value;

      await updatePreference(Preference.EXCLUDE_DEBRIEF, value);
    };

    const handleExcludeSimulatorChange = async (value: string): Promise<void> => {
      preference!.exclude_sim = value;

      await updatePreference(Preference.EXCLUDE_SIMULATOR, value);
    };

    const handleExcludeLayoverChange = async (value: string): Promise<void> => {
      preference!.exclude_lay = value;

      await updatePreference(Preference.EXCLUDE_LAYOVER, value);
    };

    return (
      <ComponentBoundary componentName="Calendar filter settings">
        <PageHead title={PageTitle.SETTINGS_CALENDAR_FILTER} description={PageDescription.SETTINGS_CALENDAR_FILTER} />

        <TodayOnwards defaultValue={preference?.export_todayonwards} onChange={handleTodayOnwardsChange} />

        <ExcludeDeadhead defaultValue={preference?.exclude_tvf} onChange={handleExcludeDeadheadChange} />

        <ExcludeStandby defaultValue={preference?.exclude_sby} onChange={handleExcludeStandbyChange} />

        <ExcludeDayOff defaultValue={preference?.exclude_do} onChange={handleExcludeDayOffChange} />

        <ExcludeReport defaultValue={preference?.exclude_rep} onChange={handleExcludeReportChange} />

        <ExcludeDebrief defaultValue={preference?.exclude_deb} onChange={handleExcludeDebriefChange} />

        <ExcludeSimulator defaultValue={preference?.exclude_sim} onChange={handleExcludeSimulatorChange} />

        <ExcludeLayover defaultValue={preference?.exclude_lay} onChange={handleExcludeLayoverChange} />
      </ComponentBoundary>
    );
  }
);

export default CalendarFilterSettings;
