import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PageLoader from "../../components/loaders/PageLoader";
import { PageDescription, PageTitle } from "../../enums/Page";
import { RouteURL } from "../../enums/routing/Route";
import { eventService } from "../../services/event/EventsService";
import { showErrorMessage } from "../../stores/error/SnackbarStore";
import { createUserStore } from "../../stores/user/UserStore";
import PageHead from "../PageHead";
import Timeline from "./Timeline";
import UploadFile from "./upload-roster/file/UploadFile";
import UploadMenu from "./upload-roster/menu/UploadMenu";
import UploadPortal from "./upload-roster/portal/UploadPortal";
import UploadSuccessful from "./upload-roster/successful/UploadSuccessful";
import UploadText from "./upload-roster/text/UploadText";

export const Dashboard = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchEvents().catch();
  }, []);

  const fetchEvents = async (): Promise<void> => {
    try {
      await eventService().fetchAllEvents();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader primary />;
  }

  const { uploadType, name, picture } = createUserStore().userData!.homeairline;

  return (
    <>
      <PageHead title={PageTitle.HOME} description={PageDescription.HOME} />

      <Timeline />

      {uploadType && (
        <>
          <Route exact path={RouteURL.UPLOAD} render={() => <UploadMenu uploadType={uploadType[0]} />} />
          <Route
            exact
            path={RouteURL.UPLOAD_PORTAL}
            render={() => <UploadPortal airline={name} picture={picture} uploadType={uploadType[0]} />}
          />
          <Route exact path={RouteURL.UPLOAD_FILE} component={UploadFile} />
          <Route exact path={RouteURL.UPLOAD_SRC} component={UploadText} />
          <Route exact path={RouteURL.UPLOAD_SUCCESS} component={UploadSuccessful} />
        </>
      )}
    </>
  );
};

export default Dashboard;
