import { action, computed, observable } from "mobx";
import i18n from "../../localization/i18n";

export enum MessageType {
  SUCCESS,
  ERROR
}

export class SnackbarStore {
  private _messageType: MessageType = MessageType.ERROR;
  @observable private _message: string | null = null;

  public showErrorMessage(error: any): void {
    this._messageType = MessageType.ERROR;

    this._message = this.getMessage(error);
  }

  public showSuccessMessage(response: any): void {
    this._messageType = MessageType.SUCCESS;

    this._message = this.getMessage(response);
  }

  private getMessage(error: any): string {
    try {
      if (typeof error === "string") {
        return error;
      }

      if (error.data?.message) {
        return error.data.message;
      }

      if (error.response?.data?.message) {
        return error.response.data.message;
      }

      return this.getDefaultMessage();
    } catch (error) {
      return this.getDefaultMessage();
    }
  }

  private getDefaultMessage(): string {
    switch (this._messageType) {
      case MessageType.SUCCESS:
        return "Success";

      case MessageType.ERROR:
        return i18n.t("error.default.message");
    }
  }

  public get messageType(): MessageType {
    return this._messageType;
  }

  public get message(): string | null {
    return this._message;
  }

  @computed
  public get hasMessage(): boolean {
    return this._message !== null;
  }

  @action.bound
  public clear(): void {
    this._message = null;
  }
}

let store: SnackbarStore | null = null;

export const createSnackbarStore = (): SnackbarStore => store ?? (store = new SnackbarStore());

export const showErrorMessage = (error: any): void => createSnackbarStore().showErrorMessage(error);

export const showSuccessMessage = (error: any): void => createSnackbarStore().showSuccessMessage(error);
