import GoogleMapReact from "google-map-react";
import find from "lodash/find";
import React from "react";
import Card from "react-bootstrap/Card";
import { IAirport, ILatLng, IRoute } from "../../interfaces";
import { createUserStore, UserStore } from "../../stores/user/UserStore";

interface IProps {
  routes: Array<IRoute> | null;
  center?: ILatLng;
}

let googleMap: any = null;

const zoom: number = 5;

const drawColor: string = "#049fd9";

const drawWeight: number = 2.5;

const drawOpacity: number = 0.8;

const circles: Array<any> = new Array<any>();

const polylines: Array<any> = new Array<any>();

const drawFlights = (google: any, routes: Array<IRoute> | null): void => {
  clearDrawings();

  if (routes === null) {
    return;
  }

  const flightRoutes: Array<IRoute> = new Array<IRoute>();

  routes.forEach((route: IRoute) => {
    const departure: ILatLng = { lat: route.start.lat, lng: route.start.lng };
    const arrival: ILatLng = { lat: route.end.lat, lng: route.end.lng };
    const coords: IRoute = { start: departure, end: arrival };
    const reverseCoords: IRoute = { start: arrival, end: departure };

    // Only draw unique flight routes
    if (!find(flightRoutes, coords) && !find(flightRoutes, reverseCoords)) {
      drawFlightRoute(google, coords);
      drawCircleOnAirport(google, coords.start);
      drawCircleOnAirport(google, coords.end);
    }

    flightRoutes.push(coords);
  });
};

const drawFlightRoute = (google: any, coords: IRoute): void => {
  const polyline: any = new google.maps.Polyline({
    path: [coords.start, coords.end],
    geodesic: true,
    strokeColor: drawColor,
    strokeOpacity: drawOpacity,
    strokeWeight: drawWeight
  });

  polyline.setMap(google.map);
  polylines.push(polyline);
};

const drawCircleOnAirport = (google: any, latLng: ILatLng): any => {
  const circle: any = new google.maps.Marker({
    position: latLng,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 3,
      strokeColor: drawColor,
      strokeOpacity: drawOpacity,
      strokeWeight: 2,
      fillColor: drawColor,
      fillOpacity: drawOpacity
    }
  });

  circle.setMap(google.map);
  circles.push(circle);
};

const clearDrawings = (): void => {
  polylines.forEach((polyline: any) => {
    polyline.setMap(null);
  });

  circles.forEach((circle: any) => {
    circle.setMap(null);
  });
};

const FlightMap = (props: IProps): JSX.Element => {
  const { routes, center } = props;

  const userStore: UserStore = createUserStore();

  const base: IAirport | null = userStore.userData ? userStore.userData.base : null;

  const baseCoords: ILatLng = {
    lat: base ? base.Latitude : 0,
    lng: base ? base.Longitude : 0
  };

  if (googleMap) {
    drawFlights(googleMap, routes);
  }

  return (
    <Card className="block flight-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP! }}
        defaultCenter={{
          lat: center ? center.lat : baseCoords.lat,
          lng: center ? center.lng : baseCoords.lng
        }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(google: any) => {
          googleMap = google;
          drawFlights(googleMap, routes);
        }}
      />
    </Card>
  );
};

export default FlightMap;
