import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IToken } from "../../interfaces";
import { createAuthStore } from "../../stores/auth/AuthStore";
import { unauthorized } from "../auth/SignOutService";

enum Status {
  UNAUTHORIZED = 401
}

export abstract class AbstractHttpService {
  /**
   * Creates a client for authentication.
   *
   * @returns {AxiosInstance}
   */
  protected createGuestClient(baseUrl: string): AxiosInstance {
    return axios.create({
      baseURL: baseUrl
    });
  }

  /**
   * Returns an authenticated client
   *
   * @param {string} baseUrl The base URL of the API to use
   */
  public async createClient(baseUrl: string): Promise<AxiosInstance> {
    try {
      const token: IToken = createAuthStore().token;

      // Check if token is expired
      if (this.isTokenExpired(token)) {
        await this.refreshToken(token);
      }

      const instance: AxiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${createAuthStore().token.access_token}`
        }
      });

      return await this.addResponseInterceptor(instance);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Adds a response interceptor to the provided client.
   * This interceptor handles Unauthorized responses.
   *
   * @param {AxiosInstance} client Axios client to add the interceptor to.
   */
  private async addResponseInterceptor(client: AxiosInstance): Promise<AxiosInstance> {
    client.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        return response;
      },
      async (error: any) => {
        if (error?.response?.status === Status.UNAUTHORIZED) {
          unauthorized();
        }
        return await Promise.reject(error);
      }
    );

    return await Promise.resolve(client);
  }

  /**
   * Checks if a token is expired
   *
   * @param {IToken} token Token to check for expiry
   * @returns {boolean} Returns true if token is expired
   */
  private isTokenExpired(token: IToken): boolean {
    try {
      const millis: number = 1000;
      const oneMinute: number = 60000;

      const expiryDate: number = token.expired_at_ts! * millis;

      // Substract a minute from the current time so the token expires a minute earlier, just to be safe.
      const currentDate: number = new Date().getTime() - oneMinute;

      return expiryDate <= currentDate;
    } catch (error) {
      return true;
    }
  }

  public abstract guestAuthClient(): AxiosInstance;

  public abstract paymentClient(): Promise<AxiosInstance>;

  public abstract client(): Promise<AxiosInstance>;

  protected abstract refreshToken(token: IToken): Promise<IToken | void>;
}
